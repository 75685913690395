import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Grid, Button, Tooltip } from '@material-ui/core';
import { AssignmentTurnedIn, ArrowUpward } from '@material-ui/icons';
import { BackButton, ButtonLink, Panel, Container } from '../../../../../../Components';
import { FormValues } from '../../../../../../Components/Form/Form.types';
import { CompanyUnitEditForm, ICompanyUnit } from '../../CompanyUnits.Edit';
import { LawProcesses } from './LawProcesses';
import { LawAspects } from './LawAspects';
import { LawTags } from './LawTags';
import { LawHeader } from '../../LawHeader';
import { ICustomerLaw } from '../CustomerLaws.List';
import { UserContext } from '../../../../../../Context/UserContext/UserContext';
import { AptorApi, useAptorApi, useFetchSelectOptions } from '../../../../../../Api';
import { useSnackbar } from 'notistack';
import { useStyles } from "../../../../AdministrationModule.styles";

const LawCompanyUnitForm = (props: { lawId: number; isNew?: boolean; unitId?: number }) => {
  const { lawId, isNew, unitId } = props;
  const { api, abortController } = useAptorApi();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { getCompanyUnitName } = useContext(UserContext);
  const [units] = useFetchSelectOptions(
    useCallback(() => api.getCustomerLawCompanyUnits(lawId), [lawId, api]),
    abortController,
  );
  const [users] = useFetchSelectOptions(
    useCallback(() => {
      if (isNew) return api.getCustomerUsers();
      return api.getCustomerCompanyUnitUsers(unitId!);
    }, [isNew, unitId, api]),
    abortController,
  );
  const fetcher = useCallback(() => api.getLawCompanyUnit<ICompanyUnit>(lawId, unitId!), [lawId, unitId, api]);

  const handleSubmit = async (data: FormValues, api: AptorApi) => {
    var ok = true;
    if (isNew) {
      ok = await api.addLawCompanyUnits(lawId, data);
    } else await api.updateLawCompanyUnit(lawId, unitId!, { complianceUsers: data.complianceUsers });
    if (!ok && !abortController.current.signal.aborted)
      enqueueSnackbar(
        formatMessage({ id: 'organization.user-unit-mismatch' }, { alias: getCompanyUnitName(formatMessage, true) }),
        { variant: 'warning' },
      );
    history.push(`/organization/law-portal/laws/${lawId}`);
  };

  return (
    <CompanyUnitEditForm
      isNew={!!isNew}
      units={units}
      users={users}
      dataFetcher={fetcher}
      handleSubmit={handleSubmit}
    />
  );
};

export const AddLawCompanyUnit = () => {
  const match = useRouteMatch<{ lawid: string }>();
  const id = parseInt(match.params.lawid);
  return (
    <Container>
      <BackButton link={`/organization/law-portal/laws/${id}`} />
      <LawCompanyUnitForm lawId={id} isNew />
    </Container>
  );
};

export const EditLawCompanyUnit = () => {
  const match = useRouteMatch<{ lawid: string; unitid: string }>();
  const lawid = parseInt(match.params.lawid);
  const unitid = parseInt(match.params.unitid);
  const { formatMessage } = useIntl();
  const { api, abortController } = useAptorApi();
  const [name, setName] = useState('');
  const classes = useStyles()
  //Initialization
  useEffect(() => {
    const initializeName = async () => {
      const law = await api.getCustomerLaw<ICustomerLaw>(lawid);
      if (abortController.current.signal.aborted) {
        return;
      }
      setName(law.number + ' ' + law.name);
    };
    if (name === '') {
      initializeName();
    }
  }, [lawid, name, api, abortController]);
  const [showScrollTop, setShowScrollTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <Container>
        <BackButton link={`/organization/law-portal/laws/${lawid}`} />
        <Grid container spacing={4} direction="column">
          <Grid item>
            <LawHeader name={name} />
          </Grid>
          <Grid item>
            <ButtonLink
              to={`/organization/law-portal/laws/${lawid}/company-units/${unitid}/checklist`}
              text={formatMessage({ id: 'law-portal.manage.edit-law.lawChecklist' })}
              icon={<AssignmentTurnedIn />}
            />
          </Grid>
          <Grid item>
            <LawCompanyUnitForm lawId={lawid} unitId={unitid} />
          </Grid>
          <Grid item>
            <Panel titleKey="law-portal.manage.edit-law.connectedProcesses" accordion>
              <LawProcesses lawid={lawid} unitid={unitid} />
            </Panel>
          </Grid>
          <Grid item>
            <Panel titleKey="law-portal.manage.edit-law.connectedAspects" accordion>
              <LawAspects lawid={lawid} unitid={unitid} />
            </Panel>
          </Grid>
          <Grid item>
            <Panel titleKey="law-portal.manage.edit-law.connectedTags" accordion>
              <LawTags lawid={lawid} unitid={unitid} />
            </Panel>
          </Grid>
        </Grid>
      </Container>
      {showScrollTop && (
        <Tooltip title={formatMessage({id:"bottom.to.top.btn.tooltip.text",defaultMessage:"Back to top"})}>
          <Button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className={classes.topButton}
          >
            <ArrowUpward style={{ fontSize: 20 }} />
          </Button>
        </Tooltip>
      )}
    </>
  );
};
