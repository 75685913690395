import React, { FC, useContext, useState } from 'react';
import { UserContext } from '../UserContext/UserContext';
// import { useParams, useRouteMatch } from "react-router-dom";

interface ILaw { }

interface ILaw {
  id: number;
  name: string;
}

interface ILawInformation extends ILaw {
  complianceUrl: string;
}

interface IComplianceSessionData {
  created: Date;
  updated?: Date;
  todo: ILawInformation[];
  done: ILawInformation[];
}

interface IComplianceSession extends IComplianceSessionData {
  contains: (id: number) => boolean;
  next: (currentLawId?: number) => ILawInformation | null;
}

class ComplianceSession implements IComplianceSession {
  private static getSessionKey = (userId: number,planId:number) => `ComplianceSession_plan_${planId}_userID_${userId}`;

  private constructor(data: IComplianceSessionData) {
    this.created = data.created;
    this.updated = data.updated;
    this.todo = data.todo;
    this.done = data.done;
  }

  public completeLaw(lawId: number, userId: number) {
    const planId=window.location.pathname?.split("/")[4]
    const law = this.todo.find((law) => law.id === lawId);
    if (law) {
      this.todo = this.todo.filter((l) => l !== law);
      this.done = [...this.done, law];
      this.updated = new Date();
      localStorage.setItem(ComplianceSession.getSessionKey(userId,Number(planId)), JSON.stringify(this));
    }
    return this;
  }

  public contains = (id: number) => this.todo.some((x) => x.id === id);

  public next = (currentLawId?: number) => {
    if (this.todo.length > 0) {
      if (currentLawId === undefined) {
        return this.todo[0]; // no law specified, return first
      } else if (this.todo.length > 1) {
        const index = this.todo.findIndex((x) => x.id === currentLawId);
        if (index >= 0) {
          if (index === this.todo.length - 1) {
            return this.todo[0]; // specified law was last in list, return first
          }
          return this.todo[index + 1]; //return item after specified law
        }
      }
    }

    //list is empty or specified id was not found or specified id is the only one in list
    return null;
  };

  public static set = (laws: ILaw[], userId: number, plan: any) => {
    const path = window?.location?.pathname?.split('/')[2]
    if(Number(plan.type)===1){
      const lawInfos = laws.map((x) => ({ ...x, complianceUrl: `/law-portal/${path}/plan/${plan.id}/other-requirements/${x.id}/compliance/report` }));
      const session = new ComplianceSession({ todo: lawInfos, done: [], created: new Date() });
      localStorage.setItem(ComplianceSession.getSessionKey(userId,plan.id), JSON.stringify(session));
      return session;
    }else{
      const lawInfos = laws.map((x) => ({ ...x, complianceUrl: `/law-portal/${path}/plan/${plan.id}/law/${x.id}/compliance/report` }));
      const session = new ComplianceSession({ todo: lawInfos, done: [], created: new Date() });
      localStorage.setItem(ComplianceSession.getSessionKey(userId,plan.id), JSON.stringify(session));
      return session;
    }
  };

  public static get(userId: number,planId:number) {
    const sessionData = localStorage.getItem(ComplianceSession.getSessionKey(userId,planId));
    if (sessionData) {
      const data = JSON.parse(sessionData) as IComplianceSessionData;
      if (data.todo && data.todo.length > 0) {
        return new ComplianceSession(data);
      } else {
        this.clear(userId,planId);
      }
    }
    return null;
  }

  public static clear(userId: number,planId:number) {
    localStorage.removeItem(ComplianceSession.getSessionKey(userId,planId));
  }

  public created: Date;
  public updated?: Date;
  public todo: ILawInformation[];
  public done: ILawInformation[];
}

interface IComplianceSessionContext {
  createComplianceSession: (laws: ILaw[], planid: any) => IComplianceSession;
  clearComplianceSession: () => void;
  completeLawCompliance: (lawId: number) => void;
  complianceSession: IComplianceSession | null;
}

export const ComplianceSessionContext = React.createContext<IComplianceSessionContext>({
  createComplianceSession: () => ({} as IComplianceSession),
  clearComplianceSession: () => { },
  completeLawCompliance: () => { },
  complianceSession: null,
});

export const useComplianceSession = () => React.useContext(ComplianceSessionContext);
export const ConfirmationSessionProvider: FC = ({ children }) => {
  const planId=window.location.pathname?.split("/")[4]

  const { id } = useContext(UserContext);
  const [complianceSession, setSession] = useState<ComplianceSession | null>(ComplianceSession.get(id,Number(planId)));

  const createComplianceSession = (laws: ILaw[], plan: any) => {
    const session = ComplianceSession.set(laws, id, plan);
    setSession(session);
    return session;
  };
  const clearComplianceSession = () => {
    const planId=window.location.pathname?.split("/")[4]
    ComplianceSession.clear(id,Number(planId));
    setSession(null);
  }
  const completeLawCompliance = (lawId: number) => {
    if (complianceSession) {
      setSession(complianceSession.completeLaw(lawId, id));
    }
  };

  return (
    <ComplianceSessionContext.Provider
      value={{ createComplianceSession, complianceSession, clearComplianceSession, completeLawCompliance }}
    >
      {children}
    </ComplianceSessionContext.Provider>
  );
};
