import { useReducer, useCallback } from 'react';

interface ILoad<TItemType> {
  type: 'load';
  initialValues: Array<TItemType>;
}

interface IAdd<TItemType> {
  type: 'add';
  state: TItemType;
}
interface IUpdate<TItemType> {
  type: 'update';
  index: number;
  state: Partial<TItemType>;
}
interface IDelete<TItemType> {
  type: 'delete';
  index: number;
}

type OnLoad<TItem> = (items: TItem[]) => void;
type OnUpdate<TItem> = (index: number, update: Partial<TItem>) => void;
type OnAdd<TItem> = (item: TItem) => void;
type OnDelete = (index: number) => void;
type Actions<TItem> = ILoad<TItem> | IAdd<TItem> | IUpdate<TItem> | IDelete<TItem>;

const reducer = <TItemType>() => (items: TItemType[], action: Actions<TItemType>): TItemType[] => {
  if (action.type === 'load') {
    return action.initialValues;
  } else if (action.type === 'add') {
    return [...items, action.state];
  } else if (action.type === 'delete') {
    const newItems = [...items];
    newItems.splice(action.index, 1);
    return newItems;
  }

  const newItems = [...items];
  newItems[action.index] = { ...newItems[action.index], ...action.state };
  return newItems;
};

export const useList = <TItem>(): [TItem[], OnLoad<TItem>, OnAdd<TItem>, OnUpdate<TItem>, OnDelete] => {
  const typedReducer = reducer<TItem>();
  const [items, dispatch] = useReducer(typedReducer, []);
  const load = useCallback((items: Array<TItem>) => dispatch({ type: 'load', initialValues: items }), []);
  const onAdd = useCallback((item: TItem) => dispatch({ type: 'add', state: item }), []);
  const onUpdate = useCallback(
    (index: number, state: Partial<TItem>) => dispatch({ type: 'update', index, state }),
    [],
  );
  const onDelete = useCallback((index: number) => dispatch({ type: 'delete', index }), []);
  return [items, load, onAdd, onUpdate, onDelete];
};
