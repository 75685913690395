import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { Button, Grid, Typography } from '@material-ui/core';
import { Close, ArrowForward } from '@material-ui/icons';
import { Panel } from '../../../../../../Components';
import { useComplianceSession } from '../../../../../../Context/ComplianceSessionContext';

export const ComplianceSessionInformation = (props: {id: number}) => {
  const { complianceSession, clearComplianceSession } = useComplianceSession();
  const history = useHistory();
  const nextLaw = complianceSession?.next(props.id);

  /* If not in a session or current compliance is not left to do in this session */
  if (complianceSession === null || !complianceSession.contains(props.id)) {
    return null;
  }
  const onAbortComplianceSession = () => {
    clearComplianceSession();
    const path = window.location.pathname?.split('/')[2]
    history.push(`/law-portal/${path}/carry`);
  };
  return (
    <Grid item>
      <Panel>
        <Grid container justify="space-between" alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h3">
              <FormattedMessage
                id="law-portal.our-laws.compliance.session.header"
                defaultMessage="Compliance Session"
                values={{
                  currentNumber: complianceSession.done.length + 1,
                  totalCount: complianceSession.todo.length + complianceSession.done.length,
                }}
              />
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button color="secondary" startIcon={<Close />} onClick={onAbortComplianceSession}>
                  <FormattedMessage id="form.cancel" defaultMessage="Avbryt" />
                </Button>
              </Grid>
              {nextLaw && (
                <Grid item>
                  <Button startIcon={<ArrowForward />} component={Link} to={nextLaw.complianceUrl}>
                    <FormattedMessage id="law-portal.our-laws.compliance.session.next.button" defaultMessage="Next" />
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Panel>
    </Grid>
  );
};
