import { useEffect, useState } from 'react';
import { useAptorApi } from '../../../../Api/useAptorApi';
import { SelectOption } from '../../../../Components/Form/Form.types';
import { entityToSelectOption } from '../../../../Utilities';

interface IOptions {
  areas: SelectOption[];
  categories: SelectOption[];
  tags: SelectOption[];
  statuses: SelectOption[];
  countries: SelectOption[];
}

export const useOptions = (): [IOptions | null, boolean] => {
  const { api, abortController } = useAptorApi();
  const [options, setOptions] = useState<IOptions | null>(null);
  const loading = options ? false : true;

  useEffect(() => {
    Promise.all([
      api.getLawSettings('areas'),
      api.getLawSettings('categories'),
      api.getLawSettings('tags'),
      api.getLawSettings('status'),
      api.getCountries(),
    ]).then(([areas, categories, tags, statuses, countries]) => {
      if (abortController.current.signal.aborted) {
        return;
      }
      setOptions({
        areas: areas.items.map(entityToSelectOption),
        categories: categories.items.map(entityToSelectOption),
        tags: tags.items.map(entityToSelectOption),
        statuses: statuses.items.map(entityToSelectOption),
        countries: countries.items.map(entityToSelectOption),
      });
    });
  }, [api, abortController]);

  return [options, loading];
};
