import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { ListFilter, createMultiSelectFilter, createSelectFilter } from '../../../../Components/ListFilter';
import { useOptions } from './Options';
import { entityToSelectlebelOption } from '../../../../Utilities';

interface IProps {
  setFilter: (field: string, values: string[] | { min?: Date; max?: Date } | undefined) => void;
  filters:any
}

export const LawListFilter: FC<IProps> = ({ filters,setFilter }) => {
  const { formatMessage } = useIntl();
  const [options] = useOptions();

  // const areaFilter = createSelectFilter('area', formatMessage({ id: 'utils.filters.area' }), options?.areas);
  const areaFilter = createSelectFilter(
    'area',
    formatMessage({ id: 'law-portal.our-laws.table.area' }),
    options?.areas?.map(entityToSelectlebelOption),
    (option) => option.value.toString(),
    undefined,
    filters.area,
  );

  // const categoryFilter = createSelectFilter(
  //   'category',
  //   formatMessage({ id: 'utils.filters.category' }),
  //   options?.categories,
  // );
  const categoryFilter = createSelectFilter(
    'category',
    formatMessage({ id: 'utils.filters.category' }),
    options?.categories?.map(entityToSelectlebelOption),
    (option) => option.value.toString(),
    undefined,
    filters.category,
  );

  // const tagFilter = createMultiSelectFilter('tags', formatMessage({ id: 'utils.filters.tags' }), options?.tags, (opt) =>
  //   opt.value.toString(),
  // );
  // const tagFilter = createMultiSelectFilter(
  //   'tags',
  //   formatMessage({ id: 'utils.filters.tags' }),
  //   options?.tags,
  //   (option) => option.value.toString(),
  //   undefined,
  //   filters.tags as string[],
  // );
  const tagFilter = createMultiSelectFilter(
    'tags',
    formatMessage({ id: 'utils.filters.tags' }),
    options?.tags,
    (option) => option.value.toString(),
    undefined,
    filters.tags as string[],
  );

  // const activeOption = { value: 1, label: formatMessage({ id: 'utils.filters.status.active' }) };
  // const statusFilter = createSelectFilter(
  //   'status',
  //   formatMessage({ id: 'utils.filters.status' }),
  //   options?.statuses,
  //   (opt) => opt.value.toString(),
  //   activeOption,
  // );
  const activeOption = { value: 1, label: formatMessage({ id: 'utils.filters.status.active' }) };

  const statusFilter = createSelectFilter(
    'status',
    formatMessage({ id: 'utils.filters.status' }),
    options?.statuses,
    (option) => option.value.toString(),
    activeOption,
    filters?.status,
  );

  return <ListFilter filters={[areaFilter, categoryFilter, tagFilter, statusFilter]} setFilter={setFilter} />;
};
