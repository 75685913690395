import React, { forwardRef, memo, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { TypedFormFieldValue } from '../Form/Form.types';
import { FormFieldRef, InputFieldProps } from './Field.props';
import { shallowCompare } from './shallowCompare';
import { TextField } from './TextField';

const PHONE_REGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g;

const isPhoneNumberValid = (number?: string, required?: boolean) => {
  if (!required && (number === undefined || number.length < 4)) {
    return true;
  }

  if (number === undefined || number.length < 12 || number.length > 20) {
    return false;
  }

  return number.match(PHONE_REGEX) !== null;
};

export const PhoneNumberField = memo(
  forwardRef<FormFieldRef<string>, InputFieldProps>((props, ref) => {
    const intl = useIntl();
    const { required, onChange } = props;
    const [isValid, setValid] = useState<boolean>(false);

    const handleChange = useCallback(
      (field: TypedFormFieldValue<string> | undefined) => {
        const phoneNumber = field?.value;
        let formattedValue = null;
        var isValid = isPhoneNumberValid(field?.value, required);
        setValid(isValid);
        if (phoneNumber && phoneNumber.charAt(3) === '0') {
          formattedValue = {
            value: phoneNumber.slice(0, 3).concat(phoneNumber.slice(4)),
            isValid: isValid,
          };
        } else {
          formattedValue =
            !required && (phoneNumber === undefined || phoneNumber === '')
              ? undefined
              : { isValid: isValid, value: phoneNumber ?? '' };
        }

        if (onChange) {
          onChange(formattedValue);
        }
      },
      [onChange, required],
    );

    const getErrorTexts = () => {
      return !isValid ? [intl.formatMessage({ id: 'form.wrongFormat' }), ...props.errorTexts] : props.errorTexts;
    };

    return (
      <TextField
        {...props}
        ref={ref}
        onChange={handleChange}
        inputMaskProps={{ alwaysShowMask: false, mask: '+469999999999999999', maskChar: null }}
        error={!isValid}
        errorTexts={getErrorTexts()}
      />
    );
  }),
  (oldProps, newProps) => shallowCompare(oldProps, newProps, ['errorTexts']),
);

export default PhoneNumberField;
