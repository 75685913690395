import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Query } from 'material-table';
import { Container, Panel, MTable, Form, RepealableName, BackButton } from '../../../../../Components';
import { HtmlView } from '../../../../../Components/Html/Html.View';
import { FormValues } from '../../../../../Components/Form/Form.types';
import { useFormField } from '../../../../../Components/Form/FormField';
import { useFilter } from '../../../../../Components/ListFilter';
import { LawListFilter } from './CustomerLaws.Add.Filter';
import { ICustomerLaw } from './CustomerLaws.List';
import { UserContext } from '../../../../../Context/UserContext/UserContext';
import { AptorApi, useAptorApi, useFetchSelectOptions } from '../../../../../Api';
import { OrderDirection, IGridQueryRequest } from '../../../../../Api/AptorApi';
import { useSnackbar } from 'notistack';
import { Typography,Button,Tooltip } from '@material-ui/core';
import { useStyles } from "../../../AdministrationModule.styles";
import { ArrowUpward } from '@material-ui/icons'

export const AddCustomerLaws = () => {
  const { api, abortController } = useAptorApi();
  const history = useHistory();
  const tableRef = React.createRef();
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { getCompanyUnitName } = useContext(UserContext);
  const [filters, setFilters] = useFilter();
  const [selectedLaws, setSelectedLaws] = useState<number[]>([]);
  const classes=useStyles()
  const [units] = useFetchSelectOptions(
    useCallback(() => api.getCustomerCompanyUnits(), [api]),
    abortController,
  );
  const [users] = useFetchSelectOptions(
    useCallback(() => api.getCustomerUsers(), [api]),
    abortController,
  );

  const nameField = useFormField({
    label: getCompanyUnitName(formatMessage),
    name: 'units',
    type: 'multi-select',
    options: units,
    required: true,
  });

  const usersField = useFormField({
    label: formatMessage({ id: 'law-portal.manage.edit-law.complianceUsers' }),
    name: 'complianceUsers',
    type: 'multi-select',
    options: users,
    required: false,
  });

  useEffect(() => {
    tableRef.current && (tableRef.current as any).onQueryChange();
  }, [filters, tableRef]);

  const handleDataFetch = useCallback(
    async (query: Query<ICustomerLaw>) => {
      return await api.searchNoneCustomerLaws<ICustomerLaw>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'number',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest);
    },
    [api, filters],
  );

  const check = (unit: ICustomerLaw) => {
    let isSelected = selectedLaws.includes(unit.id);
    return {
      tooltip: formatMessage({
        id: isSelected ? 'component.mtable.unselectTooltip' : 'component.mtable.selectTooltip',
      }),
      icon: isSelected ? 'check_box' : 'check_box_outline_blank',
      onClick: () => {
        if (isSelected) setSelectedLaws((laws) => laws.filter((x) => x !== unit.id));
        else
          setSelectedLaws((laws) => {
            return [...laws, unit.id];
          });
      },
    };
  };

  const handleSubmit = async (data: FormValues, api: AptorApi, onSuccess: () => void) => {
    if (!selectedLaws.length)
      enqueueSnackbar(formatMessage({ id: 'law-portal.manage.add-laws.noSelectedLaw' }), { variant: 'error' });
    else {
      let ok = await api.addCustomerLaws({ ...data, laws: selectedLaws as any });
      if (!ok && !abortController.current.signal.aborted) {
        let msg: string = formatMessage(
          { id: 'law-portal.manage.add-laws.warning' },
          {
            warning: formatMessage(
              { id: 'organization.user-unit-mismatch' },
              { alias: getCompanyUnitName(formatMessage, true) },
            ),
          },
        );
        enqueueSnackbar(msg, { variant: 'warning' });
      } else onSuccess();
      history.push('/organization/law-portal/laws');
    }
  };
  const [showScrollTop, setShowScrollTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
   <>
    <Container fullWidth>
      {/* <BackButton link="/organization/law-portal/laws" /> */}
      <BackButton disableMargin link="" onclick={()=>history.goBack()} />
      <Panel titleKey="law-portal.manage.add-laws.addLaws">
        <LawListFilter setFilter={setFilters} />
        <MTable
          actionLabel={formatMessage({ id: 'law-portal.manage.law-list.table.actions' })}
          tableRef={tableRef}
          columns={[
            { title: formatMessage({ id: 'law-portal.manage.law-list.table.number' }), field: 'number' },
            {
              title: formatMessage({ id: 'law-portal.manage.law-list.table.law' }),
              field: 'name',
              render: (row) => <RepealableName name={row.name} effectiveTo={row.effectiveTo} />,
            },
            {
              title: formatMessage({ id: 'law-portal.manage.law-list.table.description' }),
              field: 'description',
              render: (data: ICustomerLaw) => <HtmlView value={data.description} />,
              sorting: false,
            },
            { title: formatMessage({ id: 'law-portal.manage.law-list.table.area' }), field: 'area' },
          ]}
          data={handleDataFetch}
          actions={[check]}
          components={{}}
        />
        <Typography style={{ padding: '1em 0 0 1em' }} gutterBottom variant="body2">
          <FormattedMessage id="law-portal.manage.add-laws.selectedLaws" values={{ number: selectedLaws.length }} />
        </Typography>
        <Form
          style={{ maxWidth: '50rem' }}
          submit={handleSubmit}
          groups={[
            {
              grid: {
                type: 'row',
                items: [
                  { type: 'column', items: [nameField] },
                  { type: 'column', items: [usersField] },
                ],
              },
            },
          ]}
        />
      </Panel>
    </Container>
    {showScrollTop && (
      <Tooltip title={formatMessage({id:"bottom.to.top.btn.tooltip.text",defaultMessage:"Back to top"})}>
        <Button
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          className={classes.topButton}
        >
          <ArrowUpward style={{ fontSize: 20 }} />
        </Button>
      </Tooltip>
    )}
   </>
  );
};
