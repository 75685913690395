import React, { FC } from 'react';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Typography,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { ILawSettingsEntity } from '../../../../../Api/AptorApi';
import { useStyles } from './List.styles';
import { Delete, Edit } from '@material-ui/icons';

interface IProps {
  type: string;
  items: ILawSettingsEntity[];
  deleteItem: (item: ILawSettingsEntity) => Promise<void>;
  onEditItem: (item: ILawSettingsEntity) => void;
}

export const LawSettingsList: FC<IProps> = ({ items, type, deleteItem, onEditItem }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.header}>
              <Typography variant="overline">
                {formatMessage(
                  { id: 'admin.law-portal.settings.table.header', defaultMessage: 'List' },
                  { type: type.toLowerCase() },
                )}
              </Typography>
            </TableCell>
            <TableCell className={classes.header} />
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item: ILawSettingsEntity) => (
            <TableRow className={classes.row} key={`${type}-${item.id}`}>
              <TableCell className={classes.cell} title={item.description}>
                {item.name}
              </TableCell>
              <TableCell className={classes.cell} align="right">
                <Tooltip title={formatMessage({ id: 'utils.icon.delete', defaultMessage: 'Delete' })}>
                  <IconButton
                    aria-label={formatMessage({ id: 'utils.icon.delete', defaultMessage: 'Delete' })}
                    onClick={() => deleteItem(item)}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
                <Tooltip title={formatMessage({ id: 'utils.icon.edit', defaultMessage: 'Edit' })}>
                  <IconButton
                    aria-label={formatMessage({ id: 'utils.icon.edit', defaultMessage: 'Edit' })}
                    onClick={() => onEditItem(item)}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
