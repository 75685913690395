import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  italics: {
    fontStyle: 'italic',
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));
