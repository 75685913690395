import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Form } from '../../../../../Components/Form';
import { FormFieldValueType, FormValues, SelectOption } from '../../../../../Components/Form/Form.types';
import { useFormField } from '../../../../../Components/Form/FormField';
import { AptorApi } from '../../../../../Api';
import { INamedEntity } from '../../../../../Api/AptorApi';
import { selectOptionToEntity } from '../../../../../Utilities';

interface ILawInformation {
  name: string;
  number: string;
  effectiveFrom: Date;
  effectiveTo?: Date;
  link: { url: string; text?: string };
  area: INamedEntity;
  category: INamedEntity;
  country: INamedEntity;
  tags: INamedEntity[];
}

interface IProps extends ILawInformation {
  id: number;
  options: {
    areas: SelectOption[];
    categories: SelectOption[];
    tags: SelectOption[];
    countries: SelectOption[];
  };
  onChange: (params: ILawInformation) => void;
}

export const EditLawInformationForm: FC<IProps> = ({
  options,
  id,
  name,
  number,
  effectiveFrom,
  effectiveTo,
  link,
  area,
  category,
  country,
  tags,
  onChange,
}) => {
  const { formatMessage } = useIntl();

  const getSelectedOptionAsNamedEntity = (
    options: SelectOption[],
    id: FormFieldValueType | FormFieldValueType[] | undefined,
  ) => {
    var match = options.find((o) => o.value === id);
    if (match) {
      return selectOptionToEntity<number>(match)!;
    }
    throw Error(`Could not find matching option for id '${id}'`);
  };
  const submit = async (form: FormValues, api: AptorApi, onSuccess: () => void) => {
    await api.updateLawInformation(id, form);
    onSuccess();
    if (api.abortController?.signal.aborted) {
      return;
    }
    onChange({
      name: form['name'] as string,
      number: form['number'] as string,
      effectiveFrom: new Date(form['effectiveFrom'] as string),
      effectiveTo: form['effectiveTo'] ? new Date(form['effectiveTo'] as string) : undefined,
      link: { url: form['url'] as string, text: form['urlName'] as string | undefined },
      area: getSelectedOptionAsNamedEntity(options.areas, form['area']),
      category: getSelectedOptionAsNamedEntity(options.categories, form['category']),
      country: getSelectedOptionAsNamedEntity(options.countries, form['country']),
      tags:
        ((form['tags'] as any) as number[] | undefined)?.map((t) => getSelectedOptionAsNamedEntity(options.tags, t)) ??
        [],
    });
  };

  const nameField = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.name.label', defaultMessage: 'Name' }),
    name: 'name',
    type: 'text',
    initialState: { value: name },
    required: true,
  });

  const numberField = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.number.label', defaultMessage: 'Number' }),
    name: 'number',
    type: 'text',
    initialState: { value: number },
    required: true,
  });

  const categoryField = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.category.label', defaultMessage: 'Category' }),
    name: 'category',
    type: 'single-select',
    options: options.categories,
    required: true,
    initialState: { label: category.name, value: category.id },
  });

  const areaField = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.area.label', defaultMessage: 'Area' }),
    name: 'area',
    type: 'single-select',
    options: options.areas,
    required: true,
    initialState: { label: area.name, value: area.id },
  });

  const tagField = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.tags.label', defaultMessage: 'Tags' }),
    name: 'tags',
    type: 'multi-select',
    options: options.tags,
    required: false,
    initialState: tags.map((t) => ({ label: t.name, value: t.id })),
  });

  const countryField = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.country.label', defaultMessage: 'Country' }),
    name: 'country',
    type: 'single-select',
    options: options.countries,
    required: true,
    initialState: { label: country.name, value: country.id },
  });

  const effectiveFromField = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.effectiveFrom.label', defaultMessage: 'Effective from' }),
    name: 'effectiveFrom',
    type: 'date',
    required: true,
    initialState: { value: effectiveFrom },
  });

  const effectiveToField = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.effectiveTo.label', defaultMessage: 'Effective to' }),
    name: 'effectiveTo',
    type: 'date',
    required: false,
    initialState: (effectiveTo && { value: effectiveTo }) || undefined,
  });

  const urlField = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.url.label', defaultMessage: 'Url' }),
    name: 'url',
    type: 'text',
    initialState: { value: link.url },
    required: true,
  });

  const urlNameField = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.urlName.label', defaultMessage: 'Link name' }),
    name: 'urlName',
    type: 'text',
    initialState: (link.text && { value: link.text }) || undefined,
    placeholder: formatMessage({
      id: 'admin.law-portal.law.form.urlName.placeholder',
      defaultMessage: 'Link to the law in its entirety',
    }),
    required: false,
  });

  return (
    <Form
      submit={submit}
      groups={[
        {
          grid: {
            type: 'column',
            items: [
              { type: 'row', items: [nameField, numberField] },
              { type: 'row', items: [categoryField, areaField] },
              { type: 'row', items: [tagField, countryField] },
              { type: 'row', items: [effectiveFromField, effectiveToField] },
              { type: 'row', items: [urlField, urlNameField] },
            ],
          },
        },
      ]}
    />
  );
};
