import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

interface IButtonLinkProps {
  className?: string;
  text: string;
  to: string;
  icon?: React.ReactElement;
  disabled?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  onclick?:()=>void;
}

export const ButtonLink = (props: IButtonLinkProps) => {
  const { className, text, to, icon, variant, disabled,onclick } = props;
  return (
    <>
    {onclick?
    <Button
      disabled={disabled}
      className={className}
      variant={variant || 'outlined'}
      startIcon={icon}
      onClick={onclick}
    >
      {text}
    </Button>:
    <Button
    disabled={disabled}
    className={className}
    variant={variant || 'outlined'}
    component={Link}
    to={to}
    startIcon={icon}
  >
    {text}
  </Button>
   }
  </>
  );
};
