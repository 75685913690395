import React, { FC, useCallback } from 'react';
import { AptorApi, useAptorApi } from '../../../../../../Api';
import { IGridQueryRequest } from '../../../../../../Api/AptorApi';
import { FormValues } from '../../../../../../Components/Form/Form.types';
import { Processes, IProcess } from '../../Processes';

export const LawProcesses : FC<{ unitid: number, lawid: number }> = ({ unitid, lawid }) => {
  const { api } = useAptorApi();
  const optFetcher = useCallback(() => api.getCustomerLawProcesses(lawid, unitid), [lawid, unitid, api]);
  const dataFetcher = useCallback((request: IGridQueryRequest) => api.searchLawProcesses<IProcess>(lawid, unitid, request), [lawid, unitid, api]);

  return (
    <Processes
      optionFetcher={optFetcher}
      dataFetcher={dataFetcher}
      addNew={(api: AptorApi, data: FormValues) => api.addLawProcess(lawid, unitid, data)}
      remove={(api: AptorApi, processid: number) => api.removeLawProcess(lawid, unitid, processid)}
    />
  );
};
