import { useEffect, useRef } from 'react';
export const useIsMounted = () => {
  const mountRef = useRef(false);

  useEffect(() => {
    mountRef.current = true;
  }, []);

  return mountRef.current;
};
