import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Loading } from '../../../../../Components';
import { FormattedMessage } from 'react-intl';
import { AptorApi, useAptorApi } from '../../../../../Api';
import { MarkAsRead } from './MarkAsRead';
import { TaggedLink } from './Link';

interface IEntityMeta {
  url: (id: number) => string;
  markAsRead: (id: number, api: AptorApi) => Promise<void>;
}

export const Metadata: Record<EntityTypes, IEntityMeta> = {
  Law: {
    url: (id) => `/law-portal/our-laws/${id}`,
    markAsRead: (id, api) => api.markDashboardLawTagsAsRead(id),
  },
  CustomerRequirement: {
    url: (id) => `/law-portal/other-requirements/${id}`,
    markAsRead: (id, api) => api.markDashboardRequirementTagsAsRead(id),
  },
};

export type EntityTypes = 'CustomerRequirement' | 'Law';
interface ITaggedEntity {
  id: number;
  name: string;
  type: EntityTypes;
}

export const TaggedItemsWidget = () => {
  const { api, abortController } = useAptorApi();
  const [taggedItems, setTaggedItems] = useState<ITaggedEntity[]>();

  useEffect(() => {
    api.getDashboardTaggedItems<ITaggedEntity>().then((result) => {
      if (abortController.current.signal.aborted) {
        return;
      }
      setTaggedItems(result.items);
    });
  }, [api, abortController]);

  const onMarkedAsRead = (id: number, type: EntityTypes) => {
    if (taggedItems) {
      setTaggedItems(taggedItems.exclude((x) => x.id === id && x.type === type));
    }
  };

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h3">
            <FormattedMessage id="law-portal.dashboard.tagged-in-laws.header"></FormattedMessage>
          </Typography>
        }
      />
      <CardContent>
        {(taggedItems === undefined && <Loading />) || (
          <List>
            {taggedItems?.map((item) => (
              <ListItem key={item.id}>
                <ListItemText primary={item.name}></ListItemText>
                <ListItemSecondaryAction>
                  <TaggedLink {...item} />
                  <MarkAsRead {...item} onMarkedAsRead={onMarkedAsRead} />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </CardContent>
    </Card>
  );
};
