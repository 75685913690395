import React, { FC, useContext, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../../../Context/UserContext/UserContext';
import { HtmlView, Panel } from '../../../../../../Components';
import { ApplicationHiddenForm } from './Application.Hidden.Form';
import { ICompanyUnitLaw } from '.';
import { useStyles } from './Application.styles';

interface IProps {
  lawId: number;
  application: string;
  companyUnits: ICompanyUnitLaw[];
  onChange: (state: { companyUnits: ICompanyUnitLaw[] }) => void;
}

export const Application: FC<IProps> = ({ lawId, application, children, companyUnits, onChange }) => {
  const { hasAccessToFeature, getCompanyUnitName } = useContext(UserContext);
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const hidden = companyUnits.every((x) => x.applicationHidden);
  const partiallyHidden =
    companyUnits.some((x) => x.applicationHidden) && companyUnits.some((x) => !x.applicationHidden);
  const canHideApplication = hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);

  const handleOnChange = (state: { companyUnits: ICompanyUnitLaw[] }) => {
    setDisplayForm(false);
    onChange(state);
  };

  if (hidden && !canHideApplication) {
    return null;
  }

  return (
    <Panel titleKey="law-portal.our-laws.overview.application.label">
      <Grid container direction="column" spacing={2}>
        <Grid item>
          {children}
          {hidden === false && <HtmlView value={application} />}
        </Grid>
        <Grid item>
          {canHideApplication && displayForm && (
            <ApplicationHiddenForm
              lawId={lawId}
              companyUnits={companyUnits}
              onChange={handleOnChange}
              onCancel={() => setDisplayForm(false)}
            />
          )}
          {!displayForm && (
            <Grid container justify="space-between">
              <Grid item className={classes.partiallyHidden}>
                {partiallyHidden && (
                  <FormattedMessage
                    id="law-portal.our-laws.overview.application.partially-visible"
                    values={{
                      unitsName: getCompanyUnitName(formatMessage, true),
                      hiddenUnits: companyUnits
                        .filter((x) => x.applicationHidden)
                        .map((x) => x.name)
                        .join(', '),
                    }}
                  />
                )}
              </Grid>
              {canHideApplication && (
                <Grid item>
                  <Button variant="outlined" onClick={() => setDisplayForm(true)}>
                    <FormattedMessage id="law-portal.our-laws.overview.application.toggle-visibility.button" />
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Panel>
  );
};
