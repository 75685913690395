import React, { FC, useCallback } from 'react';
import { useHistory} from 'react-router-dom';
import { useAptorApi } from '../../../../../../Api';
import { IGridQueryRequest } from '../../../../../../Api/AptorApi';
import { CompanyUnits, ICompanyUnit } from '../../CompanyUnits.List';

export const LawCompanyUnits : FC<{ lawId: number }> = ({ lawId }) => {
  const { api } = useAptorApi();
  const history = useHistory();
  const dataFetcher = useCallback(
    (request: IGridQueryRequest) => api.searchLawCompanyUnits<ICompanyUnit>(lawId, request),
    [lawId, api],
  );

  return (
    <CompanyUnits
      addLink={`/organization/law-portal/laws/${lawId}/company-units/new`}
      dataFetcher={dataFetcher}
      edit={(id: number) => history.push(`/organization/law-portal/laws/${lawId}/company-units/${id}`)}
      remove={(id: number) => api.removeLawCompanyUnit(lawId, id)}
    />
  );
};
