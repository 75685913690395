import React, { FC, useCallback } from 'react';
import { AptorApi, useAptorApi } from '../../../../../../Api';
import { IGridQueryRequest } from '../../../../../../Api/AptorApi';
import { FormValues } from '../../../../../../Components/Form/Form.types';
import { Tags, ITag } from '../../Tags';

export const RequirementTags : FC<{ unitid: number, reqid: number }> = ({ unitid, reqid }) => {
  const { api } = useAptorApi();
  const optFetcher = useCallback(() => api.getCustomerRequirementTags(reqid, unitid), [reqid, unitid, api]);
  const dataFetcher = useCallback((request: IGridQueryRequest) => api.searchRequirementTags<ITag>(reqid, unitid, request), [reqid, unitid, api]);

  return (
    <Tags
      optionFetcher={optFetcher}
      dataFetcher={dataFetcher}
      addNew={(api: AptorApi, data: FormValues) => api.addRequirementTag(reqid, unitid, data)}
      remove={(api: AptorApi, tagid: number) => api.removeRequirementTag(reqid, unitid, tagid)}
    />
  );
};
