import React, { FC, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { TextField } from '../../../../Components/Field';
import { FormattedMessage } from 'react-intl';
import { PropertyError } from '../../../../Api/AptorApi';
import { useIntl } from 'react-intl';
import { TypedFormFieldValue } from '../../../../Components/Form/Form.types';
import { FormFieldRef } from '../../../../Components/Field/Field.props';

interface IProps {
  addQuestion: (value: string) => void;
  addGroup: (value: string) => void;
  errors: PropertyError[];
}

export const ChecklistForm: FC<IProps> = ({ addQuestion, addGroup, errors }) => {
  const { formatMessage } = useIntl();
  const [group, setGroup] = useState<TypedFormFieldValue<string>>();
  const [question, setQuestion] = useState<TypedFormFieldValue<string>>();
  let groupRef: FormFieldRef<string> | null = null;
  let questionRef: FormFieldRef<string> | null = null;
  const handleAddGroup = () => {
    if (group?.value) {
      addGroup(group.value as string);
      groupRef?.setValue({ value: '' });
    }
  };
  const handleAddQuestion = () => {
    if (question?.value) {
      addQuestion(question.value as string);
      questionRef?.setValue({ value: '' });
    }
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Grid container spacing={2} alignItems="flex-end" wrap="nowrap">
          <Grid item md={6} xs={9}>
            <TextField
              ref={(r) => (groupRef = r)}
              name="group"
              label={formatMessage({ id: 'admin.law-portal.law.checklist.form.group.label' })}
              errorTexts={[]}
              onChange={setGroup}
            />
          </Grid>
          <Grid item>
            <Button variant="outlined" size="small" onClick={handleAddGroup}>
              <FormattedMessage id="form.add" defaultMessage="Add" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2} alignItems="flex-end" wrap="nowrap">
          <Grid item md={6} xs={9}>
            <TextField
              ref={(r) => (questionRef = r)}
              label={formatMessage({ id: 'admin.law-portal.law.checklist.form.question.label' })}
              name="label"
              errorTexts={[]}
              onChange={setQuestion}
            />
          </Grid>
          <Grid item>
            <Button variant="outlined" size="small" onClick={handleAddQuestion}>
              <FormattedMessage id="form.add" defaultMessage="Add" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
