import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100%',
  },
  autoHeight: {
    minHeight: 'auto',
  },
  panelDetails: {
    display: 'block',
  },
}));
