import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { ListFilter, createDateFilter } from '../../../../../Components/ListFilter';

interface IProps {
  setFilter: (field: string, values: string[] | { min?: Date; max?: Date } | undefined) => void;
}

export const SendoutsFilter: FC<IProps> = ({ setFilter }) => {
  const { formatMessage } = useIntl();

  const dateFilter = createDateFilter(
    'sendoutDateUtc',
    formatMessage({ id: 'utils.filters.sendoutDate.from' }),
    formatMessage({ id: 'utils.filters.sendoutDate.to' }),
  );

  return <ListFilter filters={[dateFilter]} setFilter={setFilter} />;
};
