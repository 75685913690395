import React, { useCallback, useEffect, useState } from 'react';
import { useAptorApi } from '../../../../../Api';
import { LogotypeForm } from '.';

export const CustomerLogotypeForm = () => {
  const { api, abortController } = useAptorApi();
  const [currentLogotype, setCurrentLogotype] = useState<string>();

  const fetchLogotype = useCallback(async () => {
    const result = await api.getCustomerLogotype();
    if (abortController.current.signal.aborted) {
      return;
    }
    setCurrentLogotype(result.logotype);
  }, [api, abortController]);

  useEffect(() => {
    fetchLogotype();
  }, [fetchLogotype]);

  return <>{<LogotypeForm onLogoChanged={fetchLogotype} type="customer" currentLogo={currentLogotype} />}</>;
};
