import React, { useContext, useEffect, useState } from 'react';
import { useFormField } from '../../../../../../Components/Form/FormField';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { Form, Panel } from '../../../../../../Components';
import {
  //  FormFieldValue,
  FormValues
} from '../../../../../../Components/Form/Form.types';
import { AptorApi } from '../../../../../../Api';
import { useStyles } from './CreatePlanning.styles';
import { entityToSelectOption } from '../../../../../../Utilities';
import { PlanFeature, UserContext } from "../../../../../../Context/UserContext/UserContext";
// import { useHistory } from "react-router-dom";
// import moment from "moment";
interface IProps {
  setNewCreate: any
  getCompliancePlan: () => void
  isEdit?: boolean,
  planData?: any,
  setEditPlanID?: any,
  setDuplicatePlanData?: any,
  isDuplicate?: boolean
}
export const CreatePlannig = ({ setNewCreate, getCompliancePlan, isEdit, planData, setEditPlanID, setDuplicatePlanData, isDuplicate }: IProps) => {
  const { formatMessage } = useIntl();
  const { companyUnits, customerId } = useContext(UserContext);
  const classes = useStyles();
  // const history = useHistory()
  const [active, setActive] = useState<boolean>(true);
  const [reminder, setReminder] = useState<boolean>(true);

  useEffect(() => {
    if (isEdit || isDuplicate) {
      setActive(planData.isRecurrent ? false : true)
      setReminder(planData.activateReminder ? false : true)
    }
  }, [])

  const namePlanning = useFormField({
    label: formatMessage({ id: 'law-portal.legal.planned.form.name' }),
    required: true,
    name: 'name',
    type: 'text',
    initialState: isEdit ? { value: planData.name } : undefined
  });
  const chooseCompanyUnit = useFormField({
    type: 'single-select',
    name: 'CustomerCompanyUnitId',
    required: true,
    label: formatMessage({ id: 'law-portal.legal.planned.form.company.unit' }),
    options: companyUnits.map(entityToSelectOption),
    initialState: isEdit || isDuplicate ? entityToSelectOption(planData.customerCompanyUnitId) : undefined
  });

  const startFromDate = useFormField({
    label: formatMessage({ id: 'law-portal.legal.planned.form.start.date' }),
    name: 'StartDate',
    type: 'date',
    required: true,
    initialState: isEdit || isDuplicate ? { value: planData.startDate } : undefined,
  });
  const endFromDate = useFormField({
    label: formatMessage({ id: 'law-portal.legal.planned.form.end.date' }),
    name: 'EndDate',
    type: 'date',
    required: true,
    initialState: isEdit || isDuplicate ? { value: planData.endDate } : undefined
  });
  const activateCompliance = useFormField({
    label: formatMessage({ id: 'law-portal.legal.active.remainder.compliance.kek' }),
    name: 'ActivateReminder',
    type: 'checkbox',
    required: false,
    initialState: isEdit || isDuplicate ? { value: planData.activateReminder } : { value: false },
    checkedValue(values) {
      setReminder(!reminder)
    },
  });
  const useSkcick = useFormField({
    label: '',
    name: 'ReminderDate',
    type: 'date',
    required: reminder ? false : true,
    placeholder: formatMessage({ id: 'law-portal.legal.send.out.reminder' }),
    initialState: isEdit || isDuplicate ? { value: planData.reminderDate } : undefined,
    disable: reminder ? true : false,
  });
  const activateInterval = useFormField({
    label: formatMessage({ id: 'law-portal.legal.active.recuring.interval' }),
    name: 'IsRecurrent',
    type: 'checkbox',
    required: false,
    initialState: isEdit || isDuplicate ? { value: planData.isRecurrent } : { value: false },
    checkedValue(values) {
      setActive(!active)
    },
  });
  const angeDagar = useFormField({
    label: '',
    name: 'RecurrentInterval',
    type: 'integer',
    required: active ? false : true,
    placeholder: formatMessage({ id: 'law-portal.legal.number.of.day' }),
    initialState: isEdit || isDuplicate ? { value: planData.recurrentInterval } : undefined,
    disable: active ? true : false
  });
  const handleSubmit = async (data: FormValues, api: AptorApi, onSuccess: (key: string) => void) => {
    data.type = PlanFeature.requirement
    if (isEdit) {
      await api.updatePlanLaws(planData.id, data)
      setEditPlanID("")
      getCompliancePlan()
    } else {
      let cusId: number | null = customerId
      if (isDuplicate) {
        const response = await api.createCompliancePlan(cusId, data)
        const planLaws = await api.getPlanAllLawID(planData.id)
        await api.addLaws(response.id, planLaws.lawIds)
        onSuccess('law-portal.complince.create-plan.sucess.msg')
        getCompliancePlan()
        setNewCreate(false)
        setDuplicatePlanData("")

      } else {
        const response = await api.createCompliancePlan(cusId, data)
        onSuccess('law-portal.complince.create-plan.sucess.msg')
        getCompliancePlan()
        setNewCreate(false)
      }
    }
  };
  const handleCancel = () => {
    setEditPlanID("")
  }
  return (
    <Panel>
      <Typography>{formatMessage({ id: 'law-portal.legal.complaince.create.new.planning' })}</Typography>
      <Form
        submit={handleSubmit}
        cancel={isEdit ? handleCancel : undefined}
        submitLabel={formatMessage({ id: 'form.send' })}
        cancelLabel={formatMessage({ id: 'form.cancel' })}
        groups={[
          {
            grid: {
              type: 'column',
              items: [
                { type: 'row', items: [namePlanning, chooseCompanyUnit, startFromDate, endFromDate] },
                { type: 'row', classStyle: classes.formstyle, items: [activateCompliance, useSkcick, activateInterval, angeDagar] },
              ],
            },
          },
        ]}
      />
    </Panel>
  );
};