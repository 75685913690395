import React, {
    FC,
    useCallback,
   // useContext,
   useEffect,
   useState 
 } from 'react';
 import { useIntl } from 'react-intl';
 import { ListFilter, createMultiSelectFilter, createSelectFilter } from '../../../../../../Components/ListFilter';
 import { OptionsEntity } from '../../../../../../Utilities';
 import { SelectOption } from '../../../../../../Components/Form/Form.types';
 import { useAptorApi, useFetchFilterOptions } from "../../../../../../Api";
 // import { UserContext } from "../../../../../../Context/UserContext/UserContext";
 import { updateFilterOptionsDependentOnCompanyUnit } from "../../../../../../Components/ListFilter/filterUtils";
 interface IOptions extends OptionsEntity {
     areas: SelectOption[];
     categories: SelectOption[];
     companyUnits: SelectOption[];
     complianceUsers: SelectOption[];
     statuses: SelectOption[];
     processes: SelectOption[];
     aspects: SelectOption[];
     tags: SelectOption[];
   }
 interface IProps {
     filters: any;
   setFilter: (field: string, values: string[] | { min?: Date; max?: Date } | undefined) => void;
 }
 export const ComplianceFilter: FC<IProps> = ({filters, setFilter, children }) => {
   const { formatMessage } = useIntl();
   const [options, setOptions] = useState<IOptions | null>(null);
   const { api, abortController } = useAptorApi();
   // const { getCompanyUnitName } = useContext(UserContext);
   const [allOptions, , , userCompanyUnits] = useFetchFilterOptions<IOptions>(
     useCallback(() => api.getLawPortalLawFilters(), [api]),
     abortController,
   );
   useEffect(() => {
     setOptions(allOptions);
   }, [allOptions]);
   const onSetFilter = (field: string, values: string[] | { min?: Date; max?: Date } | undefined) => {
     setFilter(field, values);
     updateFilterOptionsDependentOnCompanyUnit(
       field,
       values,
       setOptions,
       allOptions,
       filters,
       setFilter,
       userCompanyUnits,
     );
   };
  //  const areaFilter = createSelectFilter(
  //    'area',
  //    formatMessage({ id: 'utils.filters.area' }),
  //    options?.areas,
  //    (option) => option.value.toString(),
  //    undefined,
  //    filters.area,
  //  );
   const areaFilter = createSelectFilter('area', formatMessage({ id: 'utils.filters.area' }), options?.areas);
 
   const categoryFilter = createSelectFilter(
     'category',
     formatMessage({ id: 'utils.filters.category' }),
     options?.categories,
     (option) => option.value.toString(),
     undefined,
     filters.category,
   );
 
   const companyUnitFilter = createSelectFilter(
     'companyUnit',
     formatMessage({id:'law-portal.overview-notes.form.company-units.label'}),
     options?.companyUnits,
     (option) => option.value.toString(),
     undefined,
     filters.companyUnit,
   );
 
   const complianceUsersFilter = createMultiSelectFilter(
     'complianceUsers',
     formatMessage({ id: 'utils.filters.complianceUsers' }),
     options?.complianceUsers,
     (option) => option.value.toString(),
     undefined,
     filters.complianceUsers as string[],
   );
 
   const activeOption = { value: 1, label: formatMessage({ id: 'utils.filters.status.active' }) };
 
   const statusFilter = createSelectFilter(
     'status',
     formatMessage({ id: 'utils.filters.status' }),
     options?.statuses,
     (option) => option.value.toString(),
     activeOption,
     filters?.status,
   );
 
   const processesFilter = createMultiSelectFilter(
     'processes',
     formatMessage({ id: 'utils.filters.processes' }),
     options?.processes,
     (option) => option.value.toString(),
     undefined,
     filters.processes as string[],
   );
 
   const aspectsFilter = createMultiSelectFilter(
     'aspects',
     formatMessage({ id: 'utils.filters.aspects' }),
     options?.aspects,
     (option) => option.value.toString(),
     undefined,
     filters.aspects as string[],
   );
 
   const tagsFilter = createMultiSelectFilter(
     'tags',
     formatMessage({ id: 'utils.filters.tags' }),
     options?.tags,
     (option) => option.value.toString(),
     undefined,
     filters.tags as string[],
   );
   return <ListFilter filters={[companyUnitFilter,complianceUsersFilter,processesFilter,aspectsFilter, tagsFilter,areaFilter, categoryFilter, statusFilter]} setFilter={onSetFilter} />;
 };
 