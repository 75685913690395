import React, { useContext, FC } from 'react';
import { useIntl } from 'react-intl';
import { Form } from '../../../../../Components';
import { FormValues, SelectOption } from '../../../../../Components/Form/Form.types';
import { useFormField } from '../../../../../Components/Form/FormField';
import { UserContext } from '../../../../../Context/UserContext/UserContext';
import { AptorApi } from '../../../../../Api';
import { IApiCustomerInfo } from '../../../../../Api/AptorApi';
import { entityToSelectOption } from '../../../../../Utilities';

interface IProps {
  customerInfo: IApiCustomerInfo;
  submit: (data: FormValues, api: AptorApi, onSuccess: (key: string) => void) => Promise<void>;
  countries: SelectOption[];
}

export const UpdateCustomerForm: FC<IProps> = ({ customerInfo, submit, countries }) => {
  const { formatMessage } = useIntl();
  const { getCompanyUnitName } = useContext(UserContext);

  //Company information
  const companyField = useFormField({
    label: formatMessage({ id: 'form.company' }),
    name: 'company',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInCompany' }),
    initialState: { value: customerInfo.name },
    required: true,
  });

  const organizationNumberField = useFormField({
    label: formatMessage({ id: 'form.organizationNumber' }),
    name: 'organizationNr',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInOrganizationNumber' }),
    initialState: { value: customerInfo.organizationNr },
    required: true,
  });

  const contactPersonField = useFormField({
    label: formatMessage({ id: 'form.contactPerson' }),
    name: 'contactPerson',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInPersonName' }),
    initialState: { value: customerInfo.contactPerson },
    required: true,
  });

  const companyAddressField = useFormField({
    label: formatMessage({ id: 'form.visitingAddress' }),
    name: 'companyAddress',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInAddress' }),
    initialState: (customerInfo.address && { value: customerInfo.address }) || undefined,
    required: false,
  });

  const cityField = useFormField({
    label: formatMessage({ id: 'form.city' }),
    name: 'city',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInCity' }),
    initialState: (customerInfo.city && { value: customerInfo.city }) || undefined,
    required: false,
  });

  const postalCodeField = useFormField({
    label: formatMessage({ id: 'form.postalCode' }),
    name: 'postalCode',
    type: 'postalCode',
    placeholder: formatMessage({ id: 'form.fillInPostalCode' }),
    initialState: (customerInfo.postalCode && { value: customerInfo.postalCode }) || undefined,
    required: false,
  });

  const nrCompanyUnitsField = useFormField({
    label: formatMessage({ id: 'admin.customers.nrCompanyUnits' }, { alias: getCompanyUnitName(formatMessage, true) }),
    name: 'numberOfAllowedCompanyUnits',
    type: 'integer',
    placeholder: formatMessage({ id: 'form.fillInCompanyUnits' }),
    initialState: { value: customerInfo.numberOfAllowedCompanyUnits },
    required: false,
  });

  const nrUsersField = useFormField({
    label: formatMessage({ id: 'admin.customers.nrUsers' }, { alias: getCompanyUnitName(formatMessage, true) }),
    name: 'numberOfAllowedUsers',
    type: 'integer',
    placeholder: formatMessage({ id: 'form.fillInUsers' }),
    initialState: { value: customerInfo.numberOfAllowedUsers },
    required: false,
  });

  //Billing information
  const billingAddressField = useFormField({
    label: formatMessage({ id: 'form.billingAddress' }),
    name: 'billingAddress',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInAddress' }),
    initialState: { value: customerInfo.billingAddress },
    required: true,
  });

  const billingEmailField = useFormField({
    label: formatMessage({ id: 'form.billingEmail' }),
    name: 'billingEmail',
    type: 'email',
    placeholder: formatMessage({ id: 'form.fillInEmail' }),
    initialState: (customerInfo.billingEmail && { value: customerInfo.billingEmail }) || undefined,
    required: false,
  });

  const billingCityField = useFormField({
    label: formatMessage({ id: 'form.city' }),
    name: 'billingCity',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInCity' }),
    initialState: { value: customerInfo.billingCity },
    required: true,
  });

  const billingPostalCodeField = useFormField({
    label: formatMessage({ id: 'form.postalCode' }),
    name: 'billingPostalCode',
    type: 'postalCode',
    placeholder: formatMessage({ id: 'form.fillInPostalCode' }),
    initialState: { value: customerInfo.billingPostalCode },
    required: true,
  });

  const referencePersonField = useFormField({
    label: formatMessage({ id: 'form.referencePerson' }),
    name: 'referencePerson',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInPersonName' }),
    initialState: { value: customerInfo.referencePerson },
    required: true,
  });

  const referencePhoneNumberField = useFormField({
    label: formatMessage({ id: 'form.phoneNumber' }),
    name: 'referencePhoneNumber',
    type: 'phoneNumber',
    placeholder: formatMessage({ id: 'form.fillInPhoneNumber' }),
    initialState: (customerInfo.phoneNumber && { value: customerInfo.phoneNumber }) || undefined,
    required: false,
  });

  const customerAccess = useFormField({
    label: '',
    name: `selectedModuleFeatures`,
    type: 'multi-select',
    options: customerInfo.configurableModuleFeatures.map(entityToSelectOption),
    initialState: customerInfo.selectedFeatures.map(entityToSelectOption),
    required: false,
  });

  const selectedCountries = useFormField({
    label: formatMessage({ id: 'form.countries' }),
    placeholder: formatMessage({ id: 'form.selectCountries' }),
    name: `selectedCountries`,
    type: 'multi-select',
    options: countries,
    initialState: customerInfo.selectedCountries.map(entityToSelectOption),
    required: false,
  });

  return (
    <Form
      submit={submit}
      submitLabel={formatMessage({ id: 'form.save' })}
      groups={[
        {
          label: 'admin.customers.information',
          grid: {
            type: 'column',
            items: [
              { type: 'row', items: [companyField, organizationNumberField] },
              { type: 'row', items: [contactPersonField, companyAddressField] },
              { type: 'row', items: [cityField, postalCodeField] },
              { type: 'row', items: [selectedCountries, null] },
              { type: 'row', items: [nrCompanyUnitsField, nrUsersField] },
            ],
          },
        },
        {
          label: 'admin.customers.billing',
          grid: {
            type: 'column',
            items: [
              { type: 'row', items: [billingAddressField, billingEmailField] },
              { type: 'row', items: [billingCityField, billingPostalCodeField] },
              { type: 'row', items: [referencePersonField, referencePhoneNumberField] },
            ],
          },
        },
        {
          label: 'admin.customers.access',
          grid: {
            type: 'column',
            items: [customerAccess],
          },
        },
      ]}
    />
  );
};
