import React from 'react';
import { Sidebar, ISidebarProps } from '../../../../Components/Sidebar/Sidebar';
import { Gavel, FormatListBulleted, People, Notes } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { IListItemLinkProps } from '../../../../Components/ListItemLink/ListItemLink';

export const AptorAdminSidebar = (props: ISidebarProps) => {
  const { formatMessage } = useIntl();

  const items: IListItemLinkProps[] = [
    {
      to: '/admin/law-portal',
      text: formatMessage({ id: 'admin.law-portal.header', defaultMessage: 'Lawportal' }),
      icon: <Gavel />,
    },
    {
      to: '/admin/customers',
      text: formatMessage({ id: 'admin.customers.header', defaultMessage: 'Customers' }),
      icon: <FormatListBulleted />,
    },
    {
      to: '/admin/users',
      text: formatMessage({ id: 'admin.users.header', defaultMessage: 'Users' }),
      icon: <People />,
    },
    {
      to: '/admin/audit',
      text: formatMessage({ id: 'admin.audit.header', defaultMessage: 'Audit' }),
      icon: <Notes />,
    },
  ];

  return (
    <Sidebar
      title={formatMessage({ id: 'component.home.aptor-admin', defaultMessage: 'Aptor - Admin' })}
      sidebarOpen={props.sidebarOpen}
      handleSidebarToggle={props.handleSidebarToggle}
      items={items}
    />
  );
};
