import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  description: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '50rem',
    },
    whiteSpace: 'break-spaces',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2),
  },
}));
