import React, { useState, useCallback } from 'react';
import { IHeaderSubMenuItem } from '../../Components/Header/Header';

type Module = {
  header: string;
  subMenu?: IHeaderSubMenuItem[];
  setHeader: (header: string, subMenu?: IHeaderSubMenuItem[]) => void;
};

export const useModule = (): Module => {
  const [header, setHeaderTitle] = useState<string>('');
  const [subMenu, setSubMenu] = useState<Array<IHeaderSubMenuItem> | undefined>([] || undefined);
  const setHeader = useCallback((header: string, subMenu?: IHeaderSubMenuItem[]) => {
    setHeaderTitle(header);
    setSubMenu(subMenu);
  }, []);

  return {
    header,
    subMenu,
    setHeader,
  };
};

export const ModuleContext = React.createContext<Module>({
  header: '',
  subMenu: undefined,
  setHeader: () => {},
});
