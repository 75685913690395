import React, { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';
import { FormControl } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { Moment } from 'moment';
import { FieldHelperOrErrors } from './FieldHelperOrErrors';
import { DateFieldProps, FormFieldRef } from './Field.props';
import { shallowCompare } from './shallowCompare';

import { useStyles } from './Field.styles';
import { TypedFormFieldValue } from '../Form/Form.types';
import ClearIcon from '@material-ui/icons/Clear';

export const DateTimeField = memo(
  forwardRef<FormFieldRef<Date>, DateFieldProps>((props, ref) => {
    const classes = useStyles();
    const { isDisabled, label, helperText, errorTexts, onChange, required } = props;
    const [value, setValue] = useState<Date | null>(
      (props.initialState as TypedFormFieldValue<Date> | undefined)?.value ?? null,
    );

    useImperativeHandle(ref, () => ({
      setValue: (value: TypedFormFieldValue<Date> | TypedFormFieldValue<Date>[] | undefined) => {
        if (!Array.isArray(value)) {
          setValue(value?.value ?? null);
        }
      },
    }));

    useEffect(() => {
      if (onChange) {
        if (value === null) {
          onChange(undefined);
        } else {
          onChange({
            value: value,
            isValid: required && value === null ? false : true,
          });
        }
      }
    }, [value, onChange, required]);

    const handleChange = (moment: Moment | null) => {
      setValue(moment?.toDate() ?? null);
    };

    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
      <FormControl error={props.error} className={classes.formControl}>
        <Typography className={classes.label} component="label">
          {label}
          {props.required&&label && <span aria-hidden="true">*</span>}
        </Typography>
        <KeyboardDateTimePicker
          variant="inline"
          InputProps={{
            disableUnderline: true,
            required: props.required,
            classes: { focused: classes.focused, error: classes.error },
          }}
          keyboardIcon={(value && !required && <ClearIcon />) || undefined}
          onOpen={() => {
            if (value && !required) {
              setValue(null);
              setIsOpen(false);
            } else {
              setIsOpen(true);
            }
          }}
          onClose={() => {
            setIsOpen(false);
          }}
          open={isOpen}
          disabled={isDisabled}
          value={value}
          helperText={''}
          onChange={handleChange}
          placeholder={props.placeholder}
          autoOk
          ampm={false}
          format="YYYY-MM-DD HH:mm"
        />

        <FieldHelperOrErrors helperText={helperText} errorTexts={errorTexts} />
      </FormControl>
    );
  }),
  (newProps, oldProps) => {
    return shallowCompare(newProps, oldProps, ['errorTexts']);
  },
);

export default DateTimeField;
