import {
    Button, Dialog, DialogActions, DialogContent,
    //  DialogContentText,
    DialogTitle, useMediaQuery, useTheme
} from "@material-ui/core";
import React, { useCallback, useContext } from 'react'
import { MTable } from "../../../../../Components";
// import { Link } from "react-router-dom";
import { useAptorApi } from "../../../../../Api";
import { useIntl } from "react-intl";
import { AccessLevel, LawPortalFeature, UserContext } from "../../../../../Context/UserContext/UserContext";
import moment from "moment";
import { Query } from "material-table";
import { IGridQueryRequest, OrderDirection } from "../../../../../Api/AptorApi";
interface Iprops {
    open: boolean
    setOpen: (val: boolean) => void;
    lawId: any
}
type ReportData = {
    id: number;
    law: string;
    controller: string;
    companyUnit: string;
    date: Date;
};
const LatestComplianceControlDialog = ({ open, setOpen, lawId }: Iprops) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const tableRef = React.createRef();
    const { api } = useAptorApi();
    const { formatMessage } = useIntl();
    const { getCompanyUnitName, hasAccessToFeature, complianceUserFor } = useContext(UserContext);
    const handleClose = () => {
        setOpen(false)
    };
    const renderDate = (date?: Date) => {
        return date ? moment(date).format('ll') : undefined;
    };

    const canDownloadAllReports = hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);
    const canDownloadReport = (row: any) => {
        return (
            canDownloadAllReports ||
            complianceUserFor.laws.some(
                (x) => x.customerCompanyUnitId === row.customerCompanyUnitId && x.id === row.id,
            )
            // complianceUserFor.laws.some(
            //     (x) => x.customerCompanyUnitId === row.customerCompanyUnitId && x.id === row.requirementId,
            // )
        );
    };

    const renderDownloadAction = (row: any) => {
        return {
            tooltip: formatMessage({ id: 'law-portal.statistics.table.download-compliance' }),
            icon: 'picture_as_pdf',
            onClick: () => {
                api.downloadLawComplianceReport(lawId, row.id)
            },
            disabled: !canDownloadReport(row),
        };
    };
    const handleDataFetch = useCallback(
        async (query: Query<ReportData>) => {
            return await api.searchLawComplianceReports<ReportData>(lawId, {
                orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
                orderBy: query.orderBy !== undefined ? query.orderBy.field : 'date',
                page: query.page,
                pageSize: query.pageSize,
                search: query.search,
            } as IGridQueryRequest);
        },
        [lawId, api],
    );

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth="md" // You can adjust this value based on your needs
            fullWidth
        >
            <DialogTitle id="responsive-dialog-title">
                {formatMessage({ id: "law-portal.our-laws.table-row.popup.title", defaultMessage: "Evaluation Compliance" })}
            </DialogTitle>
            <DialogContent>
                <div style={{ paddingTop: "10px", paddingLeft: "30px", paddingRight: "30px", paddingBottom: '10px' }}>
                    <MTable
                        tableRef={tableRef}
                        columns={[
                            {
                                title: formatMessage({ id: 'law-portal.statistics.table.law' }),
                                field: 'law',
                                // render: (row: any) => <Link to={`/law-portal/our-laws/${row.id}`}>{row.law}</Link>,
                            },
                            {
                                title: getCompanyUnitName(formatMessage),
                                field: 'companyUnit',
                            },
                            {
                                title: formatMessage({ id: 'law-portal.compliance.report.table.col' }),
                                field: 'controller',
                            },
                            {
                                title: formatMessage({ id: 'law-portal.statistics.table.control-date' }),
                                field: 'date',
                                render: (row: any) => renderDate(row.date),
                            }
                        ]}
                        // hideToolbar
                        data={handleDataFetch}
                        actionLabel={''}
                        actions={[renderDownloadAction]}
                        components={{}}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button style={{marginTop:'25px',marginRight: '42px',marginBottom:"10px"}} variant="outlined" onClick={handleClose}>
                    {formatMessage({ id: 'form.cancel' })}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default LatestComplianceControlDialog