import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { BackButton, Container, Form, Panel } from '../../../../Components';
import { AptorApi, useAptorApi } from '../../../../Api';
import { FormValues } from '../../../../Components/Form/Form.types';
import { useFormField } from '../../../../Components/Form/FormField';
import { useRouteMatch } from 'react-router-dom';
import { INamedEntity } from '../../../../Api/AptorApi';

interface IPropsForm extends IPropsInline {
  name: string;
}

const EditNameForm = (props: IPropsForm) => {
  const { formatMessage } = useIntl();

  const nameField = useFormField({
    label: formatMessage({ id: 'form.editName' }),
    name: 'name',
    type: 'text',
    required: true,
    initialState: { value: props.name },
  });

  return <Form groups={[{ grid: { type: 'row', items: [nameField] } }]} submit={props.submit} />;
};

interface IPropsInline {
  name: string | undefined;
  submit: (data: FormValues, api: AptorApi, onSuccess: (successMessage?: string | null) => void) => Promise<void>;
}

export const EditNameInline: FC<IPropsInline> = ({ name, submit }) => {
  if (name === undefined) return <></>;
  return <EditNameForm name={name} submit={submit} />;
};

interface IProps {
  titleKey: string;
  backToLink: string;
  fetcher: (id: number) => Promise<INamedEntity>;
  submit: (id: number, data: FormValues, api: AptorApi) => Promise<void>;
}

export const EditName: FC<IProps> = ({ titleKey, backToLink, fetcher, submit }) => {
  const match = useRouteMatch<{ id: string }>();
  const id = parseInt(match.params.id);
  const { abortController } = useAptorApi();
  const initFlag = useRef(false);
  const [name, setName] = useState<string>();

  //Initialization
  useEffect(() => {
    const initializeField = async () => {
      initFlag.current = true;
      const info = await fetcher(id);
      if (abortController.current.signal.aborted) {
        return;
      }
      setName(info.name);
    };
    if (!initFlag.current) {
      initializeField();
    }
  }, [id, abortController, fetcher]);

  const submitChange = useCallback(
    async (data: FormValues, api: AptorApi, onSuccess: (successMessage?: string | null) => void) => {
      await submit(id, data, api);
      onSuccess();
    },

    [id, submit],
  );

  return (
    <Container>
      <BackButton link={backToLink} />
      <Panel titleKey={titleKey}>
        <EditNameInline name={name} submit={submitChange} />
      </Panel>
    </Container>
  );
};
