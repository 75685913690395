import { useReducer, useCallback } from 'react';

type FilterValueType = string[] | { min?: Date; max?: Date } | undefined;
type OnSetFilter = (field: string, values: FilterValueType) => void;
type StateType = { [field: string]: FilterValueType };

interface ISetFilter {
  type: 'update';
  field: string;
  values: FilterValueType;
}

const hasChanged = (state: StateType, field: string, newValue: FilterValueType) => {
  if (state[field] === newValue) {
    return false;
  } else if (Array.isArray(newValue)) {
    return !(newValue.length === 0 && state[field] === undefined);
  } else if (newValue !== undefined) {
    return !(newValue.min === undefined && newValue.max === undefined && state[field] === undefined);
  }
  return true;
};

const reducer = (state: StateType, action: ISetFilter): StateType => {
  if (action.type === 'update' && hasChanged(state, action.field, action.values)) {
    return { ...state, ...{ [action.field]: action.values } };
  }
  return state;
};

export const useFilter = (): [any, OnSetFilter] => {
  const [state, dispatch] = useReducer(reducer, {});
  const onSetFilter = useCallback(
    (field: string, values: FilterValueType) => dispatch({ type: 'update', field, values }),
    [],
  );
  return [state, onSetFilter];
};
