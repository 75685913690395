import React, {
    useCallback, useContext,
    //  useEffect,
    //   useState
} from 'react'
import { MTable } from "../../../../../Components"
import moment from "moment";
import { useAptorApi } from "../../../../../Api";
import { useIntl } from "react-intl";
import {
    AccessLevel,
    LawPortalFeature,
    UserContext
} from "../../../../../Context/UserContext/UserContext";
import { Link } from "react-router-dom";
import { Query } from "material-table";
import { IGridQueryRequest, OrderDirection } from "../../../../../Api/AptorApi";
// import { Search } from "@material-ui/icons";
// type CompliancecReport = {
//     id: number;
//     requirementId: number;
//     requirementName: string;
//     effectiveFrom: Date;
//     customerCompanyUnitId: number;
//     customerCompanyUnit: string;
//     userId: number;
//     userName: string;
//     controlDate: Date;
//     unplanned: boolean;
// };
interface IProps {
    filter: any,
    search: any,
    planId: any,
    plan: any
}
const ReportTableView = ({ filter, search, planId, plan }: IProps) => {
    const { api } = useAptorApi();
    const { formatMessage } = useIntl();
    const tableRef = React.createRef();
    const {
        complianceUserFor,
        hasAccessToFeature,
        getCompanyUnitName } = useContext(UserContext);
    const canDownloadAllReports = hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);
    const canDownloadReport = (row: any) => {
        return (
            canDownloadAllReports ||
            complianceUserFor.laws.some(
                (x) => x.customerCompanyUnitId === row.customerCompanyUnitId && x.id === row.id,
            )
            // complianceUserFor.laws.some(
            //     (x) => x.customerCompanyUnitId === row.customerCompanyUnitId && x.id === row.requirementId,
            // )
        );
    };

    const renderDownloadAction = (row: any) => {
        return {
            tooltip: formatMessage({ id: 'law-portal.statistics.table.download-compliance' }),
            icon: 'picture_as_pdf',
            onClick: () => {
                plan.type === 0 ? api.downloadLawComplianceReport(row.id, row.reportId) : api.downloadRequirementComplianceReport(row.id, row.reportId)
            },
            disabled: !canDownloadReport(row),
        };
    };
    const renderDate = (date?: Date) => {
        return date ? moment(date).format('ll') : undefined;
    };

    const getPlanReport = useCallback(
        async (query: Query<any>) => {
            return await api.searchPlanReports(Number(planId), {
                orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
                orderBy: query.orderBy !== undefined ? query.orderBy.field : 'name',
                page: query.page,
                pageSize: query.pageSize,
                search: search,
                filters: filter,
            } as IGridQueryRequest);
        },
        [api, filter, search, planId],
    );

    return (
        <>
            <div style={{ paddingTop: "10px", paddingLeft: "30px", paddingRight: "30px" }}>
                <MTable
                    tableRef={tableRef}
                    columns={[
                        {
                            title: formatMessage({ id: 'law-portal.statistics.table.law' }),
                            field: 'name',
                            render: (row: any) => <Link to={`/law-portal/${plan?.type===0?'our-laws':'other-requirements'}/${row.id}`}>{row.name}</Link>,
                        },
                        {
                            title: getCompanyUnitName(formatMessage),
                            field: 'companyUnit',
                            sorting:false,
                        },
                        {
                            title: formatMessage({ id: 'law-portal.compliance.report.table.col' }),
                            field: 'responsible',
                            sorting:false,
                            render: (row: any) => {
                                if (row.responsible) {
                                  const responsiblearr = row?.responsible?.split(',')
                                  return (
                                    <>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        {responsiblearr?.map((val: any) => {
                                          return <span>{val}</span>
                                        })}
                                      </div>
                                    </>
                                  )
                                } else {
                                  return "-"
                                }
                              },
                        },
                        {
                            title: formatMessage({ id: 'law-portal.statistics.table.control-date' }),
                            field: 'controlDate',
                            sorting:false,
                            render: (row) => renderDate(row.controlDate),
                        }
                    ]}
                    hideToolbar
                    data={getPlanReport}
                    actionLabel={''}
                    actions={[renderDownloadAction]}
                    components={{}}
                />
            </div>
        </>
    )
}

export default ReportTableView