import packageJson from "../package.json";
(global as any).appVersion = packageJson.version;

const versionCheckInterval = process.env.REACT_APP_VERSION_CHECK_INTERVAL;

async function checkSiteVersion() {
  try {
    const currentVersion = (global as any).appVersion;
    const response = await fetch(`/meta.json?c=${Date.now()}`);
    const manifest = await response.json();
    const newVersion = manifest.version;
    if (currentVersion !== newVersion) {
      console.log(`Detected new version ${newVersion}`);

      window.location.reload(true);
    }
  } catch (exception) {}

  if (versionCheckInterval) {
    setTimeout(checkSiteVersion, Number.parseInt(versionCheckInterval));
  }
}

export function startVersionDetector() {
  const currentVersion = (global as any).appVersion;
  if (versionCheckInterval && Number.parseInt(versionCheckInterval) > 0) {
    console.log(`Current version ${currentVersion}`);

    checkSiteVersion();
  }
}
