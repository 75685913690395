import React from 'react';
import { Grid, Card, Typography, CardMedia, CardContent } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useStyles } from './CardLink.styles';

interface ICardLinkProps {
  label: string;
  image: string;
  link: string;
}
export const CardLink = (props: ICardLinkProps) => {
  const classes = useStyles();
  return (
    <Grid item md={3} sm={4} xs={12}>
      <Link className={classes.link} to={props.link}>
        <Card className={classes.card}>
          <CardMedia
            className={classes.cardMedia}
            image={props.image}
            component="img"
            alt={`Illustration of ${props.label}`}
          />
          <CardContent className={classes.cardContent}>
            <Typography className={classes.label} variant="h4" component="h3" align="center">
              <FormattedMessage id={props.label} defaultMessage="Module label" description="Module label" />
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
};
