import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAptorApi } from '../../../../../Api';
import { AptorApi, IApiCustomerInfo } from '../../../../../Api/AptorApi';
import { BackButton, Container, Loading, Panel } from '../../../../../Components';
import { FormValues, SelectOption } from '../../../../../Components/Form/Form.types';
import { useConfirmation } from '../../../../../Context/ConfirmationContext/ConfirmationContext';
import { UpdateCustomerForm } from './Form';
import { UpdateCustomerOrganizationUsers } from './OrganizationUsers';
import { useStyles } from "../../../AptorAdminModule.styles";
import {Button,Tooltip} from '@material-ui/core'
import {ArrowUpward} from '@material-ui/icons'

export const UpdateCustomer = () => {
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const id = parseInt(match.params.id);
  const { api, abortController } = useAptorApi();
  const [customer, setCustomer] = useState<IApiCustomerInfo>();
  const [countries, setCountries] = useState<SelectOption[]>();
const classes=useStyles()
  const { deleteConfirmation } = useConfirmation();
  const { formatMessage } = useIntl();

  useEffect(() => {
    const fetch = async () => {
      const customer = await api.getCustomer(id);
      const countries = await api.getCountries();
      if (abortController.current.signal.aborted) {
        return;
      }
      setCustomer(customer);
      setCountries(countries.items.map((i) => ({ value: i.id, label: i.name })));
    };
    fetch();
  }, [api, abortController, id]);

  const handleSubmit = async (data: FormValues, api: AptorApi, onSuccess: (key: string) => void) => {
    const onAccept = async () => {
      await api.updateCustomer(+id, data);
      onSuccess('admin.customers.edit.savedChanges');
      history.push('/admin/customers');
    };

    const previousFeatures = customer?.selectedFeatures.map((x) => x.id) ?? [];
    const currentFeatures = (data['selectedModuleFeatures'] as Number[]) ?? [];
    const hasAdded = currentFeatures.some((f) => !previousFeatures.some((id) => id === f));
    const hasRemoved = previousFeatures.some((f) => !currentFeatures.some((id) => id === f));

    return hasAdded || hasRemoved
      ? deleteConfirmation(onAccept, {
          title: formatMessage({ id: 'admin.customers.edit.featuresChangedConfirmation' }),
          description: (
            <ul>
              <li>{formatMessage({ id: 'admin.customers.edit.featuresChangedConfirmation.added' })}</li>
              <li>{formatMessage({ id: 'admin.customers.edit.featuresChangedConfirmation.removed' })}</li>
            </ul>
          ),
        })
      : onAccept();
  };
  const [showScrollTop, setShowScrollTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
    <Container>
      <BackButton link="/admin/customers" />
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Panel title={customer?.name} titleKey="admin.customers.organization-users.customer">
            {customer === undefined || countries === undefined ? (
              <Loading />
            ) : (
              <UpdateCustomerForm countries={countries} customerInfo={customer} submit={handleSubmit} />
            )}
          </Panel>
        </Grid>
        <Grid item>
          <Panel titleKey="admin.customers.organization-users">
            {customer !== undefined && <UpdateCustomerOrganizationUsers users={customer.organizationUsers} />}
          </Panel>
        </Grid>
      </Grid>
    </Container>
    {showScrollTop && (
        <Tooltip title={formatMessage({id:"bottom.to.top.btn.tooltip.text",defaultMessage:"Back to top"})}>
          <Button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className={classes.topButton}
          >
            <ArrowUpward style={{ fontSize: 20 }} />
          </Button>
        </Tooltip>
      )}
    </>
  );
};
