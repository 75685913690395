import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Container } from '../../../../Components';
import { ModuleContext } from '../../../../Context/ModuleContext/ModuleContext';
import { CarryKEK } from './CarryKEK';
import { PlanningKEK } from './PlanningKEK';
import { ReportsKEK } from './ReportsKEK';
import { ConfirmationSessionProvider } from "../../../../Context/ComplianceSessionContext";
import { ILawInfo } from "../OurLaws";
import { useAptorApi } from "../../../../Api";
import { CreateLawComplianceReport } from "./CarryKEK/Compliance/Create";

const ComplianceView = () => {
  const match = useRouteMatch<{ id: string }>();
  const id = parseInt(match.params.id);
  const { api, abortController } = useAptorApi();
  const [info, setInfo] = useState<ILawInfo>();
  useEffect(() => {
    api.getLawPortalLawInfo<ILawInfo>(id).then((result) => {
      if (abortController.current.signal.aborted) {
        return;
      }
      setInfo(result);
    });
  }, [api, abortController, id]);

  if (info === undefined) {
    return <></>;
  }

  return (
    // <Grid container spacing={2}>
    <CreateLawComplianceReport />

  );
};
export const ComplianceControlRequirement = () => {
  const { formatMessage } = useIntl();
  const { setHeader } = useContext(ModuleContext);
  useEffect(() => {
    setHeader(formatMessage({ id: 'law-portal.legal.requirement.header', defaultMessage: 'Compliance control requirement' }), [
      {
        title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.planning.kek' }),
        to: `/law-portal/compliance-control-requirements/planning`,
      },
      {
        title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.carry.kek' }),
        to: `/law-portal/compliance-control-requirements/carry`,
      },
      {
        title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.reports.kek' }),
        to: `/law-portal/compliance-control-requirements/reports`,
      },
    ]);
  }, [setHeader, formatMessage]);
  return (
    <Container fullWidth>
      <ConfirmationSessionProvider>
        <Switch>
          <Route path="/law-portal/compliance-control-requirements/carry" component={CarryKEK} />
          <Route path="/law-portal/compliance-control-requirements/:id(\d+)/compliance/report" component={ComplianceView} />
          <Route exact path="/law-portal/compliance-control-requirements/planning" component={PlanningKEK} />
          <Route path="/law-portal/compliance-control-requirements/reports" exact component={ReportsKEK} />
          <Route path="/law-portal/compliance-control-requirements">
            <Redirect to="/law-portal/compliance-control-requirements/planning" />
          </Route>
        </Switch>
      </ConfirmationSessionProvider>
    </Container>
  );
};
