import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: theme.spacing(2),
  },
  iconButton: {
    color: theme.palette.primary.main,
    paddingLeft: 0,
    paddingRight: 0,
  },
  menu: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
  },
}));
