import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { NotesForm } from './Form';
import { HtmlView } from '../../../../Components';
import { AptorApi, useAptorApi } from '../../../../Api';
import { INamedEntity } from '../../../../Api/AptorApi';
import { useStyles } from './View.styles';
import { useConfirmation } from '../../../../Context/ConfirmationContext/ConfirmationContext';

interface ICompanyUnitWithNote extends INamedEntity {
  note: string | null;
}

interface IProps {
  companyUnits: ICompanyUnitWithNote[];
  editNote?: (companyUnitId: number, note: string | null, api: AptorApi, onSuccess: () => void) => Promise<void>;
}

export const NotesView: FC<IProps> = ({ companyUnits, editNote }) => {
  const classes = useStyles();
  const { api } = useAptorApi();
  const { deleteConfirmation } = useConfirmation();
  const { formatMessage } = useIntl();
  const [editing, setEditing] = useState<ICompanyUnitWithNote>();
  const closeEditing = () => setEditing(undefined);

  const onEdited = async (companyUnitId: number, note: string, api: AptorApi, onSuccess: () => void) => {
    if (editing && editNote) {
      await editNote(companyUnitId, note, api, onSuccess).then(() => closeEditing());
    }
  };

  const onDelete = async (companyUnit: ICompanyUnitWithNote) => {
    if (editNote) {
      deleteConfirmation(() => editNote(companyUnit.id, null, api, () => {}), {
        description: formatMessage({ id: 'law-portal.overview-notes.delete-confirmation.message' }),
      });
    }
  };

  if (editing) {
    const editableUnits = companyUnits.filter((x) => editing.id === x.id);
    return <NotesForm value={editing} companyUnits={editableUnits} submit={onEdited} cancel={closeEditing} />;
  }

  return (
    <List disablePadding>
      {companyUnits
        .filter((x) => x.note !== null)
        .map((item) => (
          <ListItem disableGutters key={item.id}>
            <Card variant="outlined" className={classes.card}>
              <CardContent>
                <Typography variant="overline" component="h4">
                  {item.name}
                </Typography>
                <HtmlView variant="body2" value={item.note!} />
              </CardContent>
              {editNote && (
                <CardActions>
                  <Grid container justify="flex-end">
                    <Tooltip title={formatMessage({ id: 'utils.icon.delete', defaultMessage: 'Delete' })}>
                      <IconButton
                        aria-label={formatMessage({ id: 'utils.icon.delete', defaultMessage: 'Delete' })}
                        onClick={() => onDelete(item)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={formatMessage({ id: 'utils.icon.edit', defaultMessage: 'Delete' })}>
                      <IconButton
                        aria-label={formatMessage({ id: 'utils.icon.edit', defaultMessage: 'Delete' })}
                        onClick={() => setEditing(item)}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </CardActions>
              )}
            </Card>
          </ListItem>
        ))}
    </List>
  );
};
