import React, { useContext, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { AuditList } from './List';
import { AuditView } from './View';
import { ModuleContext } from '../../../../Context/ModuleContext/ModuleContext';

export const Audit = () => {
  const { formatMessage } = useIntl();
  const { setHeader } = useContext(ModuleContext);
  useEffect(() => {
    setHeader(formatMessage({ id: 'admin.audit.header', defaultMessage: 'User logg' }));
  });
  return (
    <Switch>
      <Route exact path="/admin/audit" component={AuditList} />
      <Route exact path="/admin/audit/:id(\d+)" component={AuditView} />
      <Route path="/admin/audit"><Redirect to="/admin/audit" /></Route>
    </Switch>
  );
};
