import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
topButton: {
    borderRadius: ' 50%',
    position: 'fixed',
    bottom: '10px',
    transform: 'translateX(-44px)',
    justifyContent: 'center',
    alignItems: 'center',
    right: 0,
    display: 'flex',
    //height: '46px',
    opacity: .7,
    width: 50,
    height: 50
  },
}));