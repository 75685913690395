import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  name: {
    marginRight: theme.spacing(1),
  },
  repealedLabel: {
    padding: theme.spacing(1),
    lineHeight: 'inherit',
    backgroundColor: theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
  },
}));
