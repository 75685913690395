import React, { ReactNode, FC } from 'react';
import clsx from 'clsx';
import { Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useStyles } from './Container.styles';
import { BackButton } from '../BackButton/BackButton';

export const Container: FC<{
  children?: ReactNode;
  backButtonTo?: string;
  labelKey?: string;
  linkButtonLabel?: string;
  linkButtonTo?: string;
  secondaryLinkButtonLabel?: string;
  secondaryLinkButtonTo?: string;
  fullWidth?: boolean;
}> = ({
  children,
  backButtonTo,
  labelKey,
  linkButtonLabel,
  linkButtonTo,
  secondaryLinkButtonTo,
  secondaryLinkButtonLabel,
  fullWidth,
}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, { [classes.fullWidth]: fullWidth })}>
      {backButtonTo && <BackButton link={backButtonTo} />}
      {labelKey && (
        <Typography className={classes.label} component="h2" variant="h3">
          <FormattedMessage id={labelKey} />
        </Typography>
      )}
      {linkButtonLabel && linkButtonTo && (
        <div className={classes.linkButtonContainer}>
          <Button component={Link} to={linkButtonTo} variant="outlined">
            <FormattedMessage id={linkButtonLabel} />
          </Button>
          {secondaryLinkButtonTo && secondaryLinkButtonLabel && (
            <Button
              className={`${classes.linkButtonContainer} ${classes.secondaryLinkButtonContainer}`}
              component={Link}
              to={secondaryLinkButtonTo}
              variant="outlined"
            >
              <FormattedMessage id={secondaryLinkButtonLabel} />
            </Button>
          )}
        </div>
      )}
      {children}
    </div>
  );
};
