import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2),
    borderLeft: `6px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.dark,
  },
  title: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  content: {
    paddingTop: theme.spacing(2),
  },
}));
