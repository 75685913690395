import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Form } from '../../../../../Components';
import { FormValues } from '../../../../../Components/Form/Form.types';
import { useFormField } from '../../../../../Components/Form/FormField';
import { AptorApi } from '../../../../../Api';

interface IProps {
  submit: (data: FormValues, api: AptorApi, onSuccess: (key: string) => void) => Promise<void>;
}

export const ReinviteCustomerUsersForm: FC<IProps> = ({ submit }) => {
  const { formatMessage } = useIntl();

  //Email information
  const emailField = useFormField({
    label: formatMessage({ id: 'form.email' }),
    name: 'email',
    type: 'email',
    placeholder: formatMessage({ id: 'form.fillInEmail' }),
    required: true,
    hideRequiredAsterisk: true,
  });

  return (
    <Form
      submit={submit}
      submitLabel={formatMessage({ id: 'admin.users.table.resendInvite' })}
      singleField={emailField}
    />
  );
};
