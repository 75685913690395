import { Api } from './Api';
// import { getAuthProvider } from '../../Auth/AuthProvider';
import {
  IMPERSONATION_KEY,
  IMPERSONATION_INVITATION_KEY,
  SupportInvitation,
} from '../../Context/UserContext/UserContext';
import { getAuthProvider } from '../../Auth/AuthProvider';

export class AuthenticatedApi extends Api {
  private locale?: string;

  constructor(abortController: AbortController, baseAddress?: string, locale?: string) {
    super(baseAddress, abortController);
    this.locale = locale;
  }

  protected async appendHeaders(headers: any = {}) {
    const idToken = await this.getIdToken();
    let resolvedHeaders = super.appendHeaders(headers);

    if (this.locale) {
      resolvedHeaders['Accept-Language'] = this.locale;
    }

    if (idToken) {
      resolvedHeaders['Authorization'] = `Bearer ${idToken}`;
      const impersonatedAs = localStorage.getItem(IMPERSONATION_KEY);
      if (impersonatedAs) {
        resolvedHeaders['ImpersonatedAs'] = impersonatedAs;
      }

      const supportInvitation = localStorage.getItem(IMPERSONATION_INVITATION_KEY);
      if (supportInvitation) {
        resolvedHeaders['SupportInvitation'] = (JSON.parse(supportInvitation) as SupportInvitation)?.id;
      }
    }
    return resolvedHeaders;
  }

  protected async executeRequest(response: () => Promise<Response>): Promise<Response> {
    var result = await response();
    if(result.status === 401){
      const authProvider = getAuthProvider();
      localStorage.clear();
      authProvider.logout();
      }
    this.checkStatus(result);
    return result;
  }

  private async getIdToken() {
    const accessToken = localStorage.getItem('accessToken');
    return accessToken;
  }
}
