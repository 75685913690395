import React, { FC } from 'react';
import { Card, List } from '@material-ui/core';
import { ChecklistItem, IProps as IChecklistProps } from '../Checklist.Item/Checklist.Item';
import { useStyles } from './Checklist.Group.styles';

interface IQuestion {
  id?: number;
  name: string;
}

interface IProps {
  label: string;
  disableUp?: boolean;
  disableDown?: boolean;
  moveUp: () => void;
  moveDown: () => void;
  remove: () => void;
  skipRemoveConfirmation?: boolean;
  header?: boolean;
  items: IQuestion[];
  moveTopItemOut: () => void;
  moveBottomItemOut: () => void;
  onItemsChange: (items: IQuestion[]) => void;
  itemindex: any;
  editGroupChildrenItem: (val: any, childIndex: any) => void;
  editGroupItem: (val: any) => void;
  handleDragStart: (index: number, isChild: boolean, childIndex?: number) => void;
  handleDrop: (index: number, isChild: boolean, childIndex?: number) => void;
}

export const ChecklistGroupItem: FC<IProps> = ({
  label,
  items,
  moveUp,
  moveDown,
  remove,
  disableUp,
  disableDown,
  moveTopItemOut,
  moveBottomItemOut,
  onItemsChange,
  itemindex,
  editGroupChildrenItem,
  editGroupItem,
  handleDragStart,
  handleDrop,
}) => {
  const swapGroupItems = (indexA: number, indexB: number) => {
    if (indexB === -1) {
      moveTopItemOut();
    } else if (indexB === items.length) {
      moveBottomItemOut();
    } else {
      const groupItems = [...items];
      groupItems[indexA] = items[indexB];
      groupItems[indexB] = items[indexA];
      onItemsChange(groupItems);
    }
  };

  const removeGroupItem = (index: number) => onItemsChange(items.filter((_, i) => i !== index));

  const classes = useStyles();

  return (
    <Card variant="outlined" component="li" className={classes.card}>
      <List
        className={classes.list}
        aria-labelledby={label}
        subheader={
          <ChecklistItem
            header
            label={label}
            itemindex={`${itemindex}.`}
            moveUp={moveUp}
            moveDown={moveDown}
            disableUp={disableUp}
            disableDown={disableDown}
            remove={remove}
            skipRemoveConfirmation
            editItem={(val: any) => editGroupItem(val)}
          />
        }
      >
        {items.map((question, index) => (
          <div
            key={`group-${index}`}
            draggable
            onDragStart={() => handleDragStart(itemindex, true, index)}
            onDragOver={(e) => e.preventDefault()}
            onDrop={() => handleDrop(itemindex, true, index)}
          >
            <ChecklistItem
              label={question.name}
              itemindex={`${itemindex}.${index + 1}`}
              moveUp={() => swapGroupItems(index, index - 1)}
              moveDown={() => swapGroupItems(index, index + 1)}
              remove={() => removeGroupItem(index)}
              editItem={(val: any) => editGroupChildrenItem(val, index)}
            />
          </div>
        ))}
      </List>
    </Card>
  );
};
