import React, { FC, useContext } from 'react';
import { Panel } from '../../../../../../Components';
import { RequirementChange } from './RequirementChange';
import { UserContext } from '../../../../../../Context/UserContext/UserContext';
import { useAptorApi } from '../../../../../../Api';
import { IRequirementChange } from '.';
import { Typography } from '@material-ui/core';


interface IProps {
  reqId: number;
  change: IRequirementChange;
  onChange: (state: { latestChange: IRequirementChange }) => void;
}

export const LatestChange: FC<IProps> = ({ reqId, change, onChange }) => {
  const { api } = useAptorApi();
  const user = useContext(UserContext);
  const handleAcknowledge = async () => {
    await api.acknowledgeLawPortalRequirementChange(reqId, change.id);
    const latestChange = {
      ...change,
      ...{ unacknowledgedByUsers: change.unacknowledgedByUsers?.filter((id) => id !== user.id) },
    };
    onChange({ latestChange });
  };

  return (
    <Panel titleKey="law-portal.requirements.changes.latest-change.label">
      <Typography variant="h4">{change.name}</Typography>
      <RequirementChange reqId={reqId} change={change} acknowledge={handleAcknowledge} />
    </Panel>
  );
};
