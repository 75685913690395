import React, { FC, useState } from 'react';
import { List, ListItem } from '@material-ui/core';
import { Panel } from '../../../../Components';
import { InternalCommentForm } from './Form';
import { InternalComment } from './Comment';
import { IInternalComment, INamedEntity } from '../../../../Api/AptorApi';

export interface ICompanyUnit extends INamedEntity {
  taggableUsers: INamedEntity[];
}

interface IProps {
  comments?: IInternalComment[];
  companyUnits: ICompanyUnit[];
  addComment: (companyUnitId: number, message: string, taggedUsers: INamedEntity[]) => Promise<void>;
  editComment: (comment: IInternalComment) => Promise<void>;
  deleteComment: (id: number) => Promise<void>;
}

export const InternalComments: FC<IProps> = ({ comments, companyUnits, addComment, editComment, deleteComment }) => {
  const [rev, setRev] = useState<number>(0);
  const [editingComment, setEditingComment] = useState<IInternalComment | undefined>();

  const onSubmit = async (companyUnitId: number, message: string, taggedUsers: INamedEntity[]) => {
    if (editingComment) {
      const comment = {
        ...editingComment,
        ...{ comment: message, companyUnit: companyUnits.find((x) => x.id === companyUnitId)!, taggedUsers },
      };
      await editComment(comment).then(() => {
        setEditingComment(undefined);
        setRev(rev + 1);
      });
    } else {
      await addComment(companyUnitId, message, taggedUsers).then(() => setRev(rev + 1));
    }
  };

  const onEditComment = (id: number) => {
    var commentToEdit = comments?.find((x) => x.id === id);
    setEditingComment(commentToEdit);
  };

  return (
    <Panel autoHeight titleKey="law-portal.internal-comments.title">
      <List>
        {comments &&
          comments.map((item) => (
            <ListItem disableGutters key={`comment-${item.id}`}>
              <InternalComment {...item} deleteComment={deleteComment} editComment={onEditComment} />
            </ListItem>
          ))}
      </List>
      <InternalCommentForm
        key={editingComment?.id ?? rev}
        companyUnits={companyUnits}
        editComment={editingComment}
        submit={onSubmit}
      />
    </Panel>
  );
};
