import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Checkbox, FormControl, FormControlLabel, Typography } from '@material-ui/core';
import { FieldHelperOrErrors } from './FieldHelperOrErrors';
import { useStyles } from './Field.styles';
import { CheckboxFieldProps } from './Field.props';
import { TypedFormFieldValue } from '../Form/Form.types';

export const CheckboxField = (props: CheckboxFieldProps) => {
  const classes = useStyles();
  const { isDisabled, label, helperText, errorTexts, onChange,onhandle, ...rest } = props;
  const initialValue = (props.initialState as TypedFormFieldValue<boolean> | undefined)?.value ?? false;
  const [value, setValue] = useState<boolean>(props?.initialValue||initialValue);
  const [isFocused, setIsFocused] = useState(initialValue);

  useEffect(() => onChange && onChange({ value, isValid: true }), [value, onChange]);
  return (
    <FormControl
      error={props.error}
      className={clsx(classes.formControl, classes.checkbox, { [classes.checkboxError]: rest.error && !isFocused })}
    >
      <FormControlLabel
        name={props.name}
        control={
          <Checkbox
            checked={value}
            color="primary"
            onChange={(_, value) =>{setValue(value);if(onhandle){
              onhandle!(value);
            }}}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            required={props.required}
          />
        }
        label={
          <Typography className={classes.label} component="label">
            {props.label}
            {props.required && <span aria-hidden="true">*</span>}
          </Typography>
        }
      />
      <FieldHelperOrErrors helperText={helperText} errorTexts={errorTexts} />
    </FormControl>
  );
};

export default CheckboxField;
