import { useState, useEffect, Dispatch, SetStateAction } from 'react';

export function useStateWithSessionStorage<T>(sessionStorageKey: string, defaultValue?: T){
  return useStateStorage<T>(sessionStorage, sessionStorageKey, defaultValue);
}

export function useStateWithLocalStorage<T>(localStorageKey: string, defaultValue?: T){
  return useStateStorage<T>(localStorage, localStorageKey, defaultValue);
}

function useStateStorage<T>(storage: Storage, storageKey: string, defaultValue?: T): [T|undefined, Dispatch<SetStateAction<T|undefined>>,  T|undefined] {
  const getDefaultValue = () => {
    const storageValue = storage.getItem(storageKey);
    return (storageValue && JSON.parse(storageValue) as T) || defaultValue;
  }
  const [initialState] = useState<T | undefined>(getDefaultValue());
  const [value, setValue] = useState<T | undefined>(getDefaultValue());

  useEffect(() => {
    if(value !== undefined){
      storage.setItem(storageKey, JSON.stringify(value));
    }
    else{
      storage.removeItem(storageKey);
    }
  }, [value, storageKey, storage]);

  return [value, setValue, initialState];
}
