import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { BackButton, Container, Loading, Panel } from '../../../../../Components';
import { FormValues, SelectOption } from '../../../../../Components/Form/Form.types';
import { AptorApi, useAptorApi } from '../../../../../Api';
import { entityToSelectOption } from '../../../../../Utilities';
import { UserForm, ICustomerUser, IPermissions } from './Users.Form';
import { useApiValidationSnackbar } from '../../../../../Api/useValidationSnackbar';
import { UserContext } from '../../../../../Context/UserContext/UserContext';
import { getAuthProvider } from '../../../../../Auth/AuthProvider';

interface IState {
  user: ICustomerUser & { permissions: IPermissions };
  languages: SelectOption[];
  companyUnits: SelectOption[];
}

export const UserEditForm = () => {
  const { api, abortController } = useAptorApi();
  const { notifyUnhandledException, notifyValidationErrors } = useApiValidationSnackbar();
  const identity = useContext(UserContext);
  const match = useRouteMatch<{ companyUnitId?: string; id: string }>();
  const id = parseInt(match.params.id);
  const isEditingYourself = identity.id === id;
  const companyUnitId = match.params.companyUnitId ? parseInt(match.params.companyUnitId) : undefined;
  const [state, loadState] = useState<IState>();
  const history = useHistory();

  const returnLink = companyUnitId ? `/organization/company-units/${companyUnitId}` : '/organization';

  useEffect(() => {
    Promise.all([
      api.getCustomerUser<ICustomerUser & { permissions: IPermissions }>(id),
      api.getLanguages(),
      api.getOrganizationCompanyUnits(),
    ]).then(([user, lang, units]) => {
      if (abortController.current.signal.aborted) {
        return;
      }
      loadState({
        user,
        languages: lang.items.map(entityToSelectOption),
        companyUnits: companyUnitId
          ? units.items.filter((i) => i.id === companyUnitId).map(entityToSelectOption)
          : units.items.map(entityToSelectOption),
      });
    });
  }, [api, id, companyUnitId, abortController]);

  const handleSubmit = async (data: FormValues, api: AptorApi, onSuccess: (key: string) => void) => {
    const action = (a: AptorApi) =>
      a.updateCustomerUser(companyUnitId, id, data).then(() => {
        if (isEditingYourself) {
          onSuccess('form.successfullyUpdatedUser.logout');
          getAuthProvider().logout();
        } else {
          onSuccess('form.successfullyUpdatedUser');
          history.push(returnLink);
        }
      });

    await api.invoke(action, abortController.current, notifyValidationErrors, notifyUnhandledException);
  };

  if (state === undefined) {
    return <Loading />;
  }

  return (
    <Container>
      <BackButton link={returnLink} />
      <Panel titleKey="admin.users.invite.editUser">
        <UserForm
          user={state.user}
          permissions={state.user.permissions}
          submit={handleSubmit}
          availableLanguages={state.languages}
          companyUnits={state.companyUnits}
          submitLabelKey={isEditingYourself ? 'form.save-and-logout' : undefined}
        />
      </Panel>
    </Container>
  );
};
