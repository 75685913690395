import { makeStyles, Theme } from '@material-ui/core/styles';

const maxCellWidth = '24rem';

export const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: theme.palette.grey[700],
    '& .MuiIconButton-root': {
      color: theme.palette.common.white,
    },
    '& .MuiIconButton-root.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
    },
    '& .MuiInput-underline': {
      color: theme.palette.common.white,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: theme.palette.common.white,
    },
    '& .MuiInputBase-formControl': {
      border: 'none',
      backgroundColor: 'transparent',
    },
  },
  row: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.grey[50],
    },
    '&.MuiTableRow-hover:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
    },
  },
  cell: {
    width: 'auto !important',
    maxWidth: maxCellWidth,
  },
  headerCell: {
    width: 'auto !important',
    maxWidth: maxCellWidth,
    '& .MuiCheckbox-root': {
      color: theme.palette.common.white,
    },
  },
}));
