import React, { FC, useCallback } from 'react';
import { AptorApi, useAptorApi } from '../../../../../../Api';
import { IGridQueryRequest } from '../../../../../../Api/AptorApi';
import { FormValues } from '../../../../../../Components/Form/Form.types';
import { Aspects, IAspect } from '../../Aspects';

export const LawAspects : FC<{ unitid: number, lawid: number }> = ({ unitid, lawid }) => {
  const { api } = useAptorApi();
  const optFetcher = useCallback(() => api.getComanyUnitLawAspects(lawid, unitid), [lawid, unitid, api])
  const dataFetcher = useCallback(async (request: IGridQueryRequest) => api.searchLawAspects<IAspect>(lawid, unitid, request), [lawid, unitid, api]);

  return (
    <Aspects
      optionFetcher={optFetcher}
      dataFetcher={dataFetcher}
      addNew={(api: AptorApi, data: FormValues) => api.addLawAspect(lawid, unitid, data)}
      remove={(api: AptorApi, aspectid: number) => api.removeLawAspect(lawid, unitid, aspectid)}
    />
  );
};
