import React, { FC, useCallback } from 'react';
import { AptorApi, useAptorApi } from '../../../../../../Api';
import { IGridQueryRequest } from '../../../../../../Api/AptorApi';
import { FormValues } from '../../../../../../Components/Form/Form.types';
import { Aspects, IAspect } from '../../Aspects';

export const RequirementAspects : FC<{ unitid: number, reqid: number }> = ({ unitid, reqid }) => {
  const { api } = useAptorApi();
  const optFetcher = useCallback(() => api.getCustomerRequirementAspects(reqid, unitid), [reqid, unitid, api]);
  const dataFetcher = useCallback((request: IGridQueryRequest) => api.searchRequirementAspects<IAspect>(reqid, unitid, request), [reqid, unitid, api]);

  return (
    <Aspects
      optionFetcher={optFetcher}
      dataFetcher={dataFetcher}
      addNew={(api: AptorApi, data: FormValues) => api.addRequirementAspect(reqid, unitid, data)}
      remove={(api: AptorApi, aspectid: number) => api.removeRequirementAspect(reqid, unitid, aspectid)}
    />
  );
};
