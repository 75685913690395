import React, { forwardRef, memo, useCallback, useState } from 'react';
import TextField from './TextField';
import { useIntl } from 'react-intl';
import { FormFieldRef, InputFieldProps } from './Field.props';
import { TypedFormFieldValue } from '../Form/Form.types';
import { shallowCompare } from './shallowCompare';

const POSTALCODE_REGEX = /^\d{3}\s?\d{2}$/g;

const isPostalCodeValid = (postalCode: string | undefined, required?: boolean) => {
  if (postalCode === undefined || postalCode.length === 0) {
    if (required !== undefined) {
      return !required;
    }
    return true;
  }
  return postalCode !== undefined && postalCode.match(POSTALCODE_REGEX) !== null;
};

export const PostalCodeField = memo(
  forwardRef<FormFieldRef<string>, InputFieldProps>((props, ref) => {
    const intl = useIntl();
    const { required, onChange } = props;
    const [isValid, setValid] = useState<boolean>(false);
    const [errorTexts, setErrorTexts] = useState<string[]>([]);

    const handleChange = useCallback(
      (postalCode: TypedFormFieldValue<string> | undefined) => {
        const validFormat = isPostalCodeValid(postalCode?.value);
        setValid(validFormat);
        setErrorTexts(!validFormat ? [intl.formatMessage({ id: 'form.wrongFormat' })] : []);

        if (onChange) {
          if (!required && (postalCode === undefined || postalCode.value === '')) {
            onChange(undefined);
          } else {
            onChange({ value: postalCode?.value || '', isValid: isPostalCodeValid(postalCode?.value, required) });
          }
        }
      },
      [required, onChange, intl],
    );

    return (
      <TextField
        {...props}
        ref={ref}
        error={props.error || !isValid}
        errorTexts={[...errorTexts, ...props.errorTexts]}
        onChange={handleChange}
        type="text"
      />
    );
  }),
  (oldProps, newProps) => shallowCompare(oldProps, newProps, ['errorTexts']),
);

export default PostalCodeField;
