import {
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAptorApi } from '../../../../../Api';
import { UserStatus } from '../../../../../Context/UserContext/UserContext';
import { useStyles } from './OrganizationUsers.styles';
import Send from '@material-ui/icons/Send';
import { useSnackbar } from 'notistack';

interface IUser {
  id: number;
  name?: string;
  email: string;
  status: UserStatus;
}

interface IProps {
  users: IUser[];
}

export const UpdateCustomerOrganizationUsers: FC<IProps> = ({ users }) => {
  const { api, abortController } = useAptorApi();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const classes = useStyles();

  const resendUserInvite = async (id: number) => {
    await api.resendAptorUserInvite(id);
    if (abortController.current.signal.aborted) {
      return;
    }
    enqueueSnackbar(intl.formatMessage({ id: 'admin.users.table.userInviteSent' }));
  };

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow className={classes.header}>
            <TableCell>
              <Typography variant="overline">
                <FormattedMessage id="admin.customers.organization-users.table.header.name" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="overline">
                <FormattedMessage id="admin.customers.organization-users.table.header.email" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="overline">
                <FormattedMessage id="admin.customers.organization-users.table.header.status" />
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.email} className={classes.row}>
              <TableCell>{user.name}</TableCell>
              <TableCell>
                <Link href={`mailto:${user.email}`}>{user.email}</Link>
              </TableCell>
              <TableCell>
                <FormattedMessage id={`admin.customers.organization-users.table.header.status.${user.status}`} />
              </TableCell>

              <TableCell>
                {user.status === UserStatus.Invited && (
                  <Tooltip title={intl.formatMessage({ id: 'admin.users.table.resendInvite' })}>
                    <IconButton onClick={() => resendUserInvite(user.id)}>
                      <Send />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
