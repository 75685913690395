import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, MTable } from '../../../../../Components';
import { useFormField } from '../../../../../Components/Form/FormField';
import { UserContext } from '../../../../../Context/UserContext/UserContext';
import { entityToSelectOption } from '../../../../../Utilities';
import { useAptorApi } from '../../../../../Api';
import { Query } from 'material-table';
import { AptorApi, IGridQueryRequest, INamedEntity, OrderDirection } from '../../../../../Api/AptorApi';
import { FormValues } from '../../../../../Components/Form/Form.types';
import { useConfirmation } from '../../../../../Context/ConfirmationContext/ConfirmationContext';

interface IAzureTenant {
  id: string;
  name: string;
  companyUnits?: string;
}

interface IAzureTenantForm {
  id: string;
  name: string;
  companyUnits: INamedEntity[];
}

const AzureTenantForm = (props: {
  editForm?: IAzureTenantForm | null;
  cancelEdit?: () => void;
  reloadTable: () => void;
  companyUnits: { value: number; label: string }[];
}) => {
  const { formatMessage } = useIntl();
  const { getCompanyUnitName } = useContext(UserContext);

  const handleTenantSubmit = async (data: FormValues, api: AptorApi, onSuccess: (key: string) => void) => {
    if (props.editForm) {
      await api.updateAzureTenant(props.editForm.id, data);
    } else {
      await api.createAzureTenant(data);
    }

    props.reloadTable();
    onSuccess('organization.azure-ad.successfullyUpdated');
  };

  const tenantName = useFormField({
    label: formatMessage({ id: 'organization.azure-ad.name', defaultMessage: 'Tenant Name' }),
    name: 'name',
    type: 'text',
    required: true,
    initialState: props.editForm ? { value: props.editForm.name } : undefined,
  });

  const tenantId = useFormField({
    label: formatMessage({ id: 'organization.azure-ad.id', defaultMessage: 'Tenant Id' }),
    name: 'id',
    type: props.editForm ? 'readonly' : 'text',
    required: true,
    placeholder: '00000000-0000-0000-0000-000000000000',
    initialState: props.editForm ? { value: props.editForm.id } : undefined,
  });

  const companyUnitsField = useFormField({
    label: formatMessage(
      { id: 'organization.users.connect-company-unit' },
      { alias: getCompanyUnitName(formatMessage, true) },
    ),
    placeholder: formatMessage({ id: 'form.selectCompanyUnits' }),
    name: 'companyUnits',
    type: 'multi-select',
    options: props.companyUnits,
    required: true,
    initialState: props.editForm ? props.editForm.companyUnits.map(entityToSelectOption) : undefined,
  });

  return (
    <Form
      submit={handleTenantSubmit}
      submitLabel={props.editForm ? undefined : formatMessage({ id: 'utils.icon.add' })}
      cancel={props.editForm ? props.cancelEdit : undefined}
      groups={[
        {
          label: props.editForm ? 'organization.azure-ad.edit' : 'organization.azure-ad.add',
          grid: {
            type: 'column',
            items: [
              {
                type: 'row',
                items: [tenantId, tenantName],
              },
              companyUnitsField,
            ],
          },
        },
      ]}
    />
  );
};

export const AzureADSettings = (props: {}) => {
  const tableRef = React.createRef();
  const rev = useRef(1);
  const tableRev = useRef(1);

  const { formatMessage } = useIntl();
  const { deleteConfirmation } = useConfirmation();
  const { getCompanyUnitName } = useContext(UserContext);
  const { api, abortController } = useAptorApi();

  const [editTenant, setEditTenant] = useState<IAzureTenantForm | null>(null);

  const [companyUnits, setCompanyUnits] = useState<{ value: number; label: string }[]>([]);

  const reloadTable = useCallback(() => {
    (tableRef.current as any).onQueryChange();
    setEditTenant(null);
  }, [tableRef]);

  useEffect(() => {
    api.getCustomerCompanyUnits().then((units) => {
      if (abortController.current.signal.aborted) {
        return;
      }

      setCompanyUnits(units.items.map(entityToSelectOption));
    });
  }, [api, abortController]);

  const handleDataFetch = useCallback(
    async (query: Query<IAzureTenant>) => {
      return await api.searchAzureTenants<IAzureTenant>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'name',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
      } as IGridQueryRequest);
    },
    [api],
  );

  const handleEdit = (id: string) => () => {
    api.getAzureTenant<IAzureTenantForm>(id).then((data) => {
      setEditTenant(data);
      rev.current = rev.current + 1;
    });
  };

  const deleteTenant = useCallback(
    async (id: string, reload: () => void) => {
      await api.deleteAzureTenant(id);
      reload();
    },
    [api],
  );

  const handleDelete = useCallback(
    (id: string) => () => {
      const confirmation = {
        title: formatMessage({ id: 'organization.azure-ad.remove-title' }),
        description: formatMessage({ id: 'organization.azure-ad.remove-description' }),
      };
      deleteConfirmation(
        () =>
          deleteTenant(id, () => {
            tableRev.current = tableRev.current + 1;
          }),
        confirmation,
      );
    },
    [deleteConfirmation, formatMessage, tableRev, deleteTenant],
  );

  const editAction = (tenant: IAzureTenant) => {
    return {
      tooltip: formatMessage({ id: 'component.mtable.editTooltip' }),
      icon: 'edit',
      onClick: handleEdit(tenant.id),
    };
  };

  const deleteAction = (tenant: IAzureTenant) => {
    return {
      tooltip: formatMessage({ id: 'component.mtable.deleteTooltip' }),
      icon: 'delete',
      onClick: handleDelete(tenant.id),
    };
  };

  return (
    <>
      {(editTenant && (
        <AzureTenantForm
          key={`edit-${rev.current}`}
          cancelEdit={() => setEditTenant(null)}
          editForm={editTenant}
          companyUnits={companyUnits}
          reloadTable={reloadTable}
        />
      )) || <AzureTenantForm key={`add-${rev.current}`} companyUnits={companyUnits} reloadTable={reloadTable} />}

      <MTable
        key={`table-${tableRev.current}`}
        // hideToolbar
        tableRef={tableRef}
        columns={[
          { title: formatMessage({ id: 'organization.azure-ad.name' }), field: 'name' },
          {
            title: formatMessage({ id: 'organization.azure-ad.id' }),
            field: 'id',
            sorting: false,
          },
          {
            title: getCompanyUnitName(formatMessage),
            field: 'companyUnits',
            sorting: false,
          },
        ]}
        data={handleDataFetch}
        actions={[editAction, deleteAction]}
        components={{}}
      />
    </>
  );
};
