import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { CreateComplianceReport, IReport, IChecklist } from '../../../../Components/Compliance/CreateReport';
import { SelectOption } from '../../../../../../Components/Form/Form.types';
import { ComplianceSessionInformation } from './SessionInformation';
import { AptorApi, useAptorApi } from '../../../../../../Api';
import { entityToSelectOption } from '../../../../../../Utilities';
import { useComplianceSession } from '../../../../../../Context/ComplianceSessionContext';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../../../Context/UserContext/UserContext';
import { Grid } from '@material-ui/core';
import { BackButton } from '../../../../../../Components';
// import async from "react-select/async";

export const CreateLawComplianceReport = () => {
  const match = useRouteMatch<{ id: string, planid: string }>();
  const lawid = parseInt(match.params.id);
  const planid = parseInt(match.params.planid);
  const { api, abortController } = useAptorApi();
  const history = useHistory();
  const { hasAccessToFeature, id } = useContext(UserContext);
  const [loadingChecklist, setLoadingChecklist] = useState<boolean>(true);

  const [loaded, setLoaded] = useState<boolean>(false);
  const [companyUnits, setCompanyUnits] = useState<SelectOption[]>([]);
  const [companyUnitId, setCompanyUnitId] = useState<number>();

  const [checklist, setChecklist] = useState<IChecklist>();
  const { complianceSession, completeLawCompliance } = useComplianceSession();
  const [LawComplianceData, setLawsComplianceData] = useState("");
  const path = window.location.pathname.split("/")
  useEffect(() => {
    const checkEdit = path[path.length - 1]
    if (checkEdit === "edit") {
      if (path?.length && path[5] === "other-requirements") {
        api.getLawPortalRequirementChecklistWithData(planid, lawid).then((chkl: any) => {
          if (!abortController.current.signal.aborted) {
            setLawsComplianceData(chkl)
          }
        });
      }else{
      api.getLawPortalLawChecklistWithData(planid, lawid).then((chkl: any) => {
        if (!abortController.current.signal.aborted) {
          setLawsComplianceData(chkl)
        }
      });
    }
    }
  }, [])

  useEffect(() => {
    if (companyUnitId !== undefined) {
      setLoadingChecklist(true);
      if (path?.length && path[5] === "other-requirements") {
        api.getLawPortalRequirementChecklist<IChecklist>(companyUnitId, lawid).then((chkl) => {
          if (!abortController.current.signal.aborted) {
            setChecklist(chkl);
            setLoadingChecklist(false);
          }
        });
      } else {
        api.getLawPortalLawChecklist<IChecklist>(lawid, companyUnitId).then((chkl) => {
          if (!abortController.current.signal.aborted) {
            setChecklist(chkl);
            setLoadingChecklist(false);
          }
        });
      }
    } else {
      if (path?.length && path[5] === "other-requirements") {
        api.getUserCompanyUnitsForRequirementCompliance(lawid).then((userCompanyUnits) => {
          if (!abortController.current.signal.aborted) {
            setCompanyUnits(userCompanyUnits.items.map(entityToSelectOption));
            setLoaded(true);
          }
        });
      } else {
        api.getUserCompanyUnitsForLawCompliance(lawid).then((userCompanyUnits) => {
          if (!abortController.current.signal.aborted) {
            setCompanyUnits(userCompanyUnits.items.map(entityToSelectOption));
            setLoaded(true);
          }
        });
      }
    }

  }, [api, abortController, companyUnitId, lawid]);

  const submitAction = (unitId: number, reportId: any, a: AptorApi, report: IReport) => {
    const path = window.location.pathname?.split("/")
    const checkEdit = path[path.length - 1]
    if (checkEdit === "edit") {
      if(path?.length && path[5] === "other-requirements" ){
        return a.updateLawPortalRequirementComplianceReport(lawid, unitId, Number(reportId), report)
      }else{
      return a.updateLawPortalLawComplianceReport(lawid, unitId, Number(reportId), report);
      }
    } else {
      if(path?.length && path[5] === "other-requirements" ){
        return a.createLawPortalRequirementComplianceReport(lawid, unitId, report);
      }else{
        return a.createLawPortalLawComplianceReport(lawid, unitId, report);
      }
    }
  }

  const onCreateSuccess = () => {
    if (complianceSession) {
      completeLawCompliance(lawid);
      const nextLaw = complianceSession.next();
      if (nextLaw) {
        history.push(nextLaw.complianceUrl);
      } else {
        history.push('/law-portal/legal-compliance-control/carry');
      }
    } else {
      history.push(`/law-portal/legal-compliance-control/carry`);
    }
  };

  const handleFetchComplianceUsers = useCallback(
    async (companyUnitId: number) => {
      if (path?.length && path[5] === "other-requirements") {
        const result = await api.getParticipantsForRequirementCompliance(lawid, companyUnitId);
        return result.items.map(entityToSelectOption);
      } else {
        const result = await api.getParticipantsForLawCompliance(lawid, companyUnitId);
        return result.items.map(entityToSelectOption);
      }
    },
    [api, id],
  );
  const { firstName, lastName } = useContext(UserContext);
  useEffect(() => {
    const path = window.location.pathname?.split("/")
    const checkEdit = path[path.length - 1]
   if(checkEdit !== "edit") {
      const localcomplianceSession: any = localStorage.getItem(`ComplianceSession_plan_${planid}_userID_${id}`)
      const localcompliancesessiondata = JSON.parse(localcomplianceSession)
      const currentuserName = `${firstName} ${lastName}`
      if (localcompliancesessiondata?.done?.length === 0) {
        if (complianceSession) {
          api.getdirectPlanLaws(planid).then(async (res) => {
            if (res.laws?.length) {
              await res?.laws?.forEach((item: any) => {
                if (item?.isStarted && currentuserName.toLowerCase() === item.responsible.toLowerCase()) {
                  completeLawCompliance(item.id);
                }
                const nextLaw = complianceSession.next();
                if (nextLaw) {
                  history.push(nextLaw.complianceUrl);
                } else {
                  history.push('/law-portal/legal-compliance-control/carry');
                }

              })
            } else if (res.requirements?.length) {
              await res?.requirements?.forEach((item: any) => {
                if (item?.isStarted && currentuserName.toLowerCase() === item.responsible.toLowerCase()) {
                  completeLawCompliance(item.id);
                }
                const nextLaw = complianceSession.next();
                if (nextLaw) {
                  history.push(nextLaw.complianceUrl);
                } else {
                  history.push('/law-portal/legal-compliance-control/carry');
                }
              })
            }
          })
          // const nextLaw = complianceSession.next();
          // if (nextLaw) {
          //   history.push(nextLaw.complianceUrl);
          // } else {
          //   history.push('/law-portal/legal-compliance-control/carry');
          // }
        } else {
          history.push(`/law-portal/legal-compliance-control/carry`);
        }
      }
    }
  }, [])
  return (
    <>
      <Grid item xs={12}>
        <BackButton disableMargin link={`/law-portal/legal-compliance-control/carry`} />
      </Grid>
      <Grid item xs={12}>
        <ComplianceSessionInformation id={lawid} />
        <Grid container direction="column" spacing={2}>
          <Grid item style={{ marginTop: 20 }}>
            <CreateComplianceReport
              key={path?.length && path[5] === "other-requirements" ? `requirement-${lawid}` : `law-${lawid}`}
              lawId={path?.length && path[5] === "other-requirements" ? undefined : lawid}
              requirementId={path?.length && path[5] === "other-requirements" ? lawid : undefined}
              titleKey={path?.length && path[5] === "other-requirements"?"law-portal.requirements.compliance.heading":"law-portal.our-laws.compliance.heading"}
              summaryKey={path?.length && path[5] === "other-requirements" ? "law-portal.requirements.compliance.summary.label":"law-portal.our-laws.compliance.summary"}
              storageKey={`compliance-state-for-plan-${planid}-${path?.length && path[5] === "other-requirements" ? "requirement" : "law"}-${lawid}`}
              loaded={loaded}
              loadingChecklist={loadingChecklist}
              units={companyUnits}
              fetchComplianceUsers={handleFetchComplianceUsers}
              onCompanyUnitChange={setCompanyUnitId}
              checklist={checklist}
              disableHide={!hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage)}
              submitAction={submitAction}
              onCreateSuccess={onCreateSuccess}
              LawComplianceData={LawComplianceData}
              setLoaded={setLoaded}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
