import React, { useContext, useEffect, useState } from 'react'
import { useStyles } from '../../../LawPortal.styles'
import {
  ButtonLink,
  //  Panel,
  Transition
} from '../../../../../Components';
import { useIntl } from 'react-intl';
import { CreatePlannig } from './CreatePlanning';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { ArrowBack, ControlPointDuplicate, Delete, Edit, ExpandMore, PlaylistAdd, SyncDisabledSharp, SyncSharp } from '@material-ui/icons';
import { PlanningView } from './View/PlanningView';
// import async from "react-select/async";
import {
  // AptorApi, 
  useAptorApi
} from "../../../../../Api";
import { PlanFeature, UserContext } from "../../../../../Context/UserContext/UserContext";
import moment from "moment";
import { Title } from "../../../../../Components/Panel/Panel";
import { SearchTextField } from '../../../../../Components/Field';
interface IconProps {
  id: number;
  name: string;
  icon?: React.ReactNode;
  onClick?: (event: any, planData?: any) => void;
}
export const PlanningKEK = () => {
  const classes = useStyles();
  let { formatMessage } = useIntl();
  const [newCreate, setNewCreate] = useState(false);
  const { customerId } = useContext(UserContext)
  const { api, abortController } = useAptorApi();
  const [activePlanList, setActivePlan] = useState([])
  const [inActivePlanList, setInActivePlan] = useState([])
  const [updateLaws, setUpdateLaws] = useState("")
  const [planLoading, setPlanLoading] = useState(false)
  const [editPlanId, setEditPlanID] = useState("")
  const [duplicatePlanData, setDuplicatePlanData] = useState("")
  const [expendinActivePanel, setExpendInActivePanel] = useState("")
  const [expendActivePanel, setExpendActivePanel] = useState("")
  let [dataTable, setTableData] = useState(activePlanList?.length ? activePlanList : []);
  const handleUpdateLaws = (data: any) => {
    setUpdateLaws(data?.planData?.id)
    setEditPlanID("")
    if (data?.planData?.isActive) {
      setExpendActivePanel(data?.planData?.id)
    } else {
      setExpendInActivePanel(data?.planData?.id)
    }
  }
  useEffect(() => {
    getCompliancePlan()
  }, [])
  const getCompliancePlan = async () => {
    setPlanLoading(true);
    await api.getAllcompliancePlan(customerId,{}).then((response: any) => {
      if (!abortController.current.signal.aborted) {
        const compliancePlan = response?.customerPlans?.filter((val: any) => !val.isDirect && val.type === PlanFeature.requirement)
        const activePlan = compliancePlan.filter((val: any) => val.isActive);
        const inActivePlan = compliancePlan.filter((val: any) => !val.isActive);
        setActivePlan(activePlan.length ? activePlan : []);
        setTableData(activePlan.length ? activePlan : []);
        setInActivePlan(inActivePlan.length ? inActivePlan : []);
        setInactiveTable(inActivePlan.length ? inActivePlan : [])
        setPlanLoading(false);
      }
    }).catch(() => {
      setPlanLoading(false);
    })
    // const { customerPlans } = compliancePlan || [];
    // const activePlan = customerPlans.filter((val:any) => val.isActive);
    // const inActivePlan = customerPlans.filter((val:any) => !val.isActive);
    // setActivePlan(activePlan.length ? activePlan : []);
    // setInActivePlan(inActivePlan.length ? inActivePlan : []);

    // setPlanLoading(false);
  };
  const editPlane = (data: any) => {
    setEditPlanID(data?.planData?.id)
    if (data?.planData?.isActive) {
      setExpendActivePanel("")
    } else {
      setExpendInActivePanel("")
    }
  }
  const handleDuplicatePlan = (data: any) => {
    setEditPlanID("")
    setDuplicatePlanData(data?.planData)
    setNewCreate(!newCreate)
  }
  const handleActiveInActive = async (data: any) => {
    await api.planStatus(data?.planData?.id)
    getCompliancePlan()
  }
  const handleDeletePlan = async (data: any) => {
    await api.deletePlan(data?.planData?.id)
    getCompliancePlan()
  }
  const data: IconProps[] = [
    {
      id: 1,
      name: 'law-portal.legal.plan.copy.duplicate',
      icon: <ControlPointDuplicate />,
      onClick: (event: any, planData: any) => {
        event.stopPropagation();
        handleDuplicatePlan(planData)
      },
    },
    {
      id: 2,
      name: 'law-portal.legal.plan.add.legislation',
      icon: <PlaylistAdd />,
      onClick: (event: any, planData: any) => {
        event.stopPropagation();
        handleUpdateLaws(planData)
      },
    },
    {
      id: 3,
      name: 'law-portal.legal.plan.edit',
      icon: <Edit />,
      onClick: (event: any, planData: any) => {
        event.stopPropagation();
        editPlane(planData)
      },
    },
    {
      id: 4,
      name: 'law-portal.legal.plan.delete',
      icon: <Delete />,
      onClick: (event: any, planData: any) => {
        event.stopPropagation();
        handleDeletePlan(planData)
      },
    },
    {
      id: 5,
      name: 'law-portal.legal.plan.deactivate',
      icon: <SyncDisabledSharp />,
      onClick: (event: any, planData: any) => {
        event.stopPropagation();
        handleActiveInActive(planData)
      },
    },
    {
      id: 6,
      name: 'law-portal.legal.plan.publish',
      icon: <SyncSharp />,
      onClick: (event: any, planData: any) => {
        event.stopPropagation();
        handleActiveInActive(planData)
      },
    },
  ];
  const IconsNode = (planData: any) => {
    const actionIcon = data.filter((val: any) => {
      return planData?.planData?.isActive
      ? val.name !== 'law-portal.legal.plan.publish'
      : val.name !== 'law-portal.legal.plan.deactivate';
    });
    return (
      <Grid>
        {actionIcon?.map((item, i) => (
          <Tooltip title={formatMessage({ id: item.name })}>
            <IconButton style={{ cursor: "pointer" }} onClick={(event) => item.onClick && item.onClick(event, planData)} key={item.id}>
              {item.icon}
            </IconButton>
          </Tooltip>
        ))}
      </Grid>
    );
  }
  const newPlanCreate = () => {
    setNewCreate(!newCreate)
    setDuplicatePlanData("")
  }

  const handlePanelToggle = (panelId: string) => {
    // Toggle the expansion state of the panel with the given panelId.
    if (expendinActivePanel === panelId) {
      setExpendInActivePanel("");
      setUpdateLaws("")
    } else {
      setExpendInActivePanel(panelId);
    }
  };
  const handleActivePanelToggle = (panelId: string) => {
    // Toggle the expansion state of the panel with the given panelId.
    if (expendActivePanel === panelId) {
      setExpendActivePanel("");
      setUpdateLaws("")
    } else {
      setExpendActivePanel(panelId);
    }
  };
  const [searchData, setSearchData] = useState('');
  let searchHandle = (str: string) => {
    setSearchData(str);
    if (str) {
      let myData = activePlanList?.filter((item: any) => {
        let findEndDate = item?.endDate.toLowerCase().includes(str.toLowerCase());
        let findStartDate = item?.startDate.toLowerCase().includes(str.toLowerCase());
        let planName = item?.name.toLowerCase().includes(str.toLowerCase());
        return findStartDate || findEndDate || planName;
      });
      setTableData(myData);
    } else {
      setTableData(activePlanList?.length ? activePlanList : []);
    }
  };
  const [SearchInactiveData, setSearchInactiveData] = useState('');
  const [InactiveTable, setInactiveTable] = useState(inActivePlanList?.length ? inActivePlanList : [])
  const searchHandleIncative = (str: string) => {
    setSearchInactiveData(str);
    if (str) {
      let myData = inActivePlanList?.filter((item: any) => {
        let findEndDate = item?.endDate.toLowerCase().includes(str.toLowerCase());
        let findStartDate = item?.startDate.toLowerCase().includes(str.toLowerCase());
        let planName = item?.name.toLowerCase().includes(str.toLowerCase());
        return findStartDate || findEndDate || planName;
      });
      setInactiveTable(myData);
    } else {
      setInactiveTable(inActivePlanList?.length ? inActivePlanList : []);
    }
  }
  return (
    <div className={classes.listWrapper}>
      {newCreate ?
        <ButtonLink text={formatMessage({ id: "component.back-button.back" })} onclick={newPlanCreate} to={''}
          icon={<ArrowBack />} /> :
        <ButtonLink text={formatMessage({ id: "law-portal.legal.complaince.create.new.planning" })} onclick={newPlanCreate} to={''} />
      }
      {newCreate ? <Grid item style={{ marginTop: 20 }}><CreatePlannig setNewCreate={setNewCreate} getCompliancePlan={getCompliancePlan} isEdit={false} planData={duplicatePlanData} setDuplicatePlanData={setDuplicatePlanData} isDuplicate={duplicatePlanData ? true : false} /></Grid>
        : <>
          <Transition isLoading={planLoading}>
            <Grid container spacing={4} direction="column" justify="space-evenly">
              {/* <Grid >
                <Typography variant="h5" style={{ marginTop: 30, marginLeft: 20, color: 'rgba(0, 0, 0, 0.87)', fontWeight: "inherit" }}>{formatMessage({ id: "law-portal.legal.planned.law.compliance.kek.check" })}</Typography>
              </Grid> */}
              {activePlanList?.length > 0 && (
                <Grid container justifyContent="space-between" alignItems="center" style={{ marginRight: 20 }}>
                  <Grid>
                    <Typography
                      variant="h5"
                      style={{ marginTop: 30, marginLeft: 20, color: 'rgba(0, 0, 0, 0.87)', fontWeight: 'inherit' }}
                    >
                      {formatMessage({ id: 'law-portal.legal.planned.law.compliance.kek.check' })}
                    </Typography>
                  </Grid>
                  <Grid>
                    <SearchTextField
                      onChange={searchHandle}
                      value={searchData}
                      clearSearch={() => {
                        setSearchData('');
                        setTableData(activePlanList?.length ? activePlanList : []);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid style={{ display: "flex", flexDirection: "column", gap: '15px' }} item>
                {dataTable?.map((val: any, index: number) => {
                  return (
                    <>
                      <ExpansionPanel defaultExpanded={false} expanded={expendActivePanel === val.id} onChange={() => handleActivePanelToggle(val.id)} >
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Grid container justify="space-between" alignItems='center'>
                            <Title titleKey={formatMessage({ id: "default", defaultMessage: `${moment(val.startDate).format("YYYY-MM-DD")} -- ${moment(val.endDate).format("YYYY-MM-DD")} ${val.name}` })} />
                            <Grid>
                              <IconsNode planData={val} />
                            </Grid>
                          </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.panelDetails}>
                          {expendActivePanel === val.id && <PlanningView updateLaws={updateLaws} planLawsData={val} setUpdateLaws={(val: any) => setUpdateLaws(val)} />}
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      {editPlanId === val.id ? <Grid item style={{ marginTop: 20 }}><CreatePlannig setNewCreate={setNewCreate} getCompliancePlan={getCompliancePlan} isEdit={true} planData={val} setEditPlanID={setEditPlanID} /></Grid> : ""}
                    </>
                  )
                })}
              </Grid>
              {/* <Grid >
                <Typography variant="h5" style={{ marginTop: 30, marginLeft: 20, color: '#000', fontWeight: "inherit" }}>{formatMessage({ id: "law-portal.legal.inactive.law.compliance.kek.check" })}</Typography>
              </Grid> */}
              {inActivePlanList?.length > 0 && (
                <Grid container justifyContent="space-between" alignItems="center" style={{ marginRight: 20 }}>
                  <Grid>
                    <Typography
                      variant="h5"
                      style={{ marginTop: 30, marginLeft: 20, color: '#000', fontWeight: 'inherit' }}
                    >
                      {formatMessage({ id: 'law-portal.legal.inactive.law.compliance.kek.check' })}
                    </Typography>
                  </Grid>
                  <Grid>
                    <SearchTextField
                      onChange={searchHandleIncative}
                      value={SearchInactiveData}
                      clearSearch={() => {
                        setSearchInactiveData('');
                        setInactiveTable(inActivePlanList?.length ? inActivePlanList : []);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid style={{ display: "flex", flexDirection: "column", gap: '15px' }} item>
                {InactiveTable?.map((val: any, index: number) => {
                  return (
                    <>
                      <ExpansionPanel defaultExpanded={false} expanded={expendinActivePanel === val.id} onChange={() => handlePanelToggle(val.id)} >
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Grid container justify='space-between' alignItems='center'>
                            <Title titleKey={formatMessage({ id: "default", defaultMessage: `${moment(val.startDate).format("YYYY-MM-DD")} -- ${moment(val.endDate).format("YYYY-MM-DD")} ${val.name}` })} />
                            <Grid>
                              <IconsNode planData={val} />
                            </Grid>
                          </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.panelDetails}>
                          {expendinActivePanel === val.id && <PlanningView updateLaws={updateLaws} planLawsData={val} setUpdateLaws={(val: any) => setUpdateLaws(val)} />}
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      {editPlanId === val.id ? <Grid item style={{ marginTop: 20 }}><CreatePlannig setNewCreate={setNewCreate} getCompliancePlan={getCompliancePlan} isEdit={true} planData={val} setEditPlanID={setEditPlanID} /></Grid> : ""}
                    </>
                  )
                })}
              </Grid>
            </Grid>
          </Transition>
        </>
      }
    </div>
  )
}