import React, { useContext, useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { LawTable } from './List';
import { CreateLaw } from './Create';
import { LawSettings } from './Settings';
import { EditLaw } from './Edit';
import { EditLawChecklist } from './Checklist';
import { SupportInvitesTable } from './SupportInvites/SupportInvitesTable';
import { SendoutsTable } from './Sendouts/SendoutsTable';
import { UserContext, AdminFeature, AccessLevel } from '../../../../Context/UserContext/UserContext';
import { ModuleContext } from '../../../../Context/ModuleContext/ModuleContext';

export const LawPortal = () => {
  const { hasAccessToFeature } = useContext(UserContext);
  const { formatMessage } = useIntl();
  const { setHeader } = useContext(ModuleContext);
  useEffect(() => {
    setHeader(formatMessage({ id: 'admin.law-portal.header', defaultMessage: 'Lawportal' }), [
      {
        title: formatMessage({ id: 'admin.law-portal.subMenu.laws', defaultMessage: 'Laws' }),
        to: '/admin/law-portal/laws',
      },
      {
        title: formatMessage({ id: 'admin.law-portal.subMenu.sendouts', defaultMessage: 'Sendouts' }),
        to: '/admin/law-portal/sendouts',
      },
      {
        title: formatMessage({ id: 'admin.law-portal.subMenu.settings', defaultMessage: 'Settings' }),
        to: '/admin/law-portal/settings',
      },
      {
        title: formatMessage({ id: 'admin.law-portal.subMenu.supportInvites', defaultMessage: 'Support invites' }),
        to: '/admin/law-portal/support-invites',
      },
    ]);
  }, [setHeader, formatMessage]);
  return (
    <Switch>
      <Route exact path="/admin/law-portal/laws">
        <LawTable />
      </Route>
      {hasAccessToFeature(AdminFeature.Administrate, AccessLevel.Manage) && (
        <Route exact path="/admin/law-portal/laws/new">
          <CreateLaw />
        </Route>
      )}
      {hasAccessToFeature(AdminFeature.Administrate, AccessLevel.Manage) && (
        <Route path="/admin/law-portal/laws/:id(\d+)/checklist">
          <EditLawChecklist />
        </Route>
      )}
      {hasAccessToFeature(AdminFeature.Administrate, AccessLevel.Manage) && (
        <Route path="/admin/law-portal/laws/:id(\d+)">
          <EditLaw />
        </Route>
      )}
      <Route path="/admin/law-portal/settings">
        <LawSettings />
      </Route>
      <Route path="/admin/law-portal/support-invites">
        <SupportInvitesTable />
      </Route>
      <Route path="/admin/law-portal/sendouts">
        <SendoutsTable />
      </Route>
      <Route path="/admin/law-portal">
        <Redirect to="/admin/law-portal/laws" />
      </Route>
    </Switch>
  );
};
