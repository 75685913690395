import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Column, Query } from 'material-table';
import { PersonAdd } from '@material-ui/icons';
import { Grid, TablePagination, Typography } from '@material-ui/core';
import { MTable } from '../../../../../Components';
import { ButtonLink } from '../../../../../Components/ButtonLink/ButtonLink';
import { OrderDirection, IGridQueryRequest } from '../../../../../Api/AptorApi';
import { useAptorApi } from '../../../../../Api';
import {
  AccessLevel,
  OrganizationFeature,
  UserContext,
  UserStatus,
} from '../../../../../Context/UserContext/UserContext';
import { useSnackbar } from 'notistack';
import { useStyles } from '../Organization.styles';
import { useConfirmation } from '../../../../../Context/ConfirmationContext/ConfirmationContext';

interface ICompanyUser {
  id: number;
  name: string;
  email: string;
  telephone?: string;
  companyUnits?: string;
  status: UserStatus;
  numberOfCompanyUnits: number;
  organizationAccessLevel: number;
}
interface IProps {
  companyUnitId?: number;
}

interface IUserBalance {
  totalAmount: number;
  currentAmount: number;
}

export const CompanyUsers: FC<IProps> = ({ companyUnitId }) => {
  const { api, abortController } = useAptorApi();
  const history = useHistory();
  const tableRef = React.createRef();
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { getCompanyUnitName, hasAccessToFeature } = useContext(UserContext);
  const canManage = hasAccessToFeature(OrganizationFeature.Manage, AccessLevel.Manage);
  const classes = useStyles();

  const handleDataFetch = useCallback(
    async (query: Query<ICompanyUser>) => {
      return await api.searchCustomerUsers<ICompanyUser>(companyUnitId, {
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'name',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
      } as IGridQueryRequest);
    },
    [api, companyUnitId],
  );

  const [balance, setBalance] = useState<{ totalAmount: number; currentAmount: number }>();

  const fetchUserBalance = useCallback(async () => {
    var balance = await api.getUserBalance<IUserBalance>();
    if (abortController.current.signal.aborted) {
      return;
    }
    setBalance(balance);
  }, [api, abortController]);

  useEffect(() => {
    fetchUserBalance();
  }, [fetchUserBalance]);

  const { deleteConfirmation } = useConfirmation();

  const disableAccess = (user: ICompanyUser) => {
    return (
      companyUnitId !== undefined &&
      (user.numberOfCompanyUnits > 1 || user.organizationAccessLevel > AccessLevel.Manage)
    );
  };

  const edit = (user: ICompanyUser) => {
    const editLink =
      companyUnitId === undefined
        ? `/organization/users/${user.id}`
        : `/organization/company-units/${companyUnitId}/users/${user.id}`;

    return {
      tooltip: formatMessage({ id: 'component.mtable.editTooltip' }),
      icon: 'edit',
      onClick: () => history.push(editLink),
      disabled: disableAccess(user),
    };
  };

  const activate = (user: ICompanyUser) => ({
    tooltip: formatMessage({ id: 'component.mtable.activateTooltip' }),
    icon: 'check',
    onClick: async () => {
      await api.toggleCustomerUserActivation(user.id, true);
      if (abortController.current.signal.aborted) {
        return;
      }
      (tableRef.current as any).onQueryChange();
    },
    disabled: disableAccess(user),
  });

  const inactivate = (user: ICompanyUser) => ({
    tooltip: formatMessage({ id: 'component.mtable.inactivateTooltip' }),
    icon: 'block',
    onClick: async () => {
      await api.toggleCustomerUserActivation(user.id, false);
      if (abortController.current.signal.aborted) {
        return;
      }
      (tableRef.current as any).onQueryChange();
    },
    disabled: disableAccess(user),
  });

  const resendInvitation = (user: ICompanyUser) => ({
    tooltip: formatMessage({ id: 'organization.users.table.action.resendInvite' }),
    icon: 'send',
    onClick: async () => {
      await api.resendCustomerUserInvite(user.id);
      if (abortController.current.signal.aborted) {
        return;
      }
      enqueueSnackbar(formatMessage({ id: 'organization.users.table.action.userInviteSent.message' }));
    },
    disabled: disableAccess(user),
  });

  const activation = (user: ICompanyUser) => {
    if (user.status === UserStatus.Active) {
      return inactivate(user);
    } else if (user.status === UserStatus.Inactive) {
      return activate(user);
    } else if (user.status === UserStatus.Invited) {
      return resendInvitation(user);
    } else {
      throw Error(`Encounterd unkown user status: '${user.status}'`);
    }
  };

  const deleteUser = (user: ICompanyUser) => ({
    tooltip: formatMessage({ id: 'component.mtable.deleteTooltip' }),
    icon: 'delete',
    onClick: async () => {
      const currentTableRef = tableRef.current as any;

      deleteConfirmation(
        async () => {
          await api.deleteCustomerUser(user.id);
          if (abortController.current.signal.aborted) {
            return;
          }
          currentTableRef.onQueryChange();
        },
        {
          description: formatMessage({ id: 'organization.users.table.action.deleteUser.confirmation' }),
        },
      );
    },
    disabled: disableAccess(user),
  });

  const newLink =
    companyUnitId === undefined ? '/organization/users/new' : `/organization/company-units/${companyUnitId}/users/new`;

  const tableColumns: Column<ICompanyUser>[] = [
    { title: formatMessage({ id: 'organization.users.table.name' }), field: 'name' },
    { title: formatMessage({ id: 'organization.users.table.email' }), field: 'email' },
    { title: formatMessage({ id: 'organization.users.table.telephone' }), field: 'telephone' },
  ];

  if (companyUnitId === undefined)
    tableColumns.push({
      title: getCompanyUnitName(formatMessage),
      field: 'companyUnits',
      sorting: false,
    });

  return (
    <>
      {canManage && (
        <Grid container direction="column" alignContent="flex-end">
          <ButtonLink
            className={classes.addButton}
            to={newLink}
            disabled={balance && balance.currentAmount >= balance.totalAmount}
            text={formatMessage({ id: 'organization.users.add-user', defaultMessage: 'Add User' })}
            icon={<PersonAdd />}
          />

          {balance && balance.currentAmount >= balance.totalAmount && (
            <>
              <Typography variant="caption" color="error" component="p">
                {formatMessage({ id: 'organization.users.notAvailable' })}
              </Typography>
              <Typography variant="caption" color="error" component="p">
                {formatMessage({ id: 'organization.users.contactSupport' })}
              </Typography>
              <br />
            </>
          )}
        </Grid>
      )}
      <MTable
        // hideToolbar
        tableRef={tableRef}
        columns={tableColumns}
        data={handleDataFetch}
        actions={canManage ? [edit, activation, deleteUser] : undefined}
        components={{
          Pagination: (props: any) => (
            <Grid component="td" container className={classes.companyUnitFooterContainer}>
              <Grid item xs={6} className={classes.companyUnitFooterLabel}>
                {balance && (
                  <Typography variant="overline">
                    {formatMessage({ id: 'organization.users.createdUsers' })}: {balance.currentAmount}/
                    {balance.totalAmount}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} className={classes.companyUnitFooterPagination}>
                <TablePagination component="div" {...props} />
              </Grid>
            </Grid>
          ),
        }}
      />
    </>
  );
};
