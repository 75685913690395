import React, { FC, memo } from 'react';
import { Typography, Grid, Icon, Card } from '@material-ui/core';
import { useStyles } from './SelectCard.styles';

interface IProps {
  label: string;
  icon?: React.ReactNode;
}

export const SelectCard: FC<IProps> = memo(({ label, icon, children }) => {
  const classes = useStyles();
  return (
    <Card variant="outlined" className={classes.root}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item sm={6} xs={12}>
          <Grid container spacing={2}>
            {icon && (
              <Grid item xs={2}>
                <Icon className={classes.icon}>{icon}</Icon>
              </Grid>
            )}
            <Grid item xs>
              <Typography>{label}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6} xs={12}>
          {children}
        </Grid>
      </Grid>
    </Card>
  );
});
