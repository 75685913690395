import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Radio, FormControlLabel } from '@material-ui/core';
import { compliance } from '../CreateReport';
import { useStyles } from './Checkbox.styles';

interface IProps {
  type: compliance;
  value: compliance | undefined;
  hidden?: boolean;
}

export const ComplianceReportCheckbox: FC<IProps> = ({ type, value, hidden }) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      value="top"
      control={
        <Radio
          disabled={hidden}
          className={classes[type]}
          classes={{ checked: classes[type] }}
          color="default"
          checked={value === type}
          value={type}
        />
      }
      label={<FormattedMessage id={`law-portal.compliance.question.compliance.${type}.label`} />}
      labelPlacement="top"
    />
  );
};
