import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { AptorUserTable } from './AptorUserTable';
import { CreateAptorUserForm } from './CreateAptorUserForm';
import { UpdateAptorUserForm } from './UpdateAptorUserForm';
import { Switch, Route, Redirect } from 'react-router-dom';
import { UserContext, AdminFeature, AccessLevel } from '../../../../Context/UserContext/UserContext';
import { ModuleContext } from '../../../../Context/ModuleContext/ModuleContext';

export const AptorUsers = () => {
  const { hasAccessToFeature } = useContext(UserContext);
  const { formatMessage } = useIntl();
  const { setHeader } = useContext(ModuleContext);
  useEffect(() => {
    setHeader(formatMessage({ id: 'admin.users.header', defaultMessage: 'Users' }));
  });
  return (
    <Switch>
      <Route exact path="/admin/users" component={AptorUserTable} />
      {hasAccessToFeature(AdminFeature.Administrate, AccessLevel.Manage) && (
        <Route exact path="/admin/users/create" component={CreateAptorUserForm} />
      )}
      {hasAccessToFeature(AdminFeature.Administrate, AccessLevel.Manage) && (
        <Route exact path="/admin/users/:id(\d+)" component={UpdateAptorUserForm} />
      )}
      <Route path="/admin/users">
        <Redirect to="/admin/users" />
      </Route>
    </Switch>
  );
};
