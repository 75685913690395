import React, { FC, useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { ListFilter, createDateFilter, createSelectFilter } from '../../../../Components/ListFilter';
import { SelectOption } from '../../../../Components/Form/Form.types';
import { UserContext } from '../../../../Context/UserContext/UserContext';
import { useAptorApi } from '../../../../Api';
import { entityToSelectlebelOption, mapEntityListToOptions, OptionsEntity } from '../../../../Utilities';
import { updateFilterOptionsDependentOnCompanyUnit } from '../../../../Components/ListFilter/filterUtils';

interface IOptions extends OptionsEntity {
  areas: SelectOption[];
  categories: SelectOption[];
  companyUnits: SelectOption[];
  complianceUsers: SelectOption[];
}

interface IProps {
  filters: any;
  setFilter: (field: string, values: string[] | { min?: Date; max?: Date } | undefined) => void;
}

export const ChangeFilter: FC<IProps> = ({ filters, setFilter }) => {
  const { formatMessage } = useIntl();
  const { api, abortController } = useAptorApi();
  const { getCompanyUnitName } = useContext(UserContext);
  const [allOptions, setAllOptions] = useState<IOptions | null>(null);
  const [options, setOptions] = useState<IOptions | null>(null);
  const [userCompanyUnits, setUserCompanyUnits] = useState<{ [userId: number]: string[] }>({});

  useEffect(() => {
    setOptions(allOptions);
  }, [allOptions]);

  const onSetFilter = (field: string, values: string[] | { min?: Date; max?: Date } | undefined) => {
    setFilter(field, values);
    updateFilterOptionsDependentOnCompanyUnit(
      field,
      values,
      setOptions,
      allOptions,
      filters,
      setFilter,
      userCompanyUnits,
    );
  };

  useEffect(() => {
    api.getStatisticsFilters().then((response) => {
      if (abortController.current.signal.aborted) {
        return;
      }

      const users: { [userId: number]: string[] } = {};
      response.complianceUsers.forEach((companyUnit) => {
        companyUnit.entities?.forEach((user) => {
          if (users[user.id]) {
            users[user.id].push(companyUnit.name);
          } else {
            users[user.id] = [companyUnit.name];
          }
        });
      });

      setUserCompanyUnits(users);

      const mappedResponse = {
        ...response,
        complianceUsers: response.complianceUsers
          .flatMap((companyUnit) => companyUnit.entities)
          .filter((user, index, array) => array.findIndex((x) => x.id === user.id) === index), // Make a flat list of unique users
      };

      setAllOptions(mapEntityListToOptions(mappedResponse) as IOptions);
    });
  }, [api, abortController]);

  // const areaFilter = createSelectFilter('area', formatMessage({ id: 'utils.filters.area' }), options?.areas);
  const areaFilter = createSelectFilter(
    'area',
    formatMessage({ id: 'utils.filters.area' }),
    options?.areas?.map(entityToSelectlebelOption),
    (option) => option.value.toString(),
    undefined,
    filters.area,
  );
  // const categoryFilter = createSelectFilter(
  //   'category',
  //   formatMessage({ id: 'utils.filters.category' }),
  //   options?.categories,
  // );
  const categoryFilter = createSelectFilter(
    'category',
    formatMessage({ id: 'utils.filters.category' }),
    options?.categories?.map(entityToSelectlebelOption),
    (option) => option.value.toString(),
    undefined,
    filters.category,
  );

  // const companyUnitFilter = createSelectFilter('companyUnit', getCompanyUnitName(formatMessage), options?.companyUnits);
  const companyUnitFilter = createSelectFilter(
    'companyUnit',
    getCompanyUnitName(formatMessage),
    options?.companyUnits?.map(entityToSelectlebelOption),
    (option) => option.value.toString(),
    undefined,
    filters.companyUnit,
  );
  const complianceUserFilter = createSelectFilter(
    'complianceUsers',
    formatMessage({ id: 'utils.filters.complianceUsers' }),
    options?.complianceUsers,
    (option) => option.value.toString(),
    undefined,
    filters.complianceUsers ? (filters.complianceUsers[0] as string) ?? null : null,
  );

  const changeFilter = createDateFilter(
    'effectiveFrom',
    formatMessage({ id: 'utils.filters.effectiveFrom.from' }),
    formatMessage({ id: 'utils.filters.effectiveFrom.to' }),
  );

  return (
    <ListFilter
      filters={[companyUnitFilter, complianceUserFilter,areaFilter, categoryFilter, changeFilter]}
      setFilter={onSetFilter}
    />
  );
};

export const RequirementComplianceFilter: FC<IProps> = ({ filters, setFilter }) => {
  const { formatMessage } = useIntl();
  const { api, abortController } = useAptorApi();
  const { getCompanyUnitName } = useContext(UserContext);
  const [allOptions, setAllOptions] = useState<IOptions | null>(null);
  const [options, setOptions] = useState<IOptions | null>(null);
  const [userCompanyUnits, setUserCompanyUnits] = useState<{ [userId: number]: string[] }>({});

  useEffect(() => {
    setOptions(allOptions);
  }, [allOptions]);

  const onSetFilter = (field: string, values: string[] | { min?: Date; max?: Date } | undefined) => {
    setFilter(field, values);
    updateFilterOptionsDependentOnCompanyUnit(
      field,
      values,
      setOptions,
      allOptions,
      filters,
      setFilter,
      userCompanyUnits,
    );
  };

  useEffect(() => {
    api.getStatisticsFilters(true).then((response) => {
      if (abortController.current.signal.aborted) {
        return;
      }

      const users: { [userId: number]: string[] } = {};
      response.complianceUsers.forEach((companyUnit) => {
        companyUnit.entities?.forEach((user) => {
          if (users[user.id]) {
            users[user.id].push(companyUnit.name);
          } else {
            users[user.id] = [companyUnit.name];
          }
        });
      });

      setUserCompanyUnits(users);

      const mappedResponse = {
        ...response,
        complianceUsers: response.complianceUsers
          .flatMap((companyUnit) => companyUnit.entities)
          .filter((user, index, array) => array.findIndex((x) => x.id === user.id) === index), // Make a flat list of unique users
      };

      setAllOptions(mapEntityListToOptions(mappedResponse) as IOptions);
    });
  }, [api, abortController]);

  const areaFilter = createSelectFilter('area', formatMessage({ id: 'utils.filters.area' }), options?.areas);

  const companyUnitFilter = createSelectFilter('companyUnit', getCompanyUnitName(formatMessage), options?.companyUnits);

  const complianceUserFilter = createSelectFilter(
    'complianceUsers',
    formatMessage({ id: 'utils.filters.complianceUsers' }),
    options?.complianceUsers,
    (option) => option.value.toString(),
    undefined,
    filters.complianceUsers ? (filters.complianceUsers[0] as string) ?? null : null,
  );

  const changeFilter = createDateFilter(
    'effectiveFrom',
    formatMessage({ id: 'utils.filters.effectiveFrom.from' }),
    formatMessage({ id: 'utils.filters.effectiveFrom.to' }),
  );

  return (
    <ListFilter filters={[areaFilter, companyUnitFilter, complianceUserFilter, changeFilter]} setFilter={onSetFilter} />
  );
};
