import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  list: {
    '& > .MuiCard-root + .MuiCard-root': {
      marginTop: theme.spacing(2),
    },
    '& .MuiCard-root + .MuiListItem-container': {
      border: 'none',
    },
    marginBottom: theme.spacing(2),
  },
  outsideInterval: {
    '& button': {
      color: theme.palette.grey[400],
    },
  },
  warningText: {
    color: theme.palette.warning.dark,
  },
  formstyle:{
    justifyContent: 'center',
    alignItems: 'center'
  },
}));
