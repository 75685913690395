import { Grid } from '@material-ui/core';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useIsMounted } from '../../Hooks/useIsMounted';
import { DateField } from '../Field';

type FilterValueType = string[] | { min?: Date; max?: Date } | undefined;
type OnSetFilter = (field: string, values: FilterValueType) => void;

interface IDateFilterProps {
  filter: IDateFilter;
  setFilter: OnSetFilter;
}

export interface IDateFilter {
  type: 'date';
  name: string;
  minPlaceholder: string;
  maxPlaceholder: string;
}

export const DateFilter: FC<IDateFilterProps> = ({ filter, setFilter }) => {
  const isMounted = useIsMounted();
  const [min, setMin] = useState<Date>();
  const [max, setMax] = useState<Date>();

  useEffect(() => {
    const minDate = min
      ? moment({
          year: moment(min).year(),
          month: moment(min).month(),
          day: moment(min).date(),
        })
      : undefined;

    const maxDate = max
      ? moment({
          year: moment(max).year(),
          month: moment(max).month(),
          day: moment(max).date(),
        })
      : undefined;

    if (isMounted) {
      setFilter(filter.name, minDate || maxDate ? { min: minDate?.toDate(), max: maxDate?.toDate() } : undefined);
    }
  }, [min, max, filter.name, isMounted]);
  return (
    <>
      <Grid item md={3} sm={6} xs={12}>
        <DateField
          placeholder={filter.minPlaceholder}
          errorTexts={[]}
          name="min"
          onChange={(date) => setMin(date?.value)}
        ></DateField>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <DateField
          placeholder={filter.maxPlaceholder}
          errorTexts={[]}
          name="max"
          onChange={(date) => setMax(date?.value)}
        ></DateField>
      </Grid>
    </>
  );
};
