import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxHeight: '25rem',
  },
  header: {
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: theme.palette.common.white,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[50],
    },
  },
  cell: {
    borderBottom: 'none',
  },
}));
