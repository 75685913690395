import { INamedEntity } from "../../../../../Api/AptorApi";
import { SelectOption } from "../../../../../Components/Form/Form.types";
import { selectOptionToEntity } from "../../../../../Utilities";
import { entityToSelectOption } from '../../../../../Utilities';
import { useIntl } from "react-intl";
import { useCallback, useReducer } from "react";

export type ConnectionType = 'process' | 'aspect' | 'complianceUser' | 'tag';


interface IConnectionOptions {
    aspect: SelectOption[];
    process: SelectOption[];
    complianceUser: SelectOption[];
    tag: SelectOption[];
  }
interface IUpdatedLaw { 
    id: number, 
    name: string, 
    number: string 
};

interface IType{
    key: ConnectionType;
    name: string;
}

export interface IState {
    companyUnit: number;
    _laws: ILawConnections[];
    _connections: IConnectionOptions;

    type?: IType;
    connection?: INamedEntity;

    typeOptions: SelectOption[];
    connectionOptions: SelectOption[];

    connectionAddedToLaws: IUpdatedLaw[];
    connectionRemovedFromLaws: IUpdatedLaw[];
}

export interface ILawConnectionsModel {
    aspects: INamedEntity[];
    processes: INamedEntity[];
    tags: INamedEntity[];
    complianceUsers: INamedEntity[];
    laws: ILawConnections[];
}

interface ILawConnections {
    id: number;
    aspect: number[];
    process: number[];
    complianceUser: number[];
    tag: number[];
}

type actions = 
    { type: 'setType'; payload: SelectOption } |
    { type: 'loadConnections', payload: ILawConnectionsModel } | 
    { type: 'setConnection', payload?: SelectOption } |
    { type: 'addConnection', payload: { law: IUpdatedLaw }} |
    { type: 'removeConnection', payload: { law: IUpdatedLaw }} |
    { type: 'resetState', payload: ILawConnectionsModel };

const reducer = (state: IState, action: actions): IState => {
    switch (action.type) {     
        case 'loadConnections': return {
            ...state,
            ...{
                _laws: action.payload.laws,
                _connections:{
                    aspect: action.payload.aspects.map(entityToSelectOption),
                    process: action.payload.processes.map(entityToSelectOption),
                    tag: action.payload.tags.map(entityToSelectOption),
                    complianceUser: action.payload.complianceUsers.map(entityToSelectOption),
                }
            }
        }
        case 'resetState': return {
            ...state,
            ...{
                _laws: action.payload.laws,
                _connections:{
                    aspect: action.payload.aspects.map(entityToSelectOption),
                    process: action.payload.processes.map(entityToSelectOption),
                    tag: action.payload.tags.map(entityToSelectOption),
                    complianceUser: action.payload.complianceUsers.map(entityToSelectOption),
                },                
                type: undefined,
                connection: undefined,
                connectionOptions: [],
                connectionAddedToLaws: [],
                connectionRemovedFromLaws: [],                
            }
        }
        case 'setType': {
            const key = action.payload?.value as ConnectionType;
            const type = { key, name:action.payload?.label }
            return {
              ...state,
              ...{
                type: type,
                connectionOptions: state._connections[key],
                connection: undefined,
              },
            };
          }
        case 'setConnection': {
            const connection = action.payload ? selectOptionToEntity<number>(action.payload): undefined;
            return {
                ...state,
                ...{
                    connection: connection,
                    connectionAddedToLaws: [],
                    connectionRemovedFromLaws: [],
                }
            }
        }
        case 'addConnection': {
            if(state.type){
                const law = action.payload.law;
                const exists = state._laws.find(l => l.id ===law.id)![state.type.key].some(connection => connection === state.connection?.id)
                return {
                    ...state,
                    ...{
                        connectionAddedToLaws: exists 
                            ? state.connectionAddedToLaws
                            : [...state.connectionAddedToLaws.filter(l => l.id !== law.id), law],
                        connectionRemovedFromLaws: state.connectionRemovedFromLaws.filter(l => l.id !== law.id)
                    }
                }
            }
            else return state;
        }
        case 'removeConnection':{
            if(state.type) {
                const law = action.payload.law;
                const exists = state._laws.find(l => l.id ===law.id)![state.type.key].some(connection => connection === state.connection?.id)
                return {
                    ...state,
                    ...{
                        connectionAddedToLaws: state.connectionAddedToLaws.filter(law => law.id !== action.payload.law.id),
                        connectionRemovedFromLaws: exists
                            ? [...state.connectionRemovedFromLaws.filter(l => l.id !== law.id), law]
                            : state.connectionRemovedFromLaws
                    }
                }
            }
            else return state;
        }     
        default:
      throw new Error();
  }
};

export interface ILawConnectionsHook{
    state: IState,
    loadLawConnections: (connections: ILawConnectionsModel) => void;
    setType: (selected: SelectOption) => void;
    setConnection: (selected?: SelectOption) => void;
    addLawConnection: (law: IUpdatedLaw) => void;
    removeLawConnection: (law: IUpdatedLaw) => void;
    lawHasConnection: (lawId:number) => boolean;
    resetState: (connections: ILawConnectionsModel) => void;
}

export const useLawConnectionUpdateState = (companyUnitId: number): ILawConnectionsHook => {
    const {formatMessage} = useIntl();
    const initialState = {
        _laws: [],
        _connections: { aspect: [], process: [], complianceUser: [], tag:[] },
        companyUnit: companyUnitId,
        connectionAddedToLaws: [],
        connectionRemovedFromLaws: [],
        typeOptions: [
            { label: formatMessage({ id: 'law-portal.manage.update-laws.type.options.aspect' }), value: 'aspect' },
            { label: formatMessage({ id: 'law-portal.manage.update-laws.type.options.process' }), value: 'process' },
            {
              label: formatMessage({ id: 'law-portal.manage.update-laws.type.options.complianceUser' }),
              value: 'complianceUser',
            },
            {
                label: formatMessage({ id: 'law-portal.manage.update-laws.type.options.tag' }),
                value: 'tag',
              },            
          ],
          connectionOptions: []
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    const loadLawConnections = useCallback((connections: ILawConnectionsModel) => dispatch({ type: "loadConnections", payload: connections }), []);
    const setType = useCallback((selected: SelectOption) => dispatch({ type: "setType", payload: selected }), []);
    const setConnection = useCallback((selected?: SelectOption) => dispatch({ type: "setConnection", payload: selected }), []);
    const addLawConnection =  useCallback((law: IUpdatedLaw) => dispatch({ type: "addConnection", payload: { law } }), []);
    const removeLawConnection =  useCallback((law: IUpdatedLaw) => dispatch({ type: "removeConnection", payload: { law} }), []);
    const resetState =  useCallback((connections: ILawConnectionsModel) => dispatch({ type: "resetState", payload: connections }), []);
    const lawHasConnection = useCallback((lawId:number) => {
        if(state.connectionAddedToLaws.some(law => law.id === lawId)){
            return true;
        }
        else if(state.connectionRemovedFromLaws.some(law => law.id === lawId)){
            return false;
        }
        
        const initialLawConnections = state._laws.find(x => x.id === lawId);
        if (initialLawConnections && state.type){
            return initialLawConnections[state.type.key].some(x => x === state.connection?.id);
        }
    
        return false;
    }, [state])

    return {
        state, 
        loadLawConnections, 
        setType, 
        setConnection, 
        addLawConnection, 
        removeLawConnection, 
        lawHasConnection, 
        resetState
    };
  };