import { Typography, Card, CardHeader, CardContent } from '@material-ui/core';
import React from 'react';
import { LawLink } from '../../../../Components';
import { FormattedMessage, useIntl } from 'react-intl';

export const NewLawsWidget = () => {
  const { formatMessage } = useIntl();
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h3">
            <FormattedMessage id="law-portal.dashboard.new-laws"></FormattedMessage>
          </Typography>
        }
      />
      <CardContent>
        <LawLink
          url="https://aptor.se/manadens-nya-forfattningar/"
          urlText={formatMessage({ id: 'law-portal.dashboard.new-laws.link' })}
        />
      </CardContent>
    </Card>
  );
};
