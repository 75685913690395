import { StylesConfig } from 'react-select';
import theme from '../../Theme/theme';

export const styles = {
  control: (provided, state: any) => {
    const borderColor = state.isFocused ? theme.palette.primary.main : theme.palette.grey[400];
    const isDisabled = state.isDisabled ? 0.5 : 1;
    const boxShadow = state.isFocused ? `0 0 0 1px ${theme.palette.primary.main}` : 'none';
    return {
      ...provided,
      border: `1px solid ${borderColor}`,
      borderRadius: theme.shape.borderRadius,
      cursor: 'text',
      opacity: isDisabled,
      boxShadow: boxShadow,
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
    };
  },
  input: (provided) => ({
    ...provided,
    fontFamily: theme.typography.fontFamily,
    '& input': {
      fontFamily: 'inherit',
      '&:focus': {
        boxShadow: 'none',
      },
    },
    margin: 0,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: theme.shape.borderRadius,
    marginTop: 0,
    zIndex: 1000,
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[200],
  }),
  option: (provided, state: any) => {
    const { isSelected, isFocused } = state;
    let color = theme.palette.text.primary;
    let backgroundColor = theme.palette.common.white;

    if (isSelected) {
      backgroundColor = theme.palette.primary.light;
      color = theme.palette.primary.main;
    }

    if (isFocused) {
      backgroundColor = '#fdf9f9';
      color = theme.palette.primary.dark;
    }

    if (isSelected && isFocused) {
      backgroundColor = theme.palette.primary.light;
      color = theme.palette.primary.dark;
    }

    return {
      ...provided,
      backgroundColor: backgroundColor,
      color: color,
      '&:hover': {
        cursor: 'pointer',
      },
      '&:active': {
        backgroundColor: theme.palette.primary.light,
      },
    };
  },
  clearIndicator: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: theme.palette.grey[500],
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.common.black,
      backgroundColor: 'transparent',
    },
  }),
} as StylesConfig;
