import { Suspense } from 'react';
import React from 'react';
import { LawPortalModule } from './LawPortalModule';

// const LawPortalModule = React.lazy(() => import("./LawPortalModule"));

const LawPortalModuleeProxy = () => (
  <div>
    <Suspense fallback={<div />}>
      <LawPortalModule />
    </Suspense>
  </div>
);

export default LawPortalModuleeProxy;
