import { useState, useCallback, useRef } from 'react';
import { FormFieldRef } from '../Field/Field.props';
import { FormFieldState, FormFieldValue, FormFieldValueType, TypedFormFieldValue } from './Form.types';

export const useFormField = (formField: Omit<FormFieldState, 'state' | 'clear' | 'setValue' | 'formFieldRef'>) => {
  const initalState =
    formField.initialState ??
    (formField.type === 'checkbox' && formField.required ? ({ value: false } as FormFieldValue) : undefined);

  const [state, setState] = useState<FormFieldValue | readonly FormFieldValue[] | undefined>(initalState);

  const formFieldRef = useRef<FormFieldRef<FormFieldValueType>>();

  const clear = useCallback(() => {
    if (formFieldRef.current) {
      formFieldRef.current.setValue(undefined);
    }
  }, []);

  const setValue = useCallback((value: TypedFormFieldValue<FormFieldValueType> | undefined) => {
    if (formFieldRef.current) {
      formFieldRef.current.setValue(value);
    }
  }, []);

  return { ...formField, state: [state, setState], clear, setValue, formFieldRef } as FormFieldState;
};
