import React, { FC, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, Button, IconButton, Typography, Card, CardContent, CardHeader, Tooltip } from '@material-ui/core';
import { Delete, Edit, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { RequirementEditChange, IRequirementChange } from './Changes.Form';
import { HtmlView } from '../../../../../../Components/Html/Html.View';
import { useAptorApi } from '../../../../../../Api';
import { useStyles } from './Changes.View.styles';
import moment from 'moment';

interface IChangeView {
  change: IRequirementChange;
  onDelete: (id: number) => void;
  openEdit: (id: number) => void;
}

const Change: FC<IChangeView> = ({ change, openEdit, onDelete }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  return (
    <Card variant="outlined" className={classes.root}>
      <CardHeader
        action={
          <>
            <Tooltip title={formatMessage({ id: 'utils.icon.edit', defaultMessage: 'Edit' })}>
              <IconButton
                aria-label={formatMessage({ id: 'utils.icon.edit', defaultMessage: 'Edit' })}
                onClick={() => openEdit(change.id)}
              >
                <Edit fontSize="small" />
              </IconButton>
            </Tooltip>
            {change.isPosibleToDelete &&
              <Tooltip title={formatMessage({ id: 'utils.icon.delete', defaultMessage: 'Delete' })}>
                <IconButton
                  aria-label={formatMessage({ id: 'utils.icon.delete', defaultMessage: 'Delete' })}
                  onClick={() => onDelete(change.id)}
                >
                  <Delete fontSize="small" />
                </IconButton>
              </Tooltip>
            }
          </>
        }
        title={<Typography variant="h4">{change.name}</Typography>}
      />
      <CardContent>
        <Typography>
          <FormattedMessage
            id="law-portal.manage.requirements.change.effectiveFrom"
            values={{ date: moment(change.effectiveFrom).format('ll') }}
          />
        </Typography>
        <HtmlView value={change.shortDescription} />
      </CardContent>
    </Card>
  );
};

interface IProps {
  id: number;
  changes: IRequirementChange[];
  onChange: (params: { changes: IRequirementChange[] }) => void;
}

export const RequirementChangesView: FC<IProps> = ({ id, changes, onChange }) => {
  const { formatMessage } = useIntl();
  const { api, abortController } = useAptorApi();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [editingChange, setEditingChange] = useState<IRequirementChange | undefined>(undefined);
  const filterChanges = (_: IRequirementChange, index: number) => expanded || index === 0;

  useEffect(() => {
    setExpanded(false);
    setEditingChange(undefined);
  }, [changes]);

  const onCancelEdit = () => {
    setEditingChange(undefined);
  };

  const onDeleteChange = async (changeId: number) => {
    await api.deleteRequirementChange(id, changeId);
    if (abortController.current.signal.aborted) {
      return;
    }
    onChange({
      changes: [...changes.filter((c) => c.id !== changeId)]
    });
  };

  if (editingChange) {
    return <RequirementEditChange change={editingChange} id={id} changes={changes} onChange={onChange} cancel={onCancelEdit} />;
  }

  return (
    <>
      {changes &&
        changes.filter(filterChanges).map((c, index) => (
          <React.Fragment key={`change-${index}`}>
            <Change change={c} onDelete={onDeleteChange} openEdit={() => setEditingChange(c)} />
          </React.Fragment>
        ))}
      <Grid container justify="flex-end">
        {!expanded && changes && changes.length > 1 && (
          <Button
            variant="text"
            startIcon={<KeyboardArrowDown />}
            aria-label={formatMessage({
              id: 'law-portal.manage.requirements.change.expand',
              defaultMessage: 'Show older changes',
            })}
            onClick={() => setExpanded(true)}
          >
            <FormattedMessage id="law-portal.manage.requirements.change.expand" defaultMessage="Show older changes" />
          </Button>
        )}
        {expanded && (
          <Button
            variant="text"
            startIcon={<KeyboardArrowUp />}
            aria-label={formatMessage({
              id: 'law-portal.manage.requirements.change.collapse',
              defaultMessage: 'Hide older changes',
            })}
            onClick={() => setExpanded(false)}
          >
            <FormattedMessage id="law-portal.manage.requirements.change.collapse" defaultMessage="Hide older changes" />
          </Button>
        )}
      </Grid>
    </>
  );
};
