import { useState, useContext, useEffect } from 'react';
import { AptorApi } from './AptorApi';
import { useApiAbort } from './Core/useApiAbort';
import { UserContext } from '../Context/UserContext/UserContext';

export const useAptorApi = () => {
  const abortController = useApiAbort();
  const {locale} = useContext(UserContext);
  const [api, setApi] = useState<AptorApi>(new AptorApi(abortController.current, locale));

  useEffect(() => {
    if((api)["locale"] !== locale){
      setApi(new AptorApi(abortController.current, locale));
    }

  }, [locale, abortController, api]);

  return { api, abortController };
};
