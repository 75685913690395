import React, { useCallback, useContext, useEffect, useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { Query } from 'material-table';
import { MTable, Container, RepealableName } from '../../../../../Components';
import { HtmlView } from '../../../../../Components/Html/Html.View';
import { OwnNotes } from '../../../../../Components/OwnNotes';
import { useFilter } from '../../../../../Components/ListFilter';
import { RequirementsListFilter } from './CustomerRequirements.List.Filter';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../../Context/UserContext/UserContext';
import { useConfirmation } from '../../../../../Context/ConfirmationContext/ConfirmationContext';
import { useAptorApi } from '../../../../../Api';
import { INamedEntity, IGridQueryRequest, OrderDirection } from '../../../../../Api/AptorApi';
import { getConnectedEntitiesString } from '../../../../../Utilities';
import moment from 'moment';
import { useStyles } from "../../../AdministrationModule.styles";
import { ArrowUpward } from '@material-ui/icons';
import { Button, Tooltip } from '@material-ui/core';
import { ModuleContext } from "../../../../../Context/ModuleContext/ModuleContext";
export interface IRequirement extends INamedEntity {
  description: string;
  area: string;
  changedDate?: Date;
  effectiveTo?: Date;
  companyUnits: Array<INamedEntity & { note: string | null }>;
  hasReports: boolean;
  connections: {
    units: number;
    processes: number;
    aspects: number;
    tags: number;
  };
}

function getDeleteDescription(
  entity: IRequirement,
  formatter: (descriptor: MessageDescriptor, values?: Record<string, string>) => string,
  getAlias: (formatMessage: (params: { id: string }) => string, lowercase?: boolean | undefined) => string,
) {
  let warnings = '';
  if (entity.hasReports) {
    warnings = formatter({ id: 'law-portal.manage.requirements.table.delete.warning' }, { name: entity.name }) + ' ';
  } else {
    let connectionsString = getConnectedEntitiesString(
      {
        name: entity.name,
        companyUnits: entity.connections.units.toString(),
        processes: entity.connections.processes.toString(),
        aspects: entity.connections.aspects.toString(),
        tags: entity.connections.tags.toString(),
      },
      formatter,
      getAlias,
    );
    if (connectionsString) warnings = connectionsString + ' ';
  }
  return warnings + formatter({ id: 'law-portal.manage.requirements.table.delete.confirmation' });
}

export const OtherRequirements = () => {
  const { api, abortController } = useAptorApi();
  const history = useHistory();
  const tableRef = React.createRef();
  const { formatMessage } = useIntl();
  const { deleteConfirmation } = useConfirmation();
  const { getCompanyUnitName, hasAccessToFeature } = useContext(UserContext);
  const canManage = hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);
  const [filters, setFilters] = useFilter();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const classes = useStyles()
  useEffect(() => {
    tableRef.current && (tableRef.current as any).onQueryChange();
  }, [filters, tableRef]);

  useEffect(() => {
    const queryParamsMap = {
      status: 'status',
      companyUnit: 'companyUnit',
      complianceUsers: 'complianceUsers',
      processes: 'processes',
      aspects: 'aspects',
      area: 'area',
      tags: 'tags',
    };
    // Object.entries(queryParamsMap).forEach(([queryKey, stateKey]) => {
    //   const queryValues = queryParams.getAll(queryKey);
    //   if (queryValues.length) {
    //     setFilters(stateKey, queryValues.join(',').split(',').map(num => num.toString()));
    //   }
    // });
    if (location.search?.split('&')?.length > 1) {
      Object.entries(queryParamsMap).forEach(([queryKey, stateKey]) => {
        const queryValues = queryParams.getAll(queryKey);
        if (queryValues.length) {
          setFilters(stateKey, queryValues.join(',').split(',').map(num => num.toString()));
        }
      });
    } else {
      let saveFilter: any = localStorage.getItem('org-res')
      const queryParams = new URLSearchParams(saveFilter);
      Object.entries(queryParamsMap).forEach(([queryKey, stateKey]) => {
        const queryValues = queryParams.getAll(queryKey);
        if (queryValues.length) {
          setFilters(stateKey, queryValues.join(',').split(',').map(num => num.toString()));
        }
      });

    }
  }, []);

  
  const handleDataFetch = useCallback(
    async (query: Query<IRequirement>) => {
      return await api.searchCustomerRequirements<IRequirement>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'name',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest);
    },
    [api, filters],
  );

  const edit = (req: IRequirement) => ({
    tooltip: formatMessage({ id: 'component.mtable.editTooltip' }),
    icon: 'edit',
    onClick: () => history.push(`/organization/law-portal/other-requirements/${req.id}`),
  });

  const remove = (req: IRequirement) => ({
    tooltip: formatMessage({ id: 'component.mtable.deleteTooltip' }),
    icon: 'delete',
    onClick: async () => {
      const onRemove = async () => {
        await api.removeCustomerRequirement(req.id);
        if (abortController.current.signal.aborted) {
          return;
        }
        (tableRef.current as any).onQueryChange();
      };
      deleteConfirmation(onRemove, { description: getDeleteDescription(req, formatMessage, getCompanyUnitName) });
    },
  });

  const renderDate = (date?: Date) => {
    return date ? moment(date).format('ll') : undefined;
  };

  const { setHeader } = useContext(ModuleContext);
  useEffect(() => {
    setHeader(formatMessage({ id: 'law-portal.manage.requirements', defaultMessage: 'other requirements' }));
  }, [setHeader, formatMessage]);
  const [showScrollTop, setShowScrollTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const updatedQueryParams = Object.keys(filters)
      .filter(key => filters[key]?.join() !== undefined && filters[key]?.join() !== "")
      .map(key => `${key}=${encodeURIComponent(filters[key]?.join())}`)
      .join('&');
      localStorage.setItem("org-res", updatedQueryParams)
    history.push({
      pathname: '',
      search: `?${updatedQueryParams}`,
    });
  }, [filters, history]);
  return (
    <>
      <Container
        fullWidth
        // backButtonTo="/organization/law-portal"
        linkButtonTo={canManage ? '/organization/law-portal/other-requirements/new' : undefined}
        linkButtonLabel="law-portal.manage.requirements.addRequirement"
      >
        <RequirementsListFilter filters={filters} setFilter={setFilters} />
        <MTable
          tableRef={tableRef}
          columns={[
            {
              title: formatMessage({ id: 'law-portal.manage.requirements.table.name' }),
              field: 'name',
              render: (row) => <RepealableName name={row.name} effectiveTo={row.effectiveTo} />,
            },
            {
              title: formatMessage({ id: 'law-portal.manage.requirements.table.description' }),
              field: 'description',
              render: (data: IRequirement) => <HtmlView variant="body2" value={data.description} />,
              sorting: false,
            },
            { title: formatMessage({ id: 'law-portal.manage.requirements.table.area' }), field: 'area' },
            {
              title: formatMessage({ id: 'law-portal.manage.requirements.table.changedDate' }),
              field: 'changedDate',
              render: (row) => renderDate(row.changedDate),
            },
            {
              title: formatMessage({ id: 'law-portal.manage.requirements.table.notes' }),
              field: 'notes',
              render: (row) => <OwnNotes {...row} linkTo={`/law-portal/other-requirements/${row.id}`} />,
              sorting: false,
            },
          ]}
          data={handleDataFetch}
          actions={canManage ? [edit, remove] : undefined}
          components={{}}
        />
      </Container>
      {showScrollTop && (
        <Tooltip title={formatMessage({ id: "bottom.to.top.btn.tooltip.text", defaultMessage: "Back to top" })}>
          <Button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className={classes.topButton}
          >
            <ArrowUpward style={{ fontSize: 20 }} />
          </Button>
        </Tooltip>
      )}
    </>
  );
};
