import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';

import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAptorApi } from '../../../../../Api';
import { Loading } from '../../../../../Components';
import { UserLink } from './Link';
import { MarkAsHandled } from './MarkAsHandled';

interface INewUser {
  id: number;
  name: string;
}

export const NewUsersWidget = () => {
  const { api, abortController } = useAptorApi();
  const [newUsers, setNewUsers] = useState<INewUser[]>();

  useEffect(() => {
    api.getDashboardNewUserItems<INewUser>().then((result) => {
      if (abortController.current.signal.aborted) {
        return;
      }
      setNewUsers(result.items);
    });
  }, [api, abortController]);

  const onHandled = (id: number) => {
    if (newUsers) {
      setNewUsers(newUsers.exclude((x) => x.id === id));
    }
  };

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h3">
            <FormattedMessage id="law-portal.dashboard.new-users" />
          </Typography>
        }
      />
      <CardContent>
        {(newUsers === undefined && <Loading />) || (
          <List>
            {newUsers?.map((item) => (
              <ListItem key={item.id}>
                <ListItemText primary={item.name}></ListItemText>
                <ListItemSecondaryAction>
                  <UserLink {...item} />
                  <MarkAsHandled {...item} onHandled={onHandled} />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </CardContent>
    </Card>
  );
};
