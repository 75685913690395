import { useReducer, useCallback } from 'react';

interface IUpdate<TQuestion extends IQuestionType> {
  type: 'update';
  questionId: number;
  groupId: number | undefined;
  state: Partial<TQuestion>;
}

interface ILoad<TQuestion extends IQuestionType, TGroup extends IGroupType<TQuestion>> {
  type: 'load';
  initialValues: Array<TQuestion | TGroup>;
}

interface IQuestionType {
  type: 'question';
  id: number;
}

interface IGroupType<TQuestion extends IQuestionType> {
  type: 'group';
  id: number;
  groupItems: TQuestion[];
}

const reducer = <TQuestion extends IQuestionType, TGroup extends IGroupType<TQuestion>>() => (
  items: Array<TQuestion | TGroup>,
  action: ILoad<TQuestion, TGroup> | IUpdate<TQuestion>,
): Array<TQuestion | TGroup> => {
  if (action.type === 'load') {
    return action.initialValues;
  }

  const newItems = [...items];
  if (action.groupId) {
    const groupIndex = newItems.findIndex((i) => i.id === action.groupId && i.type === 'group');
    const group = newItems[groupIndex] as TGroup;
    const groupItems = [...group.groupItems];
    const questionIndex = groupItems.findIndex((i) => i.id === action.questionId)!;
    groupItems[questionIndex] = { ...groupItems[questionIndex], ...action.state };
    newItems[groupIndex] = { ...group, ...{ groupItems } };
  } else {
    const index = newItems.findIndex((i) => i.id === action.questionId && i.type === 'question');
    newItems[index] = { ...newItems[index], ...action.state };
  }
  return newItems;
};

export const useChecklist = <TQuestion extends IQuestionType, TGroup extends IGroupType<TQuestion>>(): [
  Array<TQuestion | TGroup>,
  (items: Array<TQuestion | TGroup>) => void,
  (questionId: number, groupId: number | undefined, state: Partial<TQuestion>) => void,
] => {
  const typedReducer = reducer<TQuestion, TGroup>();
  const [items, dispatch] = useReducer(typedReducer, []);
  const load = useCallback((items: Array<TQuestion | TGroup>) => dispatch({ type: 'load', initialValues: items }), []);
  const update = useCallback(
    (questionId: number, groupId: number | undefined, state: Partial<TQuestion>) =>
      dispatch({ type: 'update', questionId, groupId, state }),
    [],
  );
  return [items, load, update];
};
