import React, { useContext } from 'react';
import { Typography, Button, Grid, Hidden, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { UserContext } from '../../Context/UserContext/UserContext';
import { useIntl } from 'react-intl';
import Countdown from 'react-countdown';
import moment from 'moment';
import { useStyles } from './ImpersonationBar.styles';

export const ImpersonationBar = () => {
  const classes = useStyles();
  const intl = useIntl();
  const {
    firstName,
    lastName,
    email,
    customerName,
    impersonateUserWithInvitation,
    impersonateUser,
    authorizationFailed,
    supportInvitation,
    userImpersonation,
  } = useContext(UserContext);

  const handleCancelClick = () => {
    if (supportInvitation) {
      impersonateUserWithInvitation();
    }

    if (userImpersonation) {
      impersonateUser();
    }
  };

  return (
    <Grid className={classes.root} container justify="space-between" alignItems="center" wrap="nowrap">
      <Grid item sm={10}>
        <Typography variant="body2">
          {intl.formatMessage({ id: 'component.impersonation-bar.impersonatedAs' })}{' '}
          <span className={classes.name}>{authorizationFailed ? userImpersonation : `${firstName} ${lastName}`}</span>{' '}
          {!authorizationFailed && (
            <>
              ({email}) {intl.formatMessage({ id: 'component.impersonation-bar.from' })} {customerName}
            </>
          )}
        </Typography>
        {supportInvitation && (
          <Typography variant="overline" className={classes.countdown}>
            {intl.formatMessage({ id: 'component.impersonation-bar.expiresIn' })}{' '}
            <Countdown
              renderer={(props) => {
                return (
                  <>
                    {(props.days && `${props.days} ${intl.formatMessage({ id: 'utils.time.days' })}, `) || ''}
                    {(props.hours && `${props.hours} ${intl.formatMessage({ id: 'utils.time.hours' })}, `) || ''}
                    {(props.minutes && `${props.minutes} ${intl.formatMessage({ id: 'utils.time.minutes' })}, `) || ''}
                    {`${props.seconds} ${intl.formatMessage({ id: 'utils.time.seconds' })}`}
                  </>
                );
              }}
              date={moment.utc(supportInvitation?.validTo).local().toDate()}
              onComplete={handleCancelClick}
            />
          </Typography>
        )}
      </Grid>
      <Grid item>
        <Hidden only="xs">
          <Button onClick={handleCancelClick} variant="outlined" color="inherit" size="small" startIcon={<Close />}>
            {intl.formatMessage({ id: 'component.impersonation-bar.cancelImpersonation' })}
          </Button>
        </Hidden>
        <Hidden smUp>
          <IconButton
            onClick={handleCancelClick}
            color="inherit"
            aria-label={intl.formatMessage({ id: 'component.impersonation-bar.cancelImpersonation' })}
          >
            <Close />
          </IconButton>
        </Hidden>
      </Grid>
    </Grid>
  );
};
