import React, { FC, useContext, useCallback, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  ListFilter,
  createMultiSelectFilter,
  createSelectFilter,
  // createDateFilter,
} from '../../../../../Components/ListFilter';
import { SelectOption } from '../../../../../Components/Form/Form.types';
import { UserContext } from '../../../../../Context/UserContext/UserContext';
import { useAptorApi, useFetchFilterOptions } from '../../../../../Api';
import { OptionsEntity, entityToSelectlebelOption } from '../../../../../Utilities';
import { updateFilterOptionsDependentOnCompanyUnit } from '../../../../../Components/ListFilter/filterUtils';

interface IOptions extends OptionsEntity {
  areas: SelectOption[];
  companyUnits: SelectOption[];
  complianceUsers: SelectOption[];
  statuses: SelectOption[];
  processes: SelectOption[];
  aspects: SelectOption[];
  tags: SelectOption[];
}

interface IProps {
  filters: any;
  setFilter: (field: string, values: string[] | { min?: Date; max?: Date } | undefined) => void;
}

export const RequirementListFilter: FC<IProps> = ({ filters, setFilter }) => {
  const { getCompanyUnitName } = useContext(UserContext);
  const { formatMessage } = useIntl();
  const { api, abortController } = useAptorApi();
  const [allOptions, , , userCompanyUnits] = useFetchFilterOptions<IOptions>(
    useCallback(() => api.getLawPortalRequirementFilters(), [api]),
    abortController,
  );

  const [options, setOptions] = useState<IOptions | null>(null);

  useEffect(() => {
    setOptions(allOptions);
  }, [allOptions]);

  const onSetFilter = (field: string, values: string[] | { min?: Date; max?: Date } | undefined) => {
    setFilter(field, values);
    updateFilterOptionsDependentOnCompanyUnit(
      field,
      values,
      setOptions,
      allOptions,
      filters,
      setFilter,
      userCompanyUnits,
    );
  };

  const areaFilter = createSelectFilter(
    'area',
    formatMessage({ id: 'utils.filters.area' }),
    options?.areas?.map(entityToSelectlebelOption),
    (option) => option.value.toString(),
    undefined,
    filters.area,
  );

  const companyUnitFilter = createSelectFilter(
    'companyUnit',
    getCompanyUnitName(formatMessage),
    options?.companyUnits?.map(entityToSelectlebelOption),
    (option) => option.value.toString(),
    undefined,
    filters.companyUnit,
  );

  const complianceUsersFilter = createMultiSelectFilter(
    'complianceUsers',
    formatMessage({ id: 'utils.filters.complianceUsers' }),
    options?.complianceUsers,
    (option) => option.value.toString(),
    undefined,
    filters.complianceUsers as string[],
  );

  const activeOption = { value: 1, label: formatMessage({ id: 'utils.filters.status.active' }) };

  const statusFilter = createSelectFilter(
    'status',
    formatMessage({ id: 'utils.filters.status' }),
    options?.statuses,
    (option) => option.value.toString(),
    activeOption,
    filters?.status,
  );

  const processesFilter = createMultiSelectFilter(
    'processes',
    formatMessage({ id: 'utils.filters.processes' }),
    options?.processes,
    (option) => option.value.toString(),
    undefined,
    filters.processes as string[],
  );

  const aspectsFilter = createMultiSelectFilter(
    'aspects',
    formatMessage({ id: 'utils.filters.aspects' }),
    options?.aspects,
    (option) => option.value.toString(),
    undefined,
    filters.aspects as string[],
  );

  const tagsFilter = createMultiSelectFilter(
    'tags',
    formatMessage({ id: 'utils.filters.tags' }),
    options?.tags,
    (option) => option.value.toString(),
    undefined,
    filters.tags as string[],
  );

  // const complianceIntervalFilter = createDateFilter(
  //   'nextComplianceInterval',
  //   formatMessage({ id: 'utils.filters.nextRequirementComplianceInterval.from' }),
  //   formatMessage({ id: 'utils.filters.nextRequirementComplianceInterval.to' }),
  // );

  return (
    <ListFilter
      filters={[
        companyUnitFilter,
        complianceUsersFilter,
        processesFilter,
        aspectsFilter,
        tagsFilter,
        areaFilter,
        statusFilter,
        // complianceIntervalFilter,
      ]}
      setFilter={onSetFilter}
    />
  );
};
