import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Container, Form, Panel, Loading, BackButton } from '../../../../../Components';
import { FormValues, SelectOption } from '../../../../../Components/Form/Form.types';
import { useFormField } from '../../../../../Components/Form/FormField';
import { UserContext } from '../../../../../Context/UserContext/UserContext';
import { AptorApi, useAptorApi } from '../../../../../Api';
import { entityToSelectOption } from '../../../../../Utilities';
import { useSnackbar } from 'notistack';

interface IOptions {
  areas: SelectOption[];
  companyUnits: SelectOption[];
  complianceUsers: SelectOption[];
}

interface IProps extends IOptions {
  submit: (form: FormValues, api: AptorApi, onSuccess: () => void) => Promise<void>;
}

const EditRequirementForm: FC<IProps> = ({ areas, companyUnits, complianceUsers, submit }) => {
  const { formatMessage } = useIntl();
  const { getCompanyUnitName } = useContext(UserContext);

  const nameField = useFormField({
    label: formatMessage({ id: 'form.name' }),
    name: 'name',
    type: 'text',
    required: true,
  });

  const areaField = useFormField({
    label: formatMessage({ id: 'utils.filters.area' }),
    name: 'area',
    type: 'single-select',
    options: areas,
    required: true,
  });

  const effectiveFrom = useFormField({
    label: formatMessage({ id: 'law-portal.manage.requirements.effectiveFrom' }),
    name: 'effectiveFrom',
    type: 'date',
    required: true,
  });

  const effectiveTo = useFormField({
    label: formatMessage({ id: 'law-portal.manage.requirements.effectiveTo' }),
    name: 'effectiveTo',
    type: 'date',
    required: false,
  });

  const unitField = useFormField({
    label: getCompanyUnitName(formatMessage),
    name: 'unitIds',
    type: 'multi-select',
    options: companyUnits,
    required: true,
  });

  const usersField = useFormField({
    label: formatMessage({ id: 'utils.filters.complianceUsers' }),
    name: 'complianceUsers',
    type: 'multi-select',
    options: complianceUsers,
    required: false,
  });

  const descriptionField = useFormField({
    label: formatMessage({ id: 'law-portal.manage.requirements.description' }),
    name: 'description',
    type: 'rich-text',
    required: true,
  });

  return (
    <Form
      submit={submit}
      groups={[
        {
          grid: {
            type: 'row',
            items: [
              {
                type: 'column',
                items: [
                  { type: 'row', items: [nameField, areaField] },
                  { type: 'row', items: [effectiveFrom, effectiveTo] },
                  { type: 'row', items: [unitField, usersField] },
                ],
              },
              {
                type: 'column',
                items: [descriptionField],
              },
            ],
          },
        },
      ]}
    />
  );
};

export const NewRequirement = () => {
  const { api, abortController } = useAptorApi();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { getCompanyUnitName } = useContext(UserContext);
  const [options, setOptions] = useState<IOptions | null>(null);

  useEffect(() => {
    Promise.all([
      api.getCustomerLawSettings('areas'),
      api.getCustomerCompanyUnits(),
      api.getCustomerUsers(),
    ]).then(([areas, units, users]) => {
      if (abortController.current.signal.aborted) {
        return;
      }
      setOptions({
        areas: areas.items.map(entityToSelectOption),
        companyUnits: units.items.map(entityToSelectOption),
        complianceUsers: users.items.map(entityToSelectOption),
      });
    });
  }, [api, abortController]);

  const handleSubmit = async (data: FormValues, api: AptorApi, onSuccess: () => void) => {
    var ok = await api.createCustomerRequirement(data);
    if(!ok && !abortController.current.signal.aborted){
      let msg : string = formatMessage({id: 'law-portal.manage.requirements.addRequirement.warning'}, {warning: formatMessage({id: 'organization.user-unit-mismatch'},{alias: getCompanyUnitName(formatMessage,true)})});
      enqueueSnackbar(msg, { variant: 'warning' });
    }
    else onSuccess();
    history.push('/organization/law-portal/other-requirements');
  };

  return (
    (!options && <Loading />) || (
      <Container>
        {/* <BackButton link="/organization/law-portal/other-requirements" /> */}
        <BackButton disableMargin link="" onclick={()=>history.goBack()} />
        <Panel titleKey="law-portal.manage.requirements.addRequirement" accordion={false}>
          <EditRequirementForm
            submit={handleSubmit}
            {...options!}
          />
        </Panel>
      </Container>
    )
  );
};
