import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%',
  },
  error: {
    borderColor: theme.palette.error.main,
  },
  focused: {
    boxShadow: `0 0 0 1px #c4122f`, //Box-shadow instead of border to avoid div from moving
  },
  helpLabel: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  errorList: {
    padding: 0,
    '& li': {
      padding: 0,
    },
  },
  label: {
    fontSize: '1rem',
  },
  checkbox: {
    //marginTop: theme.spacing(2),
  },
  checkboxError: {
    color: theme.palette.error.main,
  },
  richTextError: {
    outline: `1px solid ${theme.palette.error.main}`,
  },
  selectError: {
    outline: `1px solid ${theme.palette.error.main}`,
  },
  value: {
    fontWeight: theme.typography.fontWeightBold, //For Information.View
  },
  'sr-only': {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: 0,
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0,0,0,0)',
    border: 0,
  },
  searchRoot: {
    border: "1px solid #BDBDBD",
    [theme.breakpoints.down("xs")]: {
      width: "calc(92% - 0.2rem)",
    },
    height: 40,
    borderBottomWidth: 2,
    borderRadius: 5,
    "&:hover": {
      borderColor: "#c4122f",
      borderBottomWidth: 2,
    },
  },
  searchBar: {
    width: "100%",
    height: 40,
    marginBottom: 0.5,
    "&:hover": {
      borderBottomWidth: 2,
      borderBottomColor: "black",
    },
  },
  searchIcon: {
    margin: "0.5rem",
    color: "grey",
    fontWeight: 400,
    fontFamily: `"Work Sans", "Arial", "sans-serif"`,
    fontSize: "1.2rem",
  },
  closeSearch: {
    color: "grey",
    fontWeight: 400,
    fontFamily: `"Work Sans", "Arial", "sans-serif"`,
    fontSize: "1.2rem",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    paddingLeft: 0,
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 2rem)",
    },
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: 400,
    fontFamily: `"Work Sans", "Arial", "sans-serif"`,
    fontSize: "0.875rem",
  },
  inputs:{
    border:'none',
    '&:focus':{
      outline: 'none' 
    }
  }
}));
