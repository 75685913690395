import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useStyles } from '../../../LawPortal.styles';
import {
  ButtonLink,
  Form,
  HtmlView,
  MTable,
  RepealableName,
  //  Panel,
  Transition
} from '../../../../../Components';
import { useIntl } from 'react-intl';
import { CreatePlannig } from './CreatePlanning';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  ArrowBack,
  ControlPointDuplicate,
  Delete,
  Edit,
  ExpandMore,
  SaveAlt,
  // PlaylistAdd,
  SyncDisabledSharp,
  SyncSharp,
} from '@material-ui/icons';
import { PlanningView } from './View/PlanningView';
// import async from 'react-select/async';
import {
  // AptorApi,
  useAptorApi
} from '../../../../../Api';
import {
  // PlanFeature,
  UserContext
} from '../../../../../Context/UserContext/UserContext';
import moment from 'moment';
import { Title } from '../../../../../Components/Panel/Panel';
import { SearchTextField, SelectField } from '../../../../../Components/Field';
import { useSnackbar } from "notistack";
import { ExecuteFilter } from './ExecuteFilter';
import { createSelectFilter, useFilter } from '../../../../../Components/ListFilter';
import { MTableToolbar, Query } from 'material-table';
import { IGridQueryRequest, OrderDirection } from '../../../../../Api/AptorApi';
import { entityToSelectOption } from '../../../../../Utilities';
import { useFormField } from '../../../../../Components/Form/FormField';
import { useHistory, useLocation } from 'react-router-dom';
interface IconProps {
  id: number;
  name: string;
  icon?: React.ReactNode;
  onClick?: (event: any, planData?: any) => void;
}
export const PlanningLK = () => {
  const classes = useStyles();
  let { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar()
  const [newCreate, setNewCreate] = useState(false);
  const { customerId } = useContext(UserContext);
  const { api, abortController } = useAptorApi();
  const [activePlanList, setActivePlan] = useState([]);
  const [inActivePlanList, setInActivePlan] = useState([]);
  const [updateLaws, setUpdateLaws] = useState('');
  const [planLoading, setPlanLoading] = useState(false);
  // const [editPlanId, setEditPlanID] = useState('');
  const [duplicatePlanData, setDuplicatePlanData] = useState('');
  const [expendinActivePanel, setExpendInActivePanel] = useState('');
  const [expendActivePanel, setExpendActivePanel] = useState('');
  const [editData, setEditData] = useState("")
  const [filters, setFilter] = useFilter();
  const [search, setSearch] = useState("")
  let [dataTable, setTableData] = useState(activePlanList?.length ? activePlanList : []);
  const [activeTab, setActiveTab] = useState<any>('active')
  const [allcontrol,setAllControl]=useState([])
  const [type,setType]=useState<any>(-1)
  const [query, setQuery] = useState({
    page: 0,
    pageSize: 50, 
    orderBy: 'name', 
    orderDirection: 'desc',
  });

  // const handleUpdateLaws = (data: any) => {
  //   setUpdateLaws(data?.planData?.id);
  //   setEditPlanID('');
  //   if (data?.planData?.isActive) {
  //     setExpendActivePanel(data?.planData?.id);
  //   } else {
  //     setExpendInActivePanel(data?.planData?.id);
  //   }
  // };
  useEffect(() => {
    getCompliancePlan();
  }, [filters,search]);
  const getCompliancePlan = async () => {
    setPlanLoading(true);
    await api
      .getAllcompliancePlan(customerId,{filters:filters,search:search})
      .then((response: any) => {
        if (!abortController.current.signal.aborted) {
          const compliancePlan = response?.customerPlans?.filter(
            (val: any) => !val.isDirect
          );
          const activePlan = compliancePlan.filter((val: any) => val.isActive);
          const inActivePlan = compliancePlan.filter((val: any) => !val.isActive);
          activePlan?.sort((a: any, b: any) => {
            const startDateComparison = new Date(b.startDate).getTime() as number - new Date(a.startDate).getTime() as number
            if (startDateComparison === 0) {
              return a.name.localeCompare(b.name);
            }

            return startDateComparison;
          });
          inActivePlan?.sort((a: any, b: any) => {
            const startDateComparison = new Date(b.startDate).getTime() as number - new Date(a.startDate).getTime() as number
            if (startDateComparison === 0) {
              return a.name.localeCompare(b.name);
            }

            return startDateComparison;
          });

          setActivePlan(activePlan.length ? activePlan : []);
          setTableData(compliancePlan.length ? activePlan : []);
          setInActivePlan(inActivePlan.length ? inActivePlan : []);
          setInactiveTable(inActivePlan.length ? inActivePlan : [])
          setPlanLoading(false);

        }
      })
      .catch(() => {
        setPlanLoading(false);
      });
    // const { customerPlans } = compliancePlan || [];
    // const activePlan = customerPlans.filter((val:any) => val.isActive);
    // const inActivePlan = customerPlans.filter((val:any) => !val.isActive);
    // setActivePlan(activePlan.length ? activePlan : []);
    // setInActivePlan(inActivePlan.length ? inActivePlan : []);

    // setPlanLoading(false);
  };
  const editPlane = (data: any) => {
    // setEditPlanID(data?.planData?.id);
    setEditData(data?.planData)
    setNewCreate(!newCreate);
    // if (data?.planData?.isActive) {
    //   setExpendActivePanel('');
    // } else {
    //   setExpendInActivePanel('');
    // }
  };
  const handleDuplicatePlan = (data: any) => {
    // setEditPlanID('');
    setDuplicatePlanData(data?.planData);
    setNewCreate(!newCreate);
  };
  const handleActiveInActive = async (data: any) => {
    await api.planStatus(data?.planData?.id);
    getCompliancePlan();
    handleAllControl()
  };
  const handleDeletePlan = async (data: any) => {
    await api.deletePlan(data?.planData?.id).then((res) => {
      if (res.errorMessage !== '') {
        enqueueSnackbar(formatMessage({ id: res.errorMessage }), { variant: 'error' });
      } else {
        getCompliancePlan();
        handleAllControl()
      }
      // enqueueSnackbar(formatMessage({ id: 'utils.snackbar.somethingWentWrong' }), { variant: 'error' });
    })
  };
  const data: IconProps[] = [
    {
      id: 1,
      name: 'law-portal.legal.plan.copy.duplicate',
      icon: <ControlPointDuplicate />,
      onClick: (event: any, planData: any) => {
        event.stopPropagation();
        handleDuplicatePlan(planData);
      },
    },
    // {
    //   id: 2,
    //   name: 'law-portal.legal.plan.add.legislation',
    //   icon: <PlaylistAdd />,
    //   onClick: (event: any, planData: any) => {
    //     event.stopPropagation();
    //     handleUpdateLaws(planData);
    //   },
    // },
    {
      id: 3,
      name: 'law-portal.legal.plan.edit',
      icon: <Edit />,
      onClick: (event: any, planData: any) => {
        event.stopPropagation();
        editPlane(planData);
      },
    },
    {
      id: 4,
      name: 'law-portal.legal.plan.delete',
      icon: <Delete />,
      onClick: (event: any, planData: any) => {
        event.stopPropagation();
        handleDeletePlan(planData);
      },
    },
    {
      id: 5,
      name: 'law-portal.legal.plan.deactivate',
      icon: <SyncDisabledSharp />,
      onClick: (event: any, planData: any) => {
        event.stopPropagation();
        handleActiveInActive(planData);
      },
    },
    {
      id: 6,
      name: 'law-portal.legal.plan.publish',
      icon: <SyncSharp />,
      onClick: (event: any, planData: any) => {
        event.stopPropagation();
        handleActiveInActive(planData);
      },
    },
  ];
  const IconsNode = (planData: any) => {
    const actionIcon = data.filter((val: any) => {
      return planData?.planData?.isActive
        ? val.name !== 'law-portal.legal.plan.publish'
        : val.name !== 'law-portal.legal.plan.deactivate';
    });
    return (
      <Grid>
        {actionIcon?.map((item, index) => (
          <Tooltip title={formatMessage({ id: item.name })} key={index}>
            <IconButton
              style={{ cursor: 'pointer' }}
              onClick={(event) => item.onClick && item.onClick(event, planData)}
              key={item.id}
            >
              {item.icon}
            </IconButton>
          </Tooltip>
        ))}
      </Grid>
    );
  };
  const newPlanCreate = () => {
    setNewCreate(!newCreate);
    setDuplicatePlanData('');
    setEditData("")
  };

  const handlePanelToggle = (panelId: string) => {
    if (expendinActivePanel === panelId) {
      setExpendInActivePanel('');
      setUpdateLaws('');
    } else {
      setExpendInActivePanel(panelId);
    }
  };
  const handleActivePanelToggle = (panelId: string) => {
    if (expendActivePanel === panelId) {
      setExpendActivePanel('');
      setUpdateLaws('');
    } else {
      setExpendActivePanel(panelId);
    }
  };
  const [searchData, setSearchData] = useState('');
  let searchHandle = (str: string) => {
    setSearchData(str);
    if (str) {
      let myData = activePlanList?.filter((item: any) => {
        let findEndDate = item?.endDate.toLowerCase().includes(str.toLowerCase());
        let findStartDate = item?.startDate.toLowerCase().includes(str.toLowerCase());
        let planName = item?.name.toLowerCase().includes(str.toLowerCase());
        return findStartDate || findEndDate || planName;
      });
      setTableData(myData);
    } else {
      setTableData(activePlanList?.length ? activePlanList : []);
    }
  };
  const [SearchInactiveData, setSearchInactiveData] = useState('');
  const [InactiveTable, setInactiveTable] = useState(inActivePlanList?.length ? inActivePlanList : [])
  const searchHandleIncative = (str: string) => {
    setSearchInactiveData(str);
    if (str) {
      let myData = inActivePlanList?.filter((item: any) => {
        let findEndDate = item?.endDate.toLowerCase().includes(str.toLowerCase());
        let findStartDate = item?.startDate.toLowerCase().includes(str.toLowerCase());
        let planName = item?.name.toLowerCase().includes(str.toLowerCase());
        return findStartDate || findEndDate || planName;
      });
      setInactiveTable(myData);
    } else {
      setInactiveTable(inActivePlanList?.length ? inActivePlanList : []);
    }
  }
  const columnplan = [
    { title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.name-of-control' }), field: 'name' },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Company-unit' }),
      field: 'customerCompanyUnitId',
      render: (row: any) => row?.customerCompanyUnitId?.name,
    },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Start-date' }),
      field: 'startDate',
      render: (row: any) => moment(row?.startDate).format('YYYY-MM-DD'),
      sorting: false,
    },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.End-date' }), field: 'endDate',
      render: (row: any) => moment(row?.endDate).format('YYYY-MM-DD'), sorting: false,
    },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Recuring-interval' }),
      field: 'recurrentInterval',
      render: (row: any) => row?.recurrentInterval || "-",
      sorting: false,
    },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Number-of-controls' }),
      field: 'lawCount',
      render: (row: any) => row.lawCount,
      sorting: false,
    },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Edit' }),
      field: '',
      width: '20%',
      render: (row: any) => <>
        <IconsNode planData={row} />
      </>,
      sorting: false,
    },
  ]
  const allplan :any= [
    { title: formatMessage({ id: 'law-portal.our-laws.table.number' }), field: 'number',sorting: false},
    {
      title: formatMessage({ id: 'law-portal.our-laws.table.law' }),
      field: 'legislation',
      sorting: false, 
      
    },
    { title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.name-of-control' }), field: 'name' ,sorting: false},
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Company-unit' }),
      field: 'customerCompanyUnitId',
      render: (row: any) => row?.customerCompanyUnitId?.name,
      sorting: false
    },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Start-date' }),
      field: 'startDate',
      render: (row: any) => moment(row?.startDate).format('YYYY-MM-DD'),
      sorting: false
    },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.End-date' }), field: 'endDate',
      render: (row: any) => moment(row?.endDate).format('YYYY-MM-DD'), sorting: false
    },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Recuring-interval' }),
      field: 'recurrentInterval',
      render: (row: any) => row?.recurrentInterval || "-",
      sorting: false
    },
    {
      title: formatMessage({ id: 'law-portal.compliance.report.table.col' }),
      field: 'responsible',
      render: (row: any) => {
        if (row.responsibles) {
          const responsiblearr = row?.responsibles?.split(',')
          return (
            <>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {responsiblearr?.map((val: any) => {
                  return <span>{val}</span>
                })}
              </div>
            </>
          )
        } else {
          return "-"
        }
      },
      sorting: false
    },
  ]
  const tableRef = React.createRef();
  const [column, setColumn] = useState(columnplan)
  // const handleAllControl = useCallback(
  //   async (query: Query<any>) => {
  //     return await api.getAllcompliancePlanAllControl<any>(customerId,{
  //       orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
  //       orderBy: query.orderBy !== undefined ? query.orderBy.field : 'name',
  //       page: query.page,
  //       pageSize: query.pageSize,
        // search: search,
        // filters: filters,
  //     } as IGridQueryRequest);
  //   },
  //   [api, filters, search],
  // );

  const handleAllControl=async()=>{
    const allcompliance:any= await api.getAllcompliancePlanAllControl<any>(customerId,{ search: search,
      filters: filters});
    setAllControl(allcompliance?.filter((data:any)=>data?.isDirect==false&&data?.isActive==true))
  }

useEffect(()=>{
  handleAllControl()
},[filters,search])


  const handleAll = () => {
    setColumn(allplan)
    setActiveTab('all')
  }
  const typeField: any = [
    { id: -1, name: formatMessage({ id: "law-portal.compliance-law.plan-compliance.all-control"}) },
    { id: 0, name: formatMessage({ id: "law-portal.plan.type.law", defaultMessage: 'Legislation' }) },
    { id: 1, name: formatMessage({ id: "law-portal.plan.type.other-req", defaultMessage: 'Other requirements' }) }
  ]
  const planType = useFormField({
    type: 'single-select',
    name: 'type',
    required: true,
    label: formatMessage({ id: 'law-portal.create-plan.form-field-type' }),
    options: typeField.map(entityToSelectOption),
    initialState: type,
    checkedValue(value) {
      setType(value)
    },
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const history = useHistory()
  useEffect(() => {
    const queryParamsMap = {
      tags: 'tags',
      companyUnit: 'companyUnit',
      complianceUsers: 'complianceUsers',
      processes: 'processes',
      aspects: 'aspects',
      category: 'category',
      area: 'area',
    };
    const searchQuery = queryParams.getAll('search')
    if (searchQuery.length) {
      setSearch(searchQuery[0])
    }
    Object.entries(queryParamsMap).forEach(([queryKey, stateKey]) => {
      const queryValues = queryParams.getAll(queryKey);
      if (queryValues.length) {
        setFilter(stateKey, queryValues.join(',').split(',').map(num => num.toString()));
      }
    });
  }, []);
  useEffect(() => {
    var updatedQueryParams = Object.keys(filters)
      .filter(key => filters[key]?.join() !== undefined && filters[key]?.join() !== "")
      .map(key => `${key}=${encodeURIComponent(filters[key]?.join())}`)
      .join('&');
    if (search !== "") {
      updatedQueryParams = `${updatedQueryParams}&search=${search}`
    }
    history.push({
      pathname: '',
      search: `?${updatedQueryParams}`,
    });
  }, [filters, history, search]);
  return (
    <div className={classes.listWrapper}>
      {newCreate ? (
        <ButtonLink
          text={formatMessage({ id: 'component.back-button.back' })}
          onclick={newPlanCreate}
          to={''}
          icon={<ArrowBack />}
        />
      ) : (
        <ButtonLink
          text={formatMessage({ id: 'law-portal.legal.complaince.create.new.planning' })}
          onclick={newPlanCreate}
          to={''}
          className={classes.text}
        />
      )}
      <hr style={{ marginTop: '25px', marginBottom: '25px' }} />
      {newCreate ? (
        <Grid item style={{ marginTop: 20 }}>
          <CreatePlannig
            setNewCreate={setNewCreate}
            newCreate={newCreate}
            getCompliancePlan={getCompliancePlan}
            handleAllControl={handleAllControl}
            isEdit={editData ? true : false}
            planData={duplicatePlanData || editData}
            setDuplicatePlanData={setDuplicatePlanData}
            setEditData={setEditData}
            isDuplicate={duplicatePlanData ? true : false}
          />
        </Grid>
      ) : (
        <>
          <Transition isLoading={planLoading}>
            <ExecuteFilter filters={filters} setFilter={setFilter} search={search} setSearch={(value: any) => setSearch(value)} />
            <hr style={{ marginTop: '25px', marginBottom: "25px" }} />
            {/* <SelectField  options={typeField.map(entityToSelectOption)}  /> */}
             {activeTab=='all'?
             <MTable
             tableRef={tableRef}
             columns={column}
             // data={updateLaws !== "" && updateLaws == planLawsData.id?handleDataFetch:getPlanlaws}
             data={allcontrol}
             components={{
               Toolbar: (props) => <div className={classes.toolbar} style={{ display: 'flex', justifyContent: 'space-between' }}>
                 <div style={{ display: 'flex' }}>
                   <div className={`${classes.tabsize} ${activeTab == "active" ? classes?.tabActive : classes?.tab}`} onClick={() => {
                     setActiveTab('active')
                     setColumn(columnplan)
                   }}>{formatMessage({ id: 'law-portal.legal.planned.law.comliance.check' })}<span className={classes?.tabNumber}>{activePlanList?.length}</span></div>
                    <div className={`${classes.tabsize} ${activeTab == "all" ? classes?.tabActive : classes?.tab}`} onClick={() => { handleAll() }}>{formatMessage({ id: 'law-portal.compliance-law.plan-compliance.all-control' })}<span className={classes?.tabNumber}>{allcontrol?.length}</span></div>
                   <div className={`${classes.tabsize} ${activeTab == "deactive" ? classes?.tabActive : classes?.tab}`} onClick={() => {
                     setActiveTab('deactive')
                     setColumn(columnplan)
                   }}>{formatMessage({ id: 'law-portal.compliance-law.plan-compliance.deactive-control' })}<span className={classes?.tabNumber}>{inActivePlanList?.length}</span></div>
                  
  
                 </div>
                 <MTableToolbar {...props} />
  
               </div>
             }}
             
         
           /> 
            :
           <MTable
           tableRef={tableRef}
           columns={column}
           // data={updateLaws !== "" && updateLaws == planLawsData.id?handleDataFetch:getPlanlaws}
           data={activeTab=="active"?activePlanList:inActivePlanList}
           options={{
            detailPanelColumnAlignment: 'right'
          }}
           components={{
             Toolbar: (props) => <div className={classes.toolbar} style={{ display: 'flex', justifyContent: 'space-between' }}>
               <div style={{ display: 'flex' }}>
                 <div className={`${classes.tabsize} ${activeTab == "active" ? classes?.tabActive : classes?.tab}`} onClick={() => {
                   setActiveTab('active')
                   setColumn(columnplan)
                 }}>{formatMessage({ id: 'law-portal.legal.planned.law.comliance.check' })}<span className={classes?.tabNumber}>{activePlanList?.length}</span></div>
                 <div className={`${classes.tabsize} ${activeTab == "all" ? classes?.tabActive : classes?.tab}`} onClick={() => { handleAll() }}>{formatMessage({ id: 'law-portal.compliance-law.plan-compliance.all-control' })}<span className={classes?.tabNumber}>{allcontrol?.length}</span></div>
                 <div className={`${classes.tabsize} ${activeTab == "deactive" ? classes?.tabActive : classes?.tab}`} onClick={() => {
                   setActiveTab('deactive')
                   setColumn(columnplan)
                 }}>{formatMessage({ id: 'law-portal.compliance-law.plan-compliance.deactive-control' })}<span className={classes?.tabNumber}>{inActivePlanList?.length}</span></div>

               </div>
               <MTableToolbar {...props} />

             </div>
           }}
           
          //  detailPanel={(rowData: any) => {
          //    return <PlanningView
          //      updateLaws={updateLaws}
          //      planLawsData={rowData}
          //      setUpdateLaws={(val: any) => setUpdateLaws(val)}
          //    />
          //  }}
           detailPanel={[
            {
              tooltip: formatMessage({id:"text.expend"}),
              render: (rowData: any) => {
                return <PlanningView
                  updateLaws={updateLaws}
                  planLawsData={rowData}
                  setUpdateLaws={(val: any) => setUpdateLaws(val)}
                />
              },
            }
          ]}

         /> 
            }
            
            {/* <Grid container spacing={4} direction="column" justify="space-evenly">
              {activePlanList?.length > 0 && (
                <Grid container justifyContent="space-between" alignItems="center" style={{ marginRight: 20 }}>
                  <Grid>
                    <Typography
                      variant="h5"
                      style={{ marginTop: 30, marginLeft: 20, color: 'rgba(0, 0, 0, 0.87)', fontWeight: 'inherit' }}
                    >
                      {formatMessage({ id: 'law-portal.legal.planned.law.comliance.check' })}
                    </Typography>
                  </Grid>
                  <Grid>
                    <SearchTextField
                      onChange={searchHandle}
                      value={searchData}
                      clearSearch={() => {
                        setSearchData('');
                        setTableData(activePlanList?.length ? activePlanList : []);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid style={{ display: 'flex', flexDirection: 'column' }} item>
                <ExpansionPanel style={{ padding: "10px", pointerEvents: 'none', background: 'rgb(97, 97, 97)' }}>
                  <ExpansionPanelSummary expandIcon={true} style={{ display: 'flex', gap: '20px', paddingLeft: '0px' }}>
                    <Typography style={{ flex: "20%" }}>{formatMessage({ id: 'law-portal.compliance-law.plan-compliance.name-of-control' })}</Typography>
                    <Typography style={{ flex: "12%" }} >{formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Company-unit' })}</Typography>
                    <Typography style={{ flex: "12%" }} >{formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Start-date' })}</Typography>
                    <Typography style={{ flex: "12%" }} >{formatMessage({ id: 'law-portal.compliance-law.plan-compliance.End-date' })}</Typography>
                    <Typography style={{ flex: "12%" }} >{formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Recuring-interval' })}</Typography>
                    <Typography style={{ flex: "12%" }} >{formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Number-of-controls' })}</Typography>
                    <Typography style={{ flex: "20%" }}>{formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Edit' })}</Typography>
                  </ExpansionPanelSummary>
                </ExpansionPanel>
                {dataTable?.map((val: any, index: number) => {
                  return (
                    <div key={index}>
                      <ExpansionPanel
                        defaultExpanded={false}
                        expanded={expendActivePanel === val.id}
                        onChange={() => handleActivePanelToggle(val.id)}
                      >

                        <ExpansionPanelSummary expandIcon={<ExpandMore />} style={{ display: 'flex', gap: '23px' }}>
                          <Typography style={{ flex: "20%" }}>{formatMessage({ id: 'law-portal.compliance-law.plan-compliance.name-of-control' })}</Typography>
                          <Typography style={{ flex: "12%" }} >{val?.customerCompanyUnitId?.name}</Typography>
                          <Typography style={{ flex: "12%" }} >{moment(val?.startDate).format('YYYY-MM-DD')}</Typography>
                          <Typography style={{ flex: "12%" }} >{moment(val?.endDate).format('YYYY-MM-DD')}</Typography>
                          <Typography style={{ flex: "12%" }} >{val?.recurrentInterval || "-"}</Typography>
                          <Typography style={{ flex: "12%" }} >12</Typography>
                          <Typography style={{ flex: "20%" }}><IconsNode planData={val} /></Typography>
                        </ExpansionPanelSummary>

                        <ExpansionPanelDetails className={classes.panelDetails}>
                          {expendActivePanel === val.id && (
                            <PlanningView
                              updateLaws={updateLaws}
                              planLawsData={val}
                              setUpdateLaws={(val: any) => setUpdateLaws(val)}
                            />
                          )}
                        </ExpansionPanelDetails>
                      </ExpansionPanel> */}
            {/* {editPlanId == val.id ? (
                        <Grid item style={{ marginTop: 20 }}>
                          <CreatePlannig
                            setNewCreate={setNewCreate}
                            getCompliancePlan={getCompliancePlan}
                            isEdit={true}
                            planData={val}
                            setEditPlanID={setEditPlanID}
                          />
                        </Grid>
                      ) : (
                        ''
                      )} */}
            {/* </div>
                  );
                })}
              </Grid>
              {inActivePlanList?.length > 0 && (
                <Grid container justifyContent="space-between" alignItems="center" style={{ marginRight: 20 }}>
                  <Grid>
                    <Typography
                      variant="h5"
                      style={{ marginTop: 30, marginLeft: 20, color: '#000', fontWeight: 'inherit' }}
                    >
                      {formatMessage({ id: 'law-portal.legal.inactive.law.comliance.check' })}
                    </Typography>
                  </Grid>
                  <Grid>
                    <SearchTextField
                      onChange={searchHandleIncative}
                      value={SearchInactiveData}
                      clearSearch={() => {
                        setSearchInactiveData('');
                        setInactiveTable(inActivePlanList?.length ? inActivePlanList : []);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} item>
                {InactiveTable?.map((val: any, index: number) => {
                  return (
                    <div key={index}>
                      <ExpansionPanel
                        defaultExpanded={false}
                        expanded={expendinActivePanel === val.id}
                        onChange={() => handlePanelToggle(val.id)}
                      >
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                          <Grid container justifyContent='space-between' alignItems='center'>
                            <Title title={formatMessage({ id: "default", defaultMessage: `${moment(val.startDate).format("YYYY-MM-DD")} -- ${moment(val.endDate).format("YYYY-MM-DD")} ${val.name}` })} />
                            <Grid>
                              <IconsNode planData={val} />
                            </Grid>
                          </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.panelDetails}>
                          {expendinActivePanel === val.id && (
                            <PlanningView
                              updateLaws={updateLaws}
                              planLawsData={val}
                              setUpdateLaws={(val: any) => setUpdateLaws(val)}
                            />
                          )}
                        </ExpansionPanelDetails>
                      </ExpansionPanel> */}
            {/* {editPlanId == val.id ? (
                        <Grid item style={{ marginTop: 20 }}>
                          <CreatePlannig
                            setNewCreate={setNewCreate}
                            getCompliancePlan={getCompliancePlan}
                            isEdit={true}
                            planData={val}
                            setEditPlanID={setEditPlanID}
                          />
                        </Grid>
                      ) : (
                        ''
                      )} */}
            {/* </div>
                  );
                })}
              </Grid>
            </Grid> */}
          </Transition>
        </>
      )}
    </div>
  );
};
