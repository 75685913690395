import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../../Context/UserContext/UserContext';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CompanyUnitSelectDialog } from './../../../../../Components';

const useStyles = makeStyles(() => ({
  topActionContainer: {
    marginBottom: '1rem',
    display: 'flex',
  },
}));

export const CustomerLawListActions = () => {
  const { hasAccessToFeature, getCompanyUnitName } = useContext(UserContext);
  const canManage = hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);
  const [updateDialogOpen, setUpdateDialogOpen] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const openMassUpdate = () => setUpdateDialogOpen(true);

  const closeMassUpdate = () => setUpdateDialogOpen(false);

  if (!canManage) {
    return null;
  }

  return (
    <Grid container justify="flex-end" className={classes.topActionContainer} spacing={1} direction="row">
      <Grid item>
        <Button onClick={openMassUpdate} variant="outlined">
          <FormattedMessage id="law-portal.manage.law-list.update-laws" />
        </Button>
        <CompanyUnitSelectDialog
          urlTemplate="/organization/law-portal/laws/update-for-company-unit/{id}"
          title={formatMessage({ id: 'law-portal.manage.law-list.update-laws' })}
          selectPlaceholder={formatMessage(
            { id: 'law-portal.manage.update-laws.dialog.companyUnit.placeholder' },
            { alias: getCompanyUnitName(formatMessage, true) },
          )}
          open={updateDialogOpen}
          close={closeMassUpdate}
        />
      </Grid>
      <Grid item>
        <Button component={Link} to="/organization/law-portal/laws/new" variant="outlined">
          <FormattedMessage id="law-portal.manage.law-list.add-law" />
        </Button>
      </Grid>
    </Grid>
  );
};
