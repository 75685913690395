import React from 'react';
import { Grid } from '@material-ui/core';
import { LawSettingsList } from './List';
import { LawSettingsForm } from './Form';
import { Panel } from '../../../../../Components/Panel/Panel';
import { useIntl } from 'react-intl';
import { useLawSettingsState, canDelete } from './State';
import Loading from '../../../../../Components/Loading';
import { DeleteDescription } from './DeleteDescription';

export const LawSettingsCategories = () => {
  const { formatMessage } = useIntl();
  const { items, submit, loading, removeItem, editing, editItem, cancelEdit } = useLawSettingsState('categories', {
    canDelete,
    getDeleteDescription: (item, canDelete) => (
      <DeleteDescription item={item} typeTranslationKey="admin.law-portal.settings.category" canDelete={canDelete} />
    ),
  });

  return (
    <Panel accordion titleKey="admin.law-portal.settings.categories">
      {(loading && <Loading />) || (
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <LawSettingsForm
              id="admin.law-portal.settings.category"
              submit={submit}
              edit={editing}
              cancelEdit={cancelEdit}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <LawSettingsList
              type={formatMessage({ id: 'admin.law-portal.settings.categories' })}
              items={items}
              deleteItem={removeItem}
              onEditItem={editItem}
            />
          </Grid>
        </Grid>
      )}
    </Panel>
  );
};
