import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Form } from '../../../../Components/Form';
import { AptorApi } from '../../../../Api/AptorApi';
import { Container } from '../../../../Components/Container/Container';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAptorApi } from '../../../../Api/useAptorApi';
import { Panel } from '../../../../Components/Panel/Panel';
import { OptionsType } from 'react-select';
import { useFormField } from '../../../../Components/Form/FormField';
import { FormValues, SelectOption } from '../../../../Components/Form/Form.types';
import { BackButton } from '../../../../Components';

interface IAptorUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  language: { value: number; displayName: string; cultureKey: string; resourceKey: string };
}

export const UpdateAptorUserForm = () => {
  const match = useRouteMatch<{ id: string }>();
  const id = parseInt(match.params.id);
  const intl = useIntl();
  const history = useHistory();
  const { api, abortController } = useAptorApi();
  const [availableLanguages, setAvailableLanguages] = useState<OptionsType<SelectOption>>([]);

  const formatMessage = useCallback(
    (id: string) => {
      return intl.formatMessage({ id: id, defaultMessage: id });
    },
    [intl],
  );

  const emailField = useFormField({
    label: formatMessage('form.email'),
    name: 'email',
    type: 'readonly',
    placeholder: formatMessage('form.fillInEmail'),
    required: true,
  });

  const languageField = useFormField({
    label: formatMessage('form.language'),
    name: 'language',
    type: 'single-select',
    placeholder: formatMessage('form.selectLanguage'),
    options: availableLanguages,
    required: true,
  });

  const firstNameField = useFormField({
    label: formatMessage('form.firstName'),
    name: 'firstName',
    type: 'text',
    placeholder: formatMessage('form.fillInFirstName'),
    required: true,
  });

  const lastNameField = useFormField({
    label: formatMessage('form.lastName'),
    name: 'lastName',
    type: 'text',
    placeholder: formatMessage('form.fillInLastName'),
    required: true,
  });

  const initialized = useRef<boolean>(false);

  const setEmail = emailField.setValue;
  const setFirstName = firstNameField.setValue;
  const setLastName = lastNameField.setValue;
  const setLanguage = languageField.setValue;

  useEffect(() => {
    const fetchAvailableLanguages = async () => {
      const result = await api.getLanguages();
      const languages = result.items.map((l) => ({
        value: l.id,
        label: l.name,
      }));

      setAvailableLanguages(languages);
    };

    const fetchUser = async () => {
      const user = await api.getAptorUser<IAptorUser>(id);
      if (abortController.current.signal.aborted) {
        return;
      }

      setEmail({ value: user.email });
      setFirstName({ value: user.firstName });
      setLastName({ value: user.lastName });
      setLanguage({ value: user.language.value, label: user.language.displayName });
    };

    const init = async () => {
      initialized.current = true;

      await fetchAvailableLanguages();
      await fetchUser();
    };

    if (!initialized.current) {
      init();
    }
  }, [id, formatMessage, availableLanguages, api, setEmail, setFirstName, setLastName, setLanguage, abortController]);

  const handleSubmit = async (data: FormValues, api: AptorApi) => {
    await api.updateAdminUser(id, data);
    history.push('/admin/users');
  };

  return (
    <Container>
      <BackButton link="/admin/users" />
      <Panel titleKey="admin.users.invite.editUser">
        <Form
          submit={handleSubmit}
          groups={[
            {
              grid: {
                type: 'column',
                items: [
                  {
                    type: 'row',
                    items: [emailField, languageField],
                  },
                  {
                    type: 'row',
                    items: [firstNameField, lastNameField],
                  },
                ],
              },
            },
          ]}
        />
      </Panel>
    </Container>
  );
};
