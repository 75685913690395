import React, { useContext, useEffect } from 'react';
import { useIntl } from "react-intl";
// import { useStyles } from "./Administration.styles";
import { ModuleContext } from "../../../../Context/ModuleContext/ModuleContext";
import { useFormField } from "../../../../Components/Form/FormField";
import { entityToSelectOption } from "../../../../Utilities";
import { FormValues } from "../../../../Components/Form/Form.types";
import { AptorApi } from "../../../../Api";
import { Container, Form, Panel } from "../../../../Components";
import { Typography } from "@material-ui/core";
// import { SupervisedUserCircle } from "@material-ui/icons";
export const Administration = () => {
  const { formatMessage } = useIntl();
  const { setHeader } = useContext(ModuleContext);
  // const classes = useStyles();

  useEffect(() => {
    setHeader(formatMessage({ id: 'component.home.administration', defaultMessage: 'Administration' }));
  }, [setHeader, formatMessage]);
  let companyUnits = [{
    id: 1,
    name: 'companyUnit1'
  }]
  // let value: any = [{
  //   value: '1',
  //   label: 'companyUnit1',
  // }]

  const organisation = useFormField({
    type: 'single-select-card',
    name: 'manageOrganizationAccessLevel',
    required: true,
    label: formatMessage({ id: 'organization.users.permissions.manageOrganization' }),
    options: companyUnits.map(entityToSelectOption),
    // initialState: value,
    icon: 'business',
  });

  const chooseCompanyUnit = useFormField({
    type: 'single-select-card',
    name: 'manageLawsAccessLevel',
    required: true,
    label: formatMessage({ id: 'organization.users.permissions.manageLaws' }),
    options: companyUnits.map(entityToSelectOption),
    // initialState: value,
    icon: 'gavel',
  });
  const statisticsField = useFormField({
    label: formatMessage({ id: 'organization.users.permissions.statistics' }),
    icon: 'bar_chart',
    name: 'statisticsAccessLevel',
    type: 'single-select-card',
    options: companyUnits.map(entityToSelectOption),
    required: true,
    // initialState: value,
  });
  const lawsField = useFormField({
    label: formatMessage({ id: 'organization.users.permissions.laws' }),
    icon: 'gavel',
    name: 'lawsAccessLevel',
    type: 'single-select-card',
    options: companyUnits.map(entityToSelectOption),
    required: true,
    // initialState: value,
  });
  const invitationsField = useFormField({
    label: formatMessage({ id: 'organization.users.permissions.invitations' }),
    icon: 'settings',
    name: 'invitationsAccessLevel',
    type: 'single-select-card',
    options: companyUnits.map(entityToSelectOption),
    required: true,
    // initialState: value,
  });
  const otherField = useFormField({
    label: formatMessage({ id: 'organization.users.permissions.other' }),
    icon: 'format_list_bulleted',
    name: 'otherAccessLevel',
    type: 'single-select-card',
    options: companyUnits.map(entityToSelectOption),
    required: true,
    // initialState: value,
  });
  const handleSubmit = async (data: FormValues, api: AptorApi, onSuccess: (key: string) => void) => {
    console.log('submit');
  };
  return (
    <Container>
      <Panel>
        <Typography style={{ fontWeight: "bolder" }}>{formatMessage({ id: 'law-portal.administration.permission.text' })}</Typography>
        <Form
          submit={handleSubmit}
          submitLabel={formatMessage({ id: 'form.send' })}
          cancelLabel={formatMessage({ id: 'form.send' })}
          groups={[
            {
              grid: {
                type: 'column',
                items: [
                  { type: 'row', items: [organisation, chooseCompanyUnit] },
                  { type: 'row', items: [lawsField, otherField] },
                  { type: 'row', items: [statisticsField, invitationsField] }
                ],
              },
            },
          ]}
        />
      </Panel>
    </Container>
  );
};
