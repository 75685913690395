import React from 'react';
import ReactSelectAsync, { Props } from 'react-select/async';
import { styles } from './Select.styles';
import debounce from 'debounce-promise';
import { SelectOption } from '../Form/Form.types';

function SelectAsync(props: Props<SelectOption>) {
  const { loadOptions, ...rest } = props;
  const loadOptionsDebounced = debounce(loadOptions, 250);
  return (
    <ReactSelectAsync
      loadOptions={loadOptionsDebounced}
      styles={styles}
      {...rest}
      noOptionsMessage={() => 'Inga alternativ'}
    />
  );
}

export default SelectAsync;
