import React, { useCallback, useContext, useEffect,useState } from 'react';
import MTable from '../../../../Components/MTable/MTable';
import { useIntl } from 'react-intl';
import { useAptorApi } from '../../../../Api';
import { MTableToolbar, Query } from 'material-table';
import { IGridQueryRequest, OrderDirection } from '../../../../Api/AptorApi';
import { ChangeFilter } from './Filters';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useIsMounted } from '../../../../Hooks/useIsMounted';
import moment from 'moment';
import { useFilter } from '../../../../Components/ListFilter';
import { useStyles } from "../../LawPortal.styles";
import {Button,Tooltip} from '@material-ui/core'
import {ArrowUpward, SaveAlt} from '@material-ui/icons'
import { ModuleContext } from "../../../../Context/ModuleContext/ModuleContext";
type AcknowledgedChange = {
  id: number;
  number: string;
  effectiveFrom: Date;
  lawId: number;
  type: 'Law' | 'Requirement';
  name: string;
  userId: number;
  userName: string;
  acknowledgedAt: Date;
  comment: string | null;
  affectsBusiness:boolean;
};

export const AcknowledgedChanges = () => {
  const { formatMessage } = useIntl();
  const { api } = useAptorApi();
  const [filters, setFilter] = useFilter();
  const tableRef = React.createRef();
  const isMounted = useIsMounted();
  const classes=useStyles()
  const { setHeader } = useContext(ModuleContext);
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const history = useHistory()
  useEffect(() => {
    if (isMounted) {
      tableRef.current && (tableRef.current as any).onQueryChange();
    }
  }, [filters, tableRef, isMounted]);

  useEffect(() => {
    const queryParamsMap= {
      companyUnit: 'companyUnit',
      complianceUsers: 'complianceUsers',
      category: 'category',
      area: 'area',
    };
    Object.entries(queryParamsMap).forEach(([queryKey, stateKey]) => {
      const queryValues = queryParams.getAll(queryKey);
        if (queryValues.length) {
          setFilter(stateKey, queryValues.join(',').split(',').map(num => num.toString()));
        }
    });
  }, []);

  useEffect(() => {
    setHeader(formatMessage({ id: 'law-portal.our-laws.header', defaultMessage: 'Our laws' }), [
      {
        title: formatMessage({ id: 'law-portal.our-law.tab.law-list' }),
        to: `/law-portal/our-laws`,
      },
      {
        title: formatMessage({ id: 'law-portal.statistics.sub-menu.changes.unacknowledged' }),
        to: `/law-portal/our-laws/unacknowledged`,
      },
      {
        title: formatMessage({ id: 'law-portal.statistics.sub-menu.changes.acknowledged' }),
        to: `/law-portal/our-laws/acknowledged`,
      },

    ]);
  }, [setHeader, formatMessage]);

  const handleDataFetch = useCallback(
    async (query: Query<AcknowledgedChange>) => {
      return await api.searchlawAcknowledgedChanges<AcknowledgedChange>({
        orderDirection: query.orderDirection !== 'asc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'acknowledgedAt',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest);
    },
    [api, filters],
  );

  const renderDate = (date?: Date) => {
    return date ? moment(date).format('ll') : undefined;
  };
  const [showScrollTop, setShowScrollTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    const updatedQueryParams = Object.keys(filters)
      .filter(key => {
        const value = filters[key];
        if (Array.isArray(value)) {
          return value.join() !== "";
        } else if (typeof value === 'object' && value !== null) {
          return value.min !== "" && value.max !== "";
        }
        return false;
      })
      .map(key => {
        const value = filters[key];
        if (Array.isArray(value)) {
          return `${key}=${encodeURIComponent(value.join())}`;
        } 
        // else if (typeof value === 'object' && value !== null) {
        //   return `${key}[min]=${encodeURIComponent(value.min)}&${key}[max]=${encodeURIComponent(value.max)}`;
        // }
      })
      .join('&');

    history.push({
      pathname: '',
      search: `?${updatedQueryParams}`,
    });
  }, [filters, history]);

  const handleExport = async () => {
    const response = await api.exportlawAcknowledgedChanges()
    const blob = await response?.blob();
    const contentDisposition = response.headers.get('Content-Disposition'); 
    let fileName = 'LawsExport.xlsx'; 
    if (contentDisposition && contentDisposition.includes('attachment')) { 
      const matches = /filename=([^;]*)/.exec(contentDisposition);
      if (matches && matches[1]) {
         fileName = matches[1]; 
      } 
    }
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); // or whatever you want the file name to be
    document.body.appendChild(link);
    link.click();
  }
  return (
    <>
      <ChangeFilter filters={filters} setFilter={setFilter} />
      <hr style={{ marginTop: '25px', marginBottom: '25px' }} />
      <MTable
        tableRef={tableRef}
        columns={[
          {
            title: formatMessage({ id: 'law-portal.our-laws.table.law' }),
            field: 'name',
          },
          {
            title: formatMessage({ id: 'law-portal.statistics.table.number' }),
            field: 'number',
            render: (row) => (
            <>
              <Link
                to={`/law-portal/our-laws/${row.lawId}/changes`}
              >
                {row.number}
              </Link>
            </>
            ),
          },
          {
            title: formatMessage({ id: 'law-portal.statistics.table.effectiveFrom' }),
            field: 'effectiveFrom',
            render: (row) => renderDate(row.effectiveFrom),
          },
          {
            title: formatMessage({ id: 'law-portal.statistics.table.user' }),
            field: 'userName',
          },
          {
            title: formatMessage({ id: 'law-portal.statistics.table.acknowledgedAt' }),
            field: 'acknowledgedAt',
            render: (row) => renderDate(row.acknowledgedAt),
          },
          {
            title: formatMessage({ id: 'law-portal.statistics.table.affectbussiness' }),
            field: 'affectsBusiness',
            render: (row:any) => row?.affectsBusiness==null?"-":row?.affectsBusiness?formatMessage({id:"law-portal.our-law.ack.latest-change.checkbox.text.affect"}):formatMessage({id:"law-portal.our-law.ack.latest-change.checkbox.text.not_affect"}),
          },

          {
            title: formatMessage({ id: 'law-portal.statistics.table.comment' }),
            field: 'comment',
            render: (row) => (row.comment ? row.comment : ''),
          },
        ]}
        data={handleDataFetch}
        components={{
          Toolbar: (props) => <div className={classes.toolbar}>
            <MTableToolbar {...props} />
            <SaveAlt style={{ cursor: 'pointer' }} onClick={() => handleExport()} />
          </div>
        }}
      />
      {showScrollTop && (
        <Tooltip title={formatMessage({id:"bottom.to.top.btn.tooltip.text",defaultMessage:"Back to top"})}>
          <Button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className={classes.topButton}
          >
            <ArrowUpward style={{ fontSize: 20 }} />
          </Button>
        </Tooltip>
      )}
    </>
  );
};
