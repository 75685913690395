import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { AptorApi, useAptorApi, useFetchSelectOptions } from '../../../../Api';
import { Loading } from '../../../../Components';
import { FormValues } from '../../../../Components/Form/Form.types';
import { CreateCustomerForm } from './CreateCustomer.Form';

export const CreateCustomer = () => {
  const history = useHistory();
  const { api, abortController } = useAptorApi();

  const [languages] = useFetchSelectOptions(
    useCallback(() => api.getLanguages(), [api]),
    abortController,
  );

  const [features] = useFetchSelectOptions(
    useCallback(() => api.newCustomer(), [api]),
    abortController,
  );

  const [countries] = useFetchSelectOptions(
    useCallback(() => api.getCountries(), [api]),
    abortController,
  );

  const handleSubmit = async (data: FormValues, api: AptorApi, onSuccess: (key: string) => void) => {
    await api.createCustomer(data);
    onSuccess('admin.customers.new.savedCustomer');
    history.push('/admin/customers');
  };

  if (features.length === 0 || languages.length === 0 || countries.length === 0) {
    return <Loading />;
  }

  return <CreateCustomerForm features={features} countries={countries} languages={languages} submit={handleSubmit} />;
};
