import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 'auto',
    '& > div': {
      [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
      },
    },
  },
  user: {
    backgroundColor: theme.palette.grey[200],
    width: 'fit-content',
    marginLeft: theme.spacing(2),
    '& p': {
      paddingLeft: theme.spacing(2),
    },
  },
  mobile: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      '& p': {
        fontSize: '1rem',
      },
    },
  },
}));
