import React, {
  useContext,
  // useContext,
  useEffect, useState
} from 'react'
import { ReportFilter } from "./ReportFIlter"
import { useFilter } from "../../../../../Components/ListFilter";
import {
  useHistory,
  // Link,
  useLocation
} from "react-router-dom";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  // Input,
  //  Theme,
  // createStyles
} from "@material-ui/core";
// import { MTable, Panel } from "../../../../../Components";
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useIntl } from "react-intl";
import { useAptorApi } from "../../../../../Api";
import { AccessLevel, LawPortalFeature, UserContext } from "../../../../../Context/UserContext/UserContext";
import moment from "moment";
import { ExpandMore } from "@material-ui/icons";
import { Title } from "../../../../../Components/Panel/Panel";
import ReportTableView from "./ReportTableView";
import { useStyles } from "../../../LawPortal.styles";
import { MTable, Transition } from '../../../../../Components';
import { MTableToolbar } from 'material-table';
// import async from "react-select/async";

type CompliancecReport = {
  id: number;
  requirementId: number;
  requirementName: string;
  effectiveFrom: Date;
  customerCompanyUnitId: number;
  customerCompanyUnit: string;
  userId: number;
  userName: string;
  controlDate: Date;
  unplanned: boolean;
};

export const ReportsLK = () => {
  const [filters, setFilter] = useFilter();
  const [search, setSearch] = useState("")
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { complianceUserFor, hasAccessToFeature, getCompanyUnitName } = useContext(UserContext);
  const { api } = useAptorApi();
  const { formatMessage } = useIntl();
  const [expendPlanPanel, setExpendPlanPanel] = useState('');
  const [expendDirectPlanPanel, setExpendDirectPlanPanel] = useState('');
  const [isDirectPLan, setIsDirectPLan] = useState([])
  const [isInDirectPLan, setIsInDirectPLan] = useState([])
  const classes = useStyles()
  const history = useHistory()
  const { customerId } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState<any>('active')
  const [allReport, setAllReport] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const queryParamsMap = {
      tags: 'tags',
      companyUnit: 'companyUnit',
      complianceUsers: 'complianceUsers',
      processes: 'processes',
      aspects: 'aspects',
      category: 'category',
      area: 'area',
    };
    const searchQuery = queryParams.getAll('search')
    if (searchQuery.length) {
      setSearch(searchQuery[0])
    }
    Object.entries(queryParamsMap).forEach(([queryKey, stateKey]) => {
      const queryValues = queryParams.getAll(queryKey);
      if (queryValues.length) {
        setFilter(stateKey, queryValues.join(',').split(',').map(num => num.toString()));
      }
    });
  }, []);



  // const canDownloadReport = (row: CompliancecReport) => {
  //   return (
  //     canDownloadAllReports ||
  //     complianceUserFor.laws.some(
  //       (x) => x.customerCompanyUnitId === row.customerCompanyUnitId && x.id === row.requirementId,
  //     )
  //   );
  // };

  // const renderDownloadAction = (row: CompliancecReport) => {
  //   return {
  //     tooltip: formatMessage({ id: 'law-portal.statistics.table.download-compliance' }),
  //     icon: 'picture_as_pdf',
  //     onClick: () => {
  //       api.downloadRequirementComplianceReport(row.requirementId, row.id);
  //     },
  //     disabled: !canDownloadReport(row),
  //   };
  // };
  // const renderDate = (date?: Date) => {
  //   return date ? moment(date).format('ll') : undefined;
  // };
  useEffect(() => {
    getReportPlan()
  }, [filters, search])
  const getReportPlan = async () => {
    setIsLoading(true);
    const response = await api.getReportPlan({ filters: filters, search: search })
    const direactPlan = response?.plans?.filter((val: any) => val.isDirect)
    const inDireactPlan = response?.plans?.filter((val: any) => !val.isDirect)
    direactPlan?.sort((a: any, b: any) => {
      const startDateComparison = new Date(b.startDate).getTime() as number - new Date(a.startDate).getTime() as number
      if (startDateComparison === 0) {
        return a.name.localeCompare(b.name);
      }

      return startDateComparison;
    });
    inDireactPlan?.sort((a: any, b: any) => {
      const startDateComparison = new Date(b.startDate).getTime() as number - new Date(a.startDate).getTime() as number
      if (startDateComparison === 0) {
        return a.name.localeCompare(b.name);
      }

      return startDateComparison;
    });
    setIsDirectPLan(direactPlan)
    setIsInDirectPLan(inDireactPlan)
    setIsLoading(false);
  }
  const handlePlanPanelToggle = (panelId: string) => {
    if (expendPlanPanel === panelId) {
      setExpendPlanPanel('');
    } else {
      setExpendPlanPanel(panelId);
    }
  };
  const handleDireactPlanPanelToggle = (panelId: string) => {
    if (expendDirectPlanPanel === panelId) {
      setExpendDirectPlanPanel('');
    } else {
      setExpendDirectPlanPanel(panelId);
    }
  };
  useEffect(() => {
    setExpendDirectPlanPanel("")
    setExpendPlanPanel("")
  }, [filters, search])

  useEffect(() => {
    var updatedQueryParams = Object.keys(filters)
      .filter(key => filters[key]?.join() !== undefined && filters[key]?.join() !== "")
      .map(key => `${key}=${encodeURIComponent(filters[key]?.join())}`)
      .join('&');
    if (search !== "") {
      updatedQueryParams = `${updatedQueryParams}&search=${search}`
    }
    history.push({
      pathname: '',
      search: `?${updatedQueryParams}`,
    });
  }, [filters, history, search]);
  const columnplan = [
    { title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.name-of-control' }), field: 'name' },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Company-unit' }),
      field: 'companyUnit',
    },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Start-date' }),
      field: 'startDate',
      render: (row: any) => moment(row?.startDate).format('YYYY-MM-DD'),
      sorting: false,
    },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.End-date' }), field: 'endDate',
      render: (row: any) => moment(row?.endDate).format('YYYY-MM-DD'), sorting: false,
    },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Recuring-interval' }),
      field: 'recurrentInterval',
      render: (row: any) => row?.recurrentInterval || "-",
      sorting: false,
    },
    {
      title: formatMessage({
        id: 'utils.filters.status',
      }),
      field: 'status',
      render: (row: any) => `${row.executedLawCount} ${formatMessage({ id: 'text.of' })} ${row.lawCount} ${formatMessage({ id: 'status.text' })} `,
      sorting: false,
    },
  ]
  const columnunplan = [
    { title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.name-of-control' }), field: 'name' },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Company-unit' }),
      field: 'companyUnit',
    },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Start-date' }),
      field: 'startDate',
      render: (row: any) => moment(row?.startDate).format('YYYY-MM-DD'),
      sorting: false,
    },
    // {
    //   title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.End-date' }), field: 'endDate',
    //   render: (row: any) => moment(row?.endDate).format('YYYY-MM-DD'), sorting: false,
    // },
    // {
    //   title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Recuring-interval' }),
    //   field: 'recurrentInterval',
    //   render: (row: any) => row?.recurrentInterval || "-",
    //   sorting: false,
    // },
    {
      title: formatMessage({
        id: 'utils.filters.status',
      }),
      field: 'status',
      render: (row: any) => `${row.executedLawCount} ${formatMessage({ id: 'text.of' })} ${row.lawCount} ${formatMessage({ id: 'status.text' })} `,
      sorting: false,
    },
  ]
  const allplan: any = [
    {
      title: formatMessage({ id: 'law-portal.our-laws.table.law' }),
      field: 'name',
      sorting: false,

    },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Company-unit' }),
      field: 'companyUnit',
      sorting: false
    },
    {
      title: formatMessage({ id: 'law-portal.manage.update-laws.type.options.complianceUser' }),
      field: 'startDate',
      render: (row: any) => moment(row?.startDate).format('YYYY-MM-DD'),
      sorting: false
    },
    {
      title: formatMessage({ id: 'law-portal.statistics.table.control-date' }), field: 'endDate',
      render: (row: any) => moment(row?.endDate).format('YYYY-MM-DD'), sorting: false
    },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.execute.fulfilment' }),
      field: 'fulfilment',
      render: (row: any) => <div>{parseFloat(row?.fulfilment).toFixed(1)}%</div>,
      sorting: false
    },
    {
      title: formatMessage({ id: 'law-portal.compliance.report.table.col' }),
      field: 'responsible',
      render: (row: any) => {
        if (row.responsible) {
          const responsiblearr = row?.responsible?.split(',')
          return (
            <>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {responsiblearr?.map((val: any) => {
                  return <span>{val}</span>
                })}
              </div>
            </>
          )
        } else {
          return "-"
        }
      },
      sorting: false
    },
  ]
  const tableRef = React.createRef();
  const [column, setColumn] = useState(columnplan)
  const handleAll = () => {
    setColumn(allplan)
    setActiveTab('all')
  }
  const canDownloadAllReports = hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);
  const canDownloadReport = (row: any) => {
    return (
      canDownloadAllReports ||
      complianceUserFor.laws.some(
        (x) => x.customerCompanyUnitId === row.customerCompanyUnitId && x.id === row.id,
      )
      // complianceUserFor.laws.some(
      //     (x) => x.customerCompanyUnitId === row.customerCompanyUnitId && x.id === row.requirementId,
      // )
    );
  };
  const renderDownloadAction = (row: any) => {
    return {
      tooltip: formatMessage({ id: 'law-portal.statistics.table.download-compliance' }),
      icon: 'picture_as_pdf',
      onClick: () => {
        row.type === 0 ? api.downloadLawComplianceReport(row.id, row.reportId) : api.downloadRequirementComplianceReport(row.id, row.reportId)
      },
      disabled: !canDownloadReport(row),
    };
  };

  const getallControReport = async () => {
    const res: any = await api.getAllControlReport(Number(customerId), {
      filters: filters,
      search: search
    })
    setAllReport(res)
  }
  useEffect(() => {
    getallControReport()
  }, [filters, search])
  return (
    <div className={classes?.listWrapper}>
      <ReportFilter filters={filters} setFilter={setFilter} search={search} setSearch={(value: any) => setSearch(value)} />
      <hr style={{ marginTop: '25px', marginBottom: "25px" }} />
      <Transition isLoading={isLoading}>
        <div>
          {activeTab == 'all' ?
            <MTable
              tableRef={tableRef}
              columns={column}
              // data={updateLaws !== "" && updateLaws == planLawsData.id?handleDataFetch:getPlanlaws}
              data={allReport}
              components={{
                Toolbar: (props) => <div className={classes.toolbar} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex' }}>
                    <div className={`${classes.tabsize} ${activeTab == "active" ? classes?.tabActive : classes?.tab}`} onClick={() => {
                      setActiveTab('active')
                      setColumn(columnplan)
                    }}>{formatMessage({ id: "law-portal.compliance.repporter.heading.plan" })}<span className={classes?.tabNumber}>{isInDirectPLan?.length}</span></div>
                    <div className={`${classes.tabsize} ${activeTab == "deactive" ? classes?.tabActive : classes?.tab}`} onClick={() => {
                      setActiveTab('deactive')
                      setColumn(columnunplan)
                    }}>{formatMessage({ id: "law-portal.compliance.repporter.heading.direct" })}<span className={classes?.tabNumber}>{isDirectPLan?.length}</span></div>
                    <div className={`${classes.tabsize} ${activeTab == "all" ? classes?.tabActive : classes?.tab}`} onClick={() => { handleAll() }}>{formatMessage({ id: 'law-portal.compliance-law.plan-compliance.all-control' })}<span className={classes?.tabNumber}>{allReport?.length}</span></div>

                  </div>
                  <MTableToolbar {...props} />

                </div>
              }}
              actions={[renderDownloadAction]}
            />

            : <MTable
              tableRef={tableRef}
              columns={column}
              // data={updateLaws !== "" && updateLaws == planLawsData.id?handleDataFetch:getPlanlaws}
              data={activeTab == "active" ? isInDirectPLan : isDirectPLan}
              options={{
                detailPanelColumnAlignment: 'right'
              }}
              components={{
                Toolbar: (props) => <div className={classes.toolbar} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex' }}>
                    <div className={`${classes.tabsize} ${activeTab == "active" ? classes?.tabActive : classes?.tab}`} onClick={() => {
                      setActiveTab('active')
                      setColumn(columnplan)
                    }}>{formatMessage({ id: "law-portal.compliance.repporter.heading.plan" })}<span className={classes?.tabNumber}>{isInDirectPLan?.length}</span></div>
                    <div className={`${classes.tabsize} ${activeTab == "deactive" ? classes?.tabActive : classes?.tab}`} onClick={() => {
                      setActiveTab('deactive')
                      setColumn(columnunplan)
                    }}>{formatMessage({ id: "law-portal.compliance.repporter.heading.direct" })}<span className={classes?.tabNumber}>{isDirectPLan?.length}</span></div>
                    <div className={`${classes.tabsize} ${activeTab == "all" ? classes?.tabActive : classes?.tab}`} onClick={() => { handleAll() }}>{formatMessage({ id: 'law-portal.compliance-law.plan-compliance.all-control' })}<span className={classes?.tabNumber}>{allReport?.length}</span></div>

                  </div>
                  <MTableToolbar {...props} />

                </div>
              }}
              detailPanel={[
                {
                  tooltip: formatMessage({ id: "text.expend" }),
                  render: (rowData: any) => {
                    return <ReportTableView filter={filters} search={search} planId={rowData.id} plan={rowData} />
                  },
                }
              ]}
            // detailPanel={(rowData: any) => {
            //   return <ReportTableView filter={filters} search={search} planId={rowData.id} plan={rowData} />
            // }}


            />

          }
          {/* <h2>{formatMessage({ id: "law-portal.compliance.repporter.heading.plan" })}</h2>

        <Grid style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} item>
          {isInDirectPLan?.map((val: any, index: any) => {

            return (
              <div key={index}>
                <ExpansionPanel
                  defaultExpanded={false}
                  expanded={expendPlanPanel === val.id}
                  onChange={() => handlePlanPanelToggle(val.id)}
                >
                  <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <Grid container justifyContent='space-between' alignItems='center'>
                      <Title title={formatMessage({ id: "default", defaultMessage: `${moment(val.startDate).format("YYYY-MM-DD")} -- ${moment(val.endDate).format("YYYY-MM-DD")} ${val.name}` })} />
                    </Grid>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.panelDetails}>
                    <h3 style={{ margin: 0 }}>{`${formatMessage({ id: 'utils.filters.status' })}: ${val?.executedLawCount} ${formatMessage({ id: "text.of" })} ${val?.lawCount} ${formatMessage({ id: "status.text" })} `}</h3>
                    {expendPlanPanel === val.id && (
                      <ReportTableView filter={filters} search={search} planId={val.id} plan={val} />
                    )}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            )
          })}
        </Grid>
      </div>
      <hr style={{ marginTop: '25px', marginBottom: "25px" }} />
      <div>
        <h2>{formatMessage({ id: "law-portal.compliance.repporter.heading.direct" })}</h2>

        <Grid style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} item>
          {isDirectPLan?.map((val: any, index: any) => {

            return (
              <div key={index}>
                <ExpansionPanel
                  defaultExpanded={false}
                  expanded={expendDirectPlanPanel === val.id}
                  onChange={() => handleDireactPlanPanelToggle(val.id)}
                >
                  <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <Grid container justifyContent='space-between' alignItems='center'>
                      <Title title={formatMessage({ id: "default", defaultMessage: `${moment(val.startDate).format("YYYY-MM-DD")} -- ${moment(val.endDate).format("YYYY-MM-DD")} ${val.name}` })} />
                    </Grid>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.panelDetails}>
                    <h3 style={{ margin: 0 }}>{`${formatMessage({ id: 'utils.filters.status' })}: ${val?.executedLawCount} ${formatMessage({ id: "text.of" })} ${val?.lawCount} ${formatMessage({ id: "status.text" })} `}</h3>
                    {expendDirectPlanPanel === val.id && (
                      <ReportTableView filter={filters} search={search} planId={val.id} plan={val} />
                    )}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            )
          })}
        </Grid> */}
        </div>
      </Transition>
    </div>
  )
}
