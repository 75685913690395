import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  list: {
    '& > .MuiCard-root + .MuiCard-root': {
      marginTop: theme.spacing(2), //Add margin when two groups are next to each other
    },
    '& .MuiCard-root + .MuiListItem-container': {
      border: 'none', //Remove border-top when item follows a group
    },
    marginBottom: theme.spacing(2),
  },
  outsideInterval: {
    '& button': {
      color: theme.palette.grey[400],
    },
  },
  warningText: {
    color: theme.palette.warning.dark,
  },
}));
