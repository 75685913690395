import React, { useContext } from 'react';
import { Button, Hidden } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import { FormattedMessage } from 'react-intl';
import { UserContext } from '../../Context/UserContext/UserContext';
export const ChangeLocaleButton = () => {
  const user = useContext(UserContext);
  const changeLocale = () => {
    if (user.locale === 'sv') {
      user.changeLocale('en');
    } else {
      user.changeLocale('sv');
    }
  };
  return (
    <>
      <Button variant="text" color="default" onClick={changeLocale} startIcon={<LanguageIcon />}>
        <Hidden only="xs">
          <FormattedMessage
            id="component.change-locale-button.changeLanguage"
            defaultMessage="Change language"
            description="Label for Change language-button"
          />
        </Hidden>
        <Hidden smUp>
          <FormattedMessage id="component.home.language" defaultMessage="EN" description="Current language" />
        </Hidden>
      </Button>
    </>
  );
};
