import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { AptorAdminSidebar } from './Components/AptorAdminSidebar';
import { LawPortal } from './Features/LawPortal';
import { Customers } from './Features/Customers/Customers';
import { AptorUsers } from './Features/Users/AptorUsers';
import { Audit } from './Features/Audit';
import { useStyles } from '../Module.styles';
import { ModuleContext, useModule } from '../../Context/ModuleContext/ModuleContext';
import { Header } from '../../Components';

export const AptorAdminModule = () => {
  const classes = useStyles();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const handleSidebarToggle = useCallback((open: boolean) => {
    setSidebarOpen(open);
  }, []);
  const { Provider } = ModuleContext;
  const module = useModule();

  return (
    <Grid container>
      <Grid item>
        <AptorAdminSidebar sidebarOpen={sidebarOpen} handleSidebarToggle={handleSidebarToggle} />
      </Grid>
      <Grid
        item
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarOpen,
        })}
      >
        <Provider value={module}>
          <Header
            sidebarOpen={sidebarOpen}
            handleSidebarToggle={handleSidebarToggle}
            title={module.header}
            subMenu={module.subMenu}
          />
          <Switch>
            <Route path="/admin/law-portal" component={LawPortal} />
            <Route path="/admin/customers" component={Customers} />
            <Route path="/admin/users" component={AptorUsers} />
            <Route path="/admin/audit" component={Audit} />
            <Route path="/admin">
              <Redirect to="/admin/law-portal" />
            </Route>
          </Switch>
        </Provider>
      </Grid>
    </Grid>
  );
};
export default AptorAdminModule;
