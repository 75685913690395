import React, { useCallback, useContext, useEffect } from 'react'
import { Container, HtmlView, MTable, RepealableName } from '../../../../../../Components'
import { useIntl } from 'react-intl'
import { useAptorApi } from '../../../../../../Api';
import { useFilter } from '../../../../../../Components/ListFilter';
import { useHistory } from 'react-router-dom';
// import moment from 'moment';
import { Query } from 'material-table';
import { Checkbox } from '@material-ui/core';
import { ComplianceFilter } from "./ComplianceFilter";
import { useComplianceState } from "./ComplianceState";
import { IGridQueryRequest, OrderDirection } from "../../../../../../Api/AptorApi";
import { useStyles } from '../../../../LawPortal.styles';
import { ModuleContext } from '../../../../../../Context/ModuleContext/ModuleContext';
import { RequirementListFilter } from "./RequirementListFilter";
import { OwnNotes } from "../../../../../../Components/OwnNotes";
import moment from "moment";
import { Module, UserContext } from "../../../../../../Context/UserContext/UserContext";
type LawData = {
  id: number;
  number: string;
  name: string;
  changeEffectiveFrom?: Date;
  changeNumber?: string;
  effectiveTo?: Date;
  tags?: string;
};
interface IProps {
  selectedRows: any
  setSelectedRows: any
  type: any
}
export const ComplianceTable = ({ selectedRows, setSelectedRows, type }: IProps) => {
  const { formatMessage } = useIntl();
  const { api } = useAptorApi();
  const [filters, setFilter] = useFilter();
  const tableRef = React.createRef();
  const history = useHistory();
  const classes = useStyles();
  const {
    enableCompliance,
    isComplianceUserFor,
    isComplianceUserForreq
  } = useComplianceState();
  const { hasAccessToModule} = useContext(UserContext);
  useEffect(() => {
    tableRef.current && (tableRef.current as any).onQueryChange();
  }, [filters, tableRef]);
  const { setHeader } = useContext(ModuleContext);
  useEffect(() => {
    setHeader(formatMessage({ id: 'law-portal.legal.compliance.header', defaultMessage: 'Legal compliance control' }), [
      {
        title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.planning.lek' }),
        to: `/law-portal/legal-compliance-control/planning`,
      },
      {
        title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.carry.lek' }),
        to: `/law-portal/legal-compliance-control/carry`,
      },
      {
        title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.reports.lek' }),
        to: `/law-portal/legal-compliance-control/reports`,
      },
    ]);
  }, [setHeader, formatMessage]);
  function handleRowSelect(id: any) {

    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId: any) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  }
  const handleLawDataFetch = useCallback(
    async (query: Query<LawData>) => {
      return await api.searchLawPortalLaws<LawData>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'number',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest);
    },
    [api, filters],
  );
  const handleRequirementDataFetch = useCallback(
    async (query: Query<LawData>) => {
      return await api.searchLawPortalRequirements<LawData>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'name',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest);
    },
    [api, filters],
  );
  // const renderDate = (date?: Date) => {
  //   return date ? moment(date).format('ll') : undefined;
  // };
  // useEffect(() => {
  //   const updatedQueryParams = Object.keys(filters)
  //     .filter(key => filters[key]?.join() !== undefined && filters[key]?.join() !== "")
  //     .map(key => `${key}=${encodeURIComponent(filters[key]?.join())}`)
  //     .join('&');
  //   history.push({
  //     pathname: '',
  //     search: `?${updatedQueryParams}`,
  //   });
  // }, [filters, history]);

  const renderDate = (date?: Date) => {
    return date ? moment(date).format('ll') : undefined;
};
  return (
    <div className={classes.listWrapper}>
      <Container fullWidth>
        {type?.value === 0||type?.id===0?
          <ComplianceFilter setFilter={setFilter} filters={filters} >
            {/* <ComplianceActions
          complianceEnabled={complianceEnabled}
          enableCompliance={enableCompliance}
          disableCompliance={disableCompliance}
          hasCompliance={hasCompliance}
          startComplianceSession={startComplianceSession}
        /> */}
          </ComplianceFilter> :
          <RequirementListFilter setFilter={setFilter} filters={filters} />
        }
        {/* <Grid container justify='flex-end' alignItems='center' style={{ marginTop: 20 }}><Button onClick={() => addLaws()} disabled={selectedRows.length > 0 ? false : true} color="primary" variant="outlined">{formatMessage({ id: 'law-portal.legal.plane.save' })}</Button></Grid> */}
        <MTable
          tableRef={tableRef}
          // columns={[
          //   { title: formatMessage({ id: 'admin.law-portal.table.number' }), field: 'number' },
          //   {
          //     title: formatMessage({ id: 'admin.law-portal.table.law' }),
          //     field: 'name',
          //     render: (row) => <RepealableName name={row.name} effectiveTo={row.effectiveTo} />,
          //   },
          //   {
          //     title: formatMessage({ id: 'law-portal.our-laws.table.description' }),
          //     field: 'description',
          //     render: (row: any) => <HtmlView variant="body2" value={row.description} />,
          //     sorting: false,
          //   },
          //   { title: formatMessage({ id: 'law-portal.our-laws.table.area' }), field: 'area' },
          //   {
          //     title: formatMessage({ id: 'law-portal.our-laws.table.notes' }),
          //     field: 'notes',
          //     sorting: false,
          //   },
          // ]}
          columns={type?.value === 0||type?.id===0 ? [
            { title: formatMessage({ id: 'law-portal.our-laws.table.number' }), field: 'number' },
            {
              title: formatMessage({ id: 'law-portal.our-laws.table.law' }),
              field: 'name',
              render: (row) => <RepealableName name={row.name} effectiveTo={row.effectiveTo} />,
            },
            {
              title: formatMessage({ id: 'law-portal.our-laws.table.description' }),
              field: 'description',
              render: (row: any) => <HtmlView variant="body2" value={row?.description} />,
              sorting: false,
            },
            { title: formatMessage({ id: 'law-portal.our-laws.table.changeNumber' }), field: 'changeNumber' },
            {
              title: formatMessage({ id: 'law-portal.our-laws.table.changeEffectiveFrom' }),
              field: 'changeEffectiveFrom',
              render: (row) => renderDate(row.changeEffectiveFrom),
            },
            {
              title: formatMessage({ id: 'law-portal.our-laws.table.notes' }),
              field: 'notes',
              render: (row: any) => <OwnNotes {...row} />,
              sorting: false,
            },
            {
              title: formatMessage({ id: 'law-portal.compliance.report.table.col' }),
              field: 'responsible',
              render: (row: any) => {
                if (row.responsible) {
                  const responsiblearr = row?.responsible?.split(',')
                  return (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {responsiblearr?.map((val: any) => {
                          return <span>{val}</span>
                        })}
                      </div>
                    </>
                  )
                } else {
                  return "-"
                }
              },
              sorting: false,
            },
            {
              title: formatMessage({ id: 'utils.filters.processes' }),
              field: 'processes',
              render: (row: any) => {
                if (row.processes) {
                  const processarr = row?.processes?.split(',')
                  return (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {processarr?.map((val: any) => {
                          return <span>{val}</span>
                        })}
                      </div>
                    </>
                  )
                } else {
                  return "-"
                }
              },
              sorting: false,
            },
            {
              title: formatMessage({ id: 'utils.filters.aspects' }),
              field: 'aspects',
              render: (row: any) => {
                if (row.aspects) {
                  const aspectsarr = row?.aspects?.split(',')
                  return (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {aspectsarr?.map((val: any) => {
                          return <span>{val}</span>
                        })}
                      </div>
                    </>
                  )
                } else {
                  return "-"
                }
              },
              sorting: false
            },
            {
              title: formatMessage({ id: 'utils.filters.tags' }),
              field: 'tags',
              render: (row: any) => {
                if (row.tags) {
                  const tagsarr = row?.tags?.split(',')
                  return (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {tagsarr?.map((val: any) => {
                          return <span>{val}</span>
                        })}
                      </div>
                    </>
                  )
                } else {
                  return "-"
                }
              },
              sorting: false,
            },
            { title: formatMessage({ id: 'law-portal.our-laws.table.area' }), field: 'area' },
          ] : [
            {
              title: formatMessage({ id: 'law-portal.requirements.table.name' }),
              field: 'name',
              render: (row: any) => <RepealableName name={row.name} effectiveTo={row.effectiveTo} />,
            },
            {
              title: formatMessage({ id: 'law-portal.requirements.table.description' }),
              field: 'description',
              render: (row: any) => <HtmlView variant="body2" value={row.description} />,
              sorting: false,
            },
            { title: formatMessage({ id: 'law-portal.requirements.table.area' }), field: 'area' },
            {
              title: formatMessage({ id: 'law-portal.requirements.table.changedDate' }),
              field: 'changedDate',
              render: (row: any) => renderDate(row.changedDate),
            },
            {
              title: formatMessage({ id: 'law-portal.requirements.table.notes' }),
              field: 'notes',
              render: (row: any) => <OwnNotes {...row} />,
              sorting: false,
            },
            {
              title: formatMessage({ id: 'law-portal.compliance.report.table.col' }),
              field: 'responsible',
              render: (row: any) => {
                if (row.responsible) {
                  const responsiblearr = row?.responsible?.split(',')
                  return (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {responsiblearr?.map((val: any) => {
                          return <span>{val}</span>
                        })}
                      </div>
                    </>
                  )
                } else {
                  return "-"
                }
              },
              sorting: false,
            },
            {
              title: formatMessage({ id: 'utils.filters.processes' }),
              field: 'processes',
              render: (row: any) => {
                if (row.processes) {
                  const processarr = row?.processes?.split(',')
                  return (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {processarr?.map((val: any) => {
                          return <span>{val}</span>
                        })}
                      </div>
                    </>
                  )
                } else {
                  return "-"
                }
              },
              sorting: false,
            },
            {
              title: formatMessage({ id: 'utils.filters.aspects' }),
              field: 'aspects',
              render: (row: any) => {
                if (row.aspects) {
                  const aspectsarr = row?.aspects?.split(',')
                  return (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {aspectsarr?.map((val: any) => {
                          return <span>{val}</span>
                        })}
                      </div>
                    </>
                  )
                } else {
                  return "-"
                }
              },
              sorting: false,
            },
            {
              title: formatMessage({ id: 'utils.filters.tags' }),
              field: 'tags',
              render: (row: any) => {
                if (row.tags) {
                  const tagsarr = row?.tags?.split(',')
                  return (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {tagsarr?.map((val: any) => {
                          return <span>{val}</span>
                        })}
                      </div>
                    </>
                  )
                } else {
                  return "-"
                }
              },
              sorting: false,
            }
          ]}
          data={type?.value === 0||type?.id===0 ? handleLawDataFetch : handleRequirementDataFetch}
          // onRowClick={(_, row) => row && history.push(`/law-portal/our-laws/${row.id}`)}
          actionLabel={enableCompliance ? formatMessage({ id: 'law-portal.our-laws.table.compliance' }) : undefined}
          actions={
            [
              (row: LawData) => ({
                icon: () => <Checkbox checked={selectedRows?.includes(row.id)} />,
                onClick: () => handleRowSelect(row.id),
                hidden:hasAccessToModule(Module.Administration)?false: type?.value === 0||type?.id===0 ? !isComplianceUserFor(row.id) : !isComplianceUserForreq(row.id),
              }),
            ]
          }
          options={{
            actionsColumnIndex:0
          }}
          components={{}}
        />
      </Container>
    </div>
  )
}

