import { Divider, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { BackButton, Container, Panel, MTable, RepealableName } from '../../../../../Components';
import { OwnNotes } from '../../../../../Components/OwnNotes';
import { LawListFilter } from './CustomerLaws.List.Filter';
import { useFilter } from '../../../../../Components/ListFilter';
import { AptorApi, IGridQueryRequest, OrderDirection } from '../../../../../Api/AptorApi';
import { UpdateCustomerLawsActions } from './CustomerLaws.Update.Actions';
import { HtmlView } from '../../../../../Components/Html/Html.View';
import { FormattedMessage, useIntl } from 'react-intl';
import { Query } from 'material-table';
import { ICustomerLaw } from './CustomerLaws.List';
import { useAptorApi } from '../../../../../Api';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../../../../Context/UserContext/UserContext';
import { UpdateLawConnectionsDialog } from './CustomerLaws.Update.UpdateDialog';
import { ILawConnectionsModel } from './CustomerLaws.Update.State';
import { useApiValidationSnackbar } from '../../../../../Api/useValidationSnackbar';
import { useLawConnectionUpdateState } from './CustomerLaws.Update.State';

export const UpdateCustomerLaws = () => {
  const { api, abortController } = useAptorApi();
  const { companyUnits } = useContext(UserContext);
  const { unitid } = useParams<{ unitid: string }>();
  const [openUpdateDialog, setOpenUpdateDialog] = useState<boolean>(false);
  const unit = companyUnits.find((u) => u.id === +unitid);
  const [filters, setFilters] = useFilter();
  const tableRef = React.createRef();
  const { formatMessage } = useIntl();
  const {
    state,
    loadLawConnections,
    addLawConnection,
    removeLawConnection,
    lawHasConnection,
    setType,
    setConnection,
    resetState,
  } = useLawConnectionUpdateState(+unitid);
  const { notifySuccess, notifyUnhandledException } = useApiValidationSnackbar();

  useEffect(() => {
    api.getCustomerCompanyUnitsLawConnections<ILawConnectionsModel>(+unitid).then(loadLawConnections);
  }, [api, unitid, loadLawConnections]);

  useEffect(() => {
    tableRef.current && (tableRef.current as any).onQueryChange();
  }, [filters, tableRef]);

  const handleDataFetch = useCallback(
    async (query: Query<ICustomerLaw>) => {
      var response = await api.searchCustomerLaws<ICustomerLaw>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'number',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest);
      return response;
    },
    [api, filters],
  );

  const check = (law: ICustomerLaw) => {
    let isSelected = lawHasConnection(law.id);
    return {
      tooltip: formatMessage({
        id: isSelected ? 'component.mtable.unselectTooltip' : 'component.mtable.selectTooltip',
      }),
      icon: isSelected ? 'check_box' : 'check_box_outline_blank',
      isFreeAction: true,
      onClick: () => {
        if (isSelected) {
          removeLawConnection(law);
        } else {
          addLawConnection(law);
        }
      },
    };
  };

  const onUpdateClicked = () => setOpenUpdateDialog(true);
  const onUpdateCancelled = () => setOpenUpdateDialog(false);
  const onUpdateSubmit = () => {
    setOpenUpdateDialog(false);
    if (state.type && state.connection) {
      const action = (i: AptorApi) =>
        i.updateCustomerCompanyUnitsLawConnections(
          +unitid,
          state.type!.key,
          state.connection!.id,
          state.connectionAddedToLaws.map((x) => x.id),
          state.connectionRemovedFromLaws.map((x) => x.id),
        );
      const validation = () => {};
      api.invoke(action, abortController.current, validation, notifyUnhandledException).then(() => {
        notifySuccess({
          message: formatMessage(
            { id: 'law-portal.manage.update-laws.update.success' },
            {
              type: state.type
                ? formatMessage({
                    id: `law-portal.manage.update-laws.type.options.${state.type?.key}`,
                  }).toLowerCase()
                : undefined,
              connection: state.connection?.name,
            },
          ),
        });
        api.getCustomerCompanyUnitsLawConnections<ILawConnectionsModel>(+unitid).then(resetState);
      });
    }
  };

  return (
    <Container fullWidth>
      <BackButton link="/organization/law-portal/laws" />
      <Panel titleKey="law-portal.manage.add-laws.updateLaws" titleValues={{ unit: unit?.name }}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <UpdateCustomerLawsActions
              state={state}
              onTypeSelected={setType}
              onConnectionSelected={setConnection}
              onUpdateClicked={onUpdateClicked}
            />
            <UpdateLawConnectionsDialog
              state={state}
              open={openUpdateDialog}
              onClose={onUpdateCancelled}
              onSubmit={onUpdateSubmit}
            />
          </Grid>
          <Divider variant="middle" />
          <Grid item>
            <Typography gutterBottom variant="body2">
              <FormattedMessage id="law-portal.manage.law-list.update-laws.description" />
            </Typography>
            <LawListFilter companyUnit={unit} filters={filters} setFilter={setFilters} />
            <MTable
              tableRef={tableRef}
              columns={[
                { title: formatMessage({ id: 'law-portal.manage.law-list.table.number' }), field: 'number' },
                {
                  title: formatMessage({ id: 'law-portal.manage.law-list.table.law' }),
                  field: 'name',
                  render: (row) => <RepealableName name={row.name} effectiveTo={row.effectiveTo} />,
                },
                {
                  title: formatMessage({ id: 'law-portal.manage.law-list.table.description' }),
                  field: 'description',
                  render: (row) => <HtmlView variant="body2" value={row.description} />,
                  sorting: false,
                },
                { title: formatMessage({ id: 'law-portal.manage.law-list.table.area' }), field: 'area' },
                {
                  title: formatMessage({ id: 'law-portal.manage.law-list.table.notes' }),
                  field: 'notes',
                  render: (row) => <OwnNotes {...row} linkTo={`/law-portal/our-laws/${row.id}`} />,
                  sorting: false,
                },
              ]}
              actions={[check]}
              actionLabel={formatMessage({ id: 'law-portal.manage.update-laws.connection' })}
              data={handleDataFetch}
              components={{}}
            />
          </Grid>
        </Grid>
      </Panel>
    </Container>
  );
};
