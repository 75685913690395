import React, { FC, useState, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Grid, Button, Typography } from '@material-ui/core';
import Loading from '../../../../Components/Loading';
import { Container, BackButton } from '../../../../Components';
import { Panel } from '../../../../Components/Panel/Panel';
import { Checklist, IListItem } from './Checklist.List/Checklist.List';
import { ChecklistForm } from './Checklist.Form';
import { useAptorApi } from '../../../../Api';
import { AptorApi, PropertyError } from '../../../../Api/AptorApi';
import { useApiValidationSnackbar } from '../../../../Api/useValidationSnackbar';
import { useHistory } from "react-router-dom"
import { useStyles } from './Checklist.styles';

export interface IChecklist {
  name: string;
  items: IListItem[];
}

interface IProps {
  fetcher: () => Promise<IChecklist | undefined>;
  editor: (
    api: AptorApi,
    checklist: { items: IListItem[] },
  ) => Promise<{
    groupsAdded: number;
    groupsRemoved: number;
    questionsAdded: number;
    questionsRemoved: number;
  }>;
  backTo?: string;
}

export const EditChecklist: FC<IProps> = ({ fetcher, editor, backTo }) => {
  const { api, abortController } = useAptorApi();
  const { formatMessage } = useIntl();
  const { notifySuccess, notifyUnhandledException, notifyValidationErrors } = useApiValidationSnackbar();
  const [loading, setLoading] = useState<boolean>(true);
  const [name, setName] = useState<string | undefined>(undefined);
  const [items, setItems] = useState<IListItem[]>([]);
  const [errors, setErrors] = useState<PropertyError[]>([]);
  const history = useHistory();

  useEffect(() => {
    fetcher().then((response) => {
      if (response) {
        setName(response.name);
        setItems(response.items);
        setLoading(false);
      }
    });
  }, [fetcher]);

  const addQuestion = (name: string) => setItems([...items, { name, type: 'question', groupItems: [] }]);
  const addGroup = (name: string) => setItems([...items, { name, type: 'group', groupItems: [] }]);
  const enableSave = useMemo(() => items.some((i) => i.type === 'question' || i.groupItems.length > 0), [items]);

  const classes = useStyles();

  const submit = async () => {
    const action = async (api: AptorApi) => {
      if (!abortController.current.signal.aborted) {
        editor(api, { items }).then((response) => {
          if(abortController.current.signal.aborted)
          {
            return;
          }
          notifySuccess({
            message: formatMessage(
              { id: 'admin.law-portal.law.checklist.saved.notification' },
              {
                gAdded: (_: any) => (
                  <Typography key="groups-added" component="span" className={classes.typography}>
                    +{response.groupsAdded}
                  </Typography>
                ),
                gRemoved: (_: any) => (
                  <Typography key="groups-removed" component="span" className={classes.typography}>
                    -{response.groupsRemoved}
                  </Typography>
                ),
                qAdded: (_: any) => (
                  <Typography key="questions-added" component="span" className={classes.typography}>
                    +{response.questionsAdded}
                  </Typography>
                ),
                qRemoved: (_: any) => (
                  <Typography key="questions-removed" component="span" className={classes.typography}>
                    -{response.questionsRemoved}
                  </Typography>
                ),
              },
            ),
          });

          if (backTo){
            history.push(backTo);
          }
        });
      }
    };

    const onValidation = (errors: PropertyError[]) => {
      if (abortController.current.signal.aborted) {
        return;
      }
      notifyValidationErrors(errors) && setErrors(errors);
    };
    return await api.invoke(action, abortController.current, onValidation, notifyUnhandledException);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      {backTo && <BackButton link={backTo} />}
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Panel titleKey="admin.law-portal.law.checklist.heading" titleValues={{ name }}>
            <ChecklistForm addGroup={addGroup} addQuestion={addQuestion} errors={errors} />
          </Panel>
        </Grid>
        <Grid item>
          <Panel titleKey="admin.law-portal.law.checklist.list.title">
            <Checklist items={items} updateItems={setItems} />
            <Grid container justify="flex-end">
              <Button disabled={enableSave === false} variant="outlined" onClick={submit}>
                {formatMessage({ id: 'form.save' })}
              </Button>
            </Grid>
          </Panel>
        </Grid>
      </Grid>
    </Container>
  );
};

export type IChecklistItem = IListItem;
