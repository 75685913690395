import { Grid } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { AptorApi } from '../../../../../Api/AptorApi';
import { BackButton, Container } from '../../../../../Components';
import { FormValues } from '../../../../../Components/Form/Form.types';
import { ReinviteCustomerUsersForm } from './Form';

export const ReinviteCustomerUser = () => {
  const history = useHistory();

  const handleSubmit = async (data: FormValues, api: AptorApi, onSuccess: (key: string) => void) => {
    await api.reinviteCustomerUser(data);
    onSuccess('admin.users.table.userInviteSent');
    history.push('/admin/customers');
  };

  return (
    <Container>
      <BackButton link="/admin/customers" />
      <Grid container spacing={2} direction="column">
        <Grid item>
          <ReinviteCustomerUsersForm submit={handleSubmit} />
        </Grid>
      </Grid>
    </Container>
  );
};
