import React, { FC, memo, useEffect } from 'react';
import { Grid, Divider } from '@material-ui/core';
import Select from '../Select/Select';
import { DateFilter, IDateFilter } from './ListFilter.DateInterval';
import { useStyles } from './ListFilter.styles';
import { SelectOption } from '../Form/Form.types';
import { ValueType } from 'react-select/src/types';
import { useIsMounted } from '../../Hooks/useIsMounted';
import { ISearchFilter, SearchFilter } from "./ListFilter.Search";

type setFilterValue = (option: SelectOption) => string | undefined;
interface IValueFilter {
  selector?: setFilterValue;
}

interface ISelectFilter extends IValueFilter {
  type: 'select';
  options: SelectOption[] | undefined;
  name: string;
  placeholder: React.ReactNode;
  initialValue?: ValueType<SelectOption>;
  value?: ValueType<SelectOption>;
  disabled?: boolean;
}

interface IMultiSelectFilter extends IValueFilter {
  type: 'multi-select';
  options: SelectOption[] | undefined;
  name: string;
  placeholder: React.ReactNode;
  initialValue?: ValueType<SelectOption>;
  value?: ValueType<SelectOption>;
}

type filterType = ISelectFilter | IMultiSelectFilter | IDateFilter|ISearchFilter;
type FilterValueType = string[] | { min?: Date; max?: Date } | undefined;
type OnSetFilter = (field: string, values: FilterValueType) => void;

export const createSelectFilter = (
  name: string,
  placeholder: React.ReactNode,
  options: SelectOption[] | undefined,
  setFilterValue?: setFilterValue,
  initialValue?: ValueType<SelectOption>,
  value?: string | null,
  disabled?: boolean,
): ISelectFilter => ({
  name,
  placeholder,
  options,
  type: 'select',
  selector: setFilterValue,
  initialValue: initialValue,
  value: value === null || value === undefined ? value : getSelectedOption(value, options),
  disabled,
});

export const createMultiSelectFilter = (
  name: string,
  placeholder: React.ReactNode,
  options: SelectOption[] | undefined,
  setFilterValue?: setFilterValue,
  initialValue?: ValueType<SelectOption>,
  values?: string[],
): IMultiSelectFilter => ({
  name,
  placeholder,
  options,
  type: 'multi-select',
  selector: setFilterValue,
  initialValue: initialValue,
  value: values ? getSelectedOptions(values, options) : [],
});

const getSelectedOption: (value: string, options: SelectOption[] | undefined) => ValueType<SelectOption> = (
  value: string,
  options: SelectOption[] | undefined,
) => {
  if (!options) {
    return undefined;
  }

  return options.find((x) => x.value.toString() === value?.toString()) ?? null;
};

const getSelectedOptions: (values: string[], options: SelectOption[] | undefined) => ValueType<SelectOption> = (
  values: string[],
  options: SelectOption[] | undefined,
) => {
  if (!options) {
    return [];
  }

  const optionsList = isNested(options) ? (options.flatMap((x) => x.options) as SelectOption[]) : options;
  return optionsList.filter((x) => x && values.indexOf(x.value.toString()) >= 0);
};

function isNested(
  options: SelectOption[] | { label: string; options: SelectOption[] }[] | undefined,
): options is { label: string; options: SelectOption[] }[] {
  return !!options && (options as { label: string; options: SelectOption[] }[]).some((x) => x.options);
}

export const createDateFilter = (name: string, minPlaceholder: string, maxPlaceholder: string): IDateFilter => ({
  name,
  minPlaceholder,
  maxPlaceholder,
  type: 'date',
});

export const createSearchFilter = (name: string,onChange:any,value:any): ISearchFilter => ({
  name,
  onChange,
  value,
  type: 'search',
});

interface IListFilterProps {
  filters: filterType[];
  setFilter: OnSetFilter;
}

export const ListFilter: FC<IListFilterProps> = memo(({ setFilter, filters, children }) => {
  const classes = useStyles();
  const isMounted = useIsMounted();

  const getFilterValue = (filter: IValueFilter, option: SelectOption) => {
    if (filter.selector) {
      return filter.selector(option);
    }
    return option.label;
  };

  useEffect(() => {
    if (!isMounted) {
      filters.forEach((filter) => {
        if (filter.type === 'multi-select' || filter.type === 'select') {
          if (filter.initialValue) {
            const defaultValue = getFilterValue(filter, filter.initialValue as SelectOption);
            setFilter(filter.name, defaultValue ? [defaultValue] : undefined);
          }
        }
      });
    }
  }, [filters, setFilter, isMounted]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {filters.map((filter: any, index:any) => {
          if (filter.type === 'multi-select') {
            return (
              <Grid item md={3} sm={6} xs={12} key={`filter-${filter.name}`}>
                <Select
                  selectProps={{
                    defaultValue: filter.initialValue,
                    value: filter.value,
                    options: filter.options,
                    placeholder: filter.placeholder,
                    isClearable: true,
                    isMulti: true,
                    onChange: (values) => {
                      setFilter(
                        filter.name,
                        (values as SelectOption[])?.map((o) => getFilterValue(filter, o) as string) ?? undefined,
                      );
                    },
                  }}
                />
              </Grid>
            );
          } else if (filter.type === 'select') {
            return (
              <Grid item md={3} sm={6} xs={12} key={`filter-${filter.name}`}>
                <Select
                  selectProps={{
                    defaultValue: filter.initialValue,
                    value: filter.value,
                    options: filter.options,
                    placeholder: filter.placeholder,
                    isClearable: true,
                    isDisabled: filter.disabled,
                    onChange: (option) => {
                      const value = option ? getFilterValue(filter, option as SelectOption) : undefined;
                      setFilter(filter.name, value ? [value] : undefined);
                    },
                  }}
                />
              </Grid>
            );
          } else if (filter.type === 'date') {
            return <DateFilter key={`filter-${filter.name}`} filter={filter} setFilter={setFilter} />;
          } else if (filter.type === 'search') {
            return <SearchFilter name={filter.name} value={filter.value} onChange={filter.onChange} key={index} />;
          } else {
            return <span>{'undefined filter'}</span>;
          }
        })}
      </Grid>
      {children && (
        <>
          <Divider />
          <Grid container spacing={2} justify="flex-end">
            {children}
          </Grid>
        </>
      )}
    </div>
  );
});
