import { Grid } from '@material-ui/core';
import moment from 'moment';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useAptorApi } from '../../../../../Api';
import { IInternalComment, INamedEntity } from '../../../../../Api/AptorApi';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../../Context/UserContext/UserContext';
import { InternalComments, ICompanyUnit } from '../../../Components/InternalComments';

interface IProps {
  id: number;
}

interface IState {
  comments: IInternalComment[];
  companyUnits: ICompanyUnit[];
}

export const LawInternalComments: FC<IProps> = ({ id }) => {
  const { api } = useAptorApi();
  const user = useContext(UserContext);
  const [state, setState] = useState<IState>();

  const hasAccessToComments =
    user.hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage) ||
    user.complianceUserFor.laws.some((x) => x.id === id);

  useEffect(() => {
    Promise.all([api.getLawPortalLawComments(id), api.getLawPortalLawCommentCompanyUnits<ICompanyUnit>(id)]).then(
      ([comments, units]) => {
        if (api.abortController?.signal.aborted) {
          return;
        }
        setState({
          comments: comments.items.sortby((x) => x.createdAt, true),
          companyUnits: units.items,
        });
      },
    );
  }, [api, id]);

  const addComment = async (companyUnitId: number, message: string, taggedUsers: INamedEntity[]) => {
    if (state) {
      const userIds = taggedUsers.map((x) => x.id);
      await api.addLawPortalLawComment(id, companyUnitId, message, userIds).then((result) => {
        if (api.abortController?.signal.aborted) {
          return;
        }
        const newComment = {
          id: result.id,
          companyUnit: state.companyUnits.find((x) => x.id === companyUnitId)!,
          from: { id: user.id, name: `${user.firstName} ${user.lastName}` },
          comment: message,
          createdAt: moment().utc().toDate(),
          taggedUsers,
        };
        setState({ ...state, ...{ comments: [newComment, ...state.comments] } });
      });
    }
  };

  const editComment = async (comment: IInternalComment) => {
    if (state) {
      await api.editLawPortalLawComment(id, comment).then((result) => {
        if (api.abortController?.signal.aborted) {
          return;
        }
        setState({
          ...state,
          ...{
            comments: state.comments.replace(
              (x) => x.id === comment.id,
              () => comment,
            ),
          },
        });
      });
    }
  };

  const deleteComment = (commentId: number) =>
    api.deleteLawPortalLawComment(id, commentId).then(() => {
      if (api.abortController?.signal.aborted) {
        return;
      }

      if (state) {
        setState({ ...state, ...{ comments: state.comments.filter((x) => x.id !== commentId) } });
      }
    });

  if (!hasAccessToComments) {
    return null;
  }

  if (!hasAccessToComments) {
    return null;
  }

  return (
    <Grid item md={3} xs={12}>
      {state && (
        <InternalComments {...state} editComment={editComment} addComment={addComment} deleteComment={deleteComment} />
      )}
    </Grid>
  );
};
