import React, { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { EditChecklist, IChecklistItem } from '../../Components/Checklist';
import { AptorApi, useAptorApi } from '../../../../Api';

interface ILawChecklist {
  law: string;
  items: IChecklistItem[];
}

export const EditLawChecklist = () => {
  const match = useRouteMatch<{ id: string }>();
  const id = parseInt(match.params.id);
  const { api, abortController } = useAptorApi();
  const fetcher = useCallback(
    () =>
      api.getLawChecklist<ILawChecklist>(id).then((x) => {
        if (abortController.current.signal.aborted) {
          return undefined;
        }
        return { name: x.law, items: x.items };
      }),
    [api, abortController, id],
  );
  const editor = (api: AptorApi, checklist: { items: IChecklistItem[] }) =>
    api.editLawChecklist(id, checklist);
  return (
    <EditChecklist
      fetcher={fetcher}
      editor={editor}
      backTo={`/admin/law-portal/laws/${id}`}
    />);
};