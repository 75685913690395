import React, { useCallback, useEffect, useState } from 'react'
import { Container, HtmlView, MTable, RepealableName } from '../../../../../../Components'
import { useIntl } from 'react-intl'
import { useAptorApi } from '../../../../../../Api';
import { useFilter } from '../../../../../../Components/ListFilter';
import { 
  // useHistory,
   useLocation } from 'react-router-dom';
import { IGridQueryRequest, OrderDirection } from '../../../../../../Api/AptorApi';
import { Query } from 'material-table';
import { PlanningListFilter } from './PlanningFilter';
// import { ComplianceActions } from '../../../OurLaws/List/ComplianceAction';
import { useComplianceState } from '../../../OurLaws/List/ComplianceState';
import { Button, Checkbox, Grid } from '@material-ui/core';
// import moment from "moment";
// import async from "react-select/async";
type LawData = {
  id: number;
  number: string;
  name: string;
  changeEffectiveFrom?: Date;
  changeNumber?: string;
  effectiveTo?: Date;
  tags?: string;
};
interface IProps {
  planLawsData: any,
  updateLaws: any,
  setUpdateLaws:(val:any)=>void;
}
export const PlanningView = ({ planLawsData, updateLaws ,setUpdateLaws}: IProps) => {
  const { formatMessage } = useIntl();
  const { api } = useAptorApi();
  const [filters, setFilter] = useFilter();
  const tableRef = React.createRef();
  // const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [plans, setPlans] = useState([])
  const {
    // enableCompliance,
    // complianceEnabled,
    // disableCompliance,
    // startComplianceSession,
    // hasCompliance,
    isComplianceUserFor,
    // toggleCompliance,
  } = useComplianceState();
  useEffect(() => {
    tableRef.current && (tableRef.current as any).onQueryChange();
  }, [filters, tableRef]);

  function handleRowSelect(id: any) {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId: any) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  }
  useEffect(() => {
    getPlanlawsId()
  }, [])
  const getPlanlawsId = async () => {
    const planLaws = await api.getPlanAllLawID(planLawsData.id)
    setPlans(planLaws.lawIds)
    setSelectedRows(planLaws.lawIds)
  }
  const handleDataFetch = useCallback(
    async (query: Query<LawData>) => {
      return await api.searchLawPortalLaws<LawData>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'number',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest);
    },
    [api, filters],
  );
  const getPlanlaws = useCallback(
    async (query: Query<LawData>) => {
      return await api.searchPlanLaws<LawData>(planLawsData.id,{
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'number',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest);
    },
    [api, filters],
  );
  useEffect(() => {
    const queryParamsMap = {
      status: 'status',
      companyUnit: 'companyUnit',
      complianceUsers: 'complianceUsers',
      processes: 'processes',
      aspects: 'aspects',
      category: 'category',
      area: 'category',
      tags:'tags'
    };
    Object.entries(queryParamsMap).forEach(([queryKey, stateKey]) => {
      const queryValues = queryParams.getAll(queryKey);
      if (queryValues.length) {
        setFilter(stateKey, queryValues.join(',').split(',').map(num => num.toString()));
      }
    });
  }, []);

  const addLawsInPlans=async()=>{
      await api.addLaws(updateLaws,selectedRows)
      getPlanlawsId()
      setSelectedRows([])
      setUpdateLaws("")
  }
  const SavePlane = () => {
    return <Button onClick={() => addLawsInPlans()} color="primary" variant="outlined">{formatMessage({ id: 'law-portal.legal.plane.save' })}</Button>
  }
  const [column, setColumn] = useState([
    { title: formatMessage({ id: 'admin.law-portal.table.number' }), field: 'number' },
    {
      title: formatMessage({ id: 'admin.law-portal.table.law' }),
      field: 'name',
      render: (row: any) => <RepealableName name={row.name} effectiveTo={row.effectiveTo} />,
    },
    {
      title: formatMessage({ id: 'law-portal.our-laws.table.description' }),
      field: 'description',
      render: (row: any) =>  <HtmlView variant="body2" value={row.description} />,
      sorting: false,
    },
    { title: formatMessage({ id: 'law-portal.our-laws.table.area' }), field: 'area' },
    {
      title: formatMessage({ id: 'law-portal.our-laws.table.notes' }),
      field: 'notes',
      sorting: false,
    },
  ])
  useEffect(() => {
    const filterColumn = column?.filter((val) => val.field !== "select")
    if (updateLaws !== "" && updateLaws === planLawsData.id) {
      setColumn([...filterColumn, {
        title: 'Select', // Add a selection column
        field: 'select',
        render: (rowData: any) => (
          isComplianceUserFor(rowData.id)?<Checkbox checked={selectedRows.includes(rowData.id)}
            onChange={() => handleRowSelect(rowData.id)} />:<></>
        ),
      }])
    }else{
      setSelectedRows(plans)
      setColumn([...filterColumn])
    }
  }, [updateLaws,selectedRows])
  return (
    <Container fullWidth>
      {selectedRows.length > 0&&updateLaws !== "" && updateLaws === planLawsData.id && <Grid container justify='flex-end' alignItems='center' style={{ marginBottom: 10 }}><SavePlane /></Grid>}
      <PlanningListFilter setFilter={setFilter} filters={filters} />
      <MTable
        tableRef={tableRef}
        columns={column}
        data={updateLaws !== "" && updateLaws === planLawsData.id?handleDataFetch:getPlanlaws}
        components={{}}
      />
      {selectedRows.length > 0&&updateLaws !== "" && updateLaws === planLawsData.id && <Grid container justify='flex-end' alignItems='center' style={{ marginTop: 20 }}><SavePlane /></Grid>}
    </Container>
  );
};
