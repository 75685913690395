import React, { useCallback, useEffect, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button, Checkbox, Paper, Tooltip } from '@material-ui/core';
import { MTableToolbar, Query } from 'material-table';
import { useFilter } from '../../../../../Components/ListFilter';
import { HtmlView } from '../../../../../Components/Html/Html.View';
import { MTable, RepealableName } from '../../../../../Components';
import { OwnNotes } from '../../../../../Components/OwnNotes';
import { LawListFilter } from './Filter';
// import { ComplianceActions } from './ComplianceAction';
import { useComplianceState } from './ComplianceState';
import { ModuleContext } from '../../../../../Context/ModuleContext/ModuleContext';
import { useAptorApi } from '../../../../../Api';
import { IGridQueryRequest, INamedEntity, OrderDirection } from '../../../../../Api/AptorApi';
import { useIsMounted } from '../../../../../Hooks/useIsMounted';
import { useStyles } from '../../../LawPortal.styles';
import { ArrowUpward, SaveAlt } from '@material-ui/icons';
import moment from 'moment';
import LatestComplianceControlDialog from "./LatestComplianceControlDialog";
import { useUser } from '../../../../../Context/UserContext/UserContext';
import { useSnackbar } from 'notistack';
type LawData = {
  id: number;
  number: string;
  name: string;
  description: string;
  area: string;
  changeNumber?: string;
  changeEffectiveFrom?: Date;
  effectiveTo?: Date;
  companyUnits: Array<INamedEntity & { note: string | null }>;
};

export const LawList = () => {
  const { formatMessage } = useIntl();
  const {
    enableCompliance,
    complianceEnabled,
    // disableCompliance,
    // startComplianceSession,
    hasCompliance,
    isComplianceUserFor,
    toggleCompliance,
  } = useComplianceState();
  const { api } = useAptorApi();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [filters, setFilter] = useFilter();
  const tableRef = React.createRef();
  const isMounted = useIsMounted();
  const { setHeader } = useContext(ModuleContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [lawIdForCompliance, setLawIdForCompliance] = useState("")
  // useEffect(() => {
  //   setHeader(formatMessage({ id: 'law-portal.our-laws.header', defaultMessage: 'Our laws' }));
  // }, [setHeader, formatMessage]);

  useEffect(() => {
    if (isMounted) {
      tableRef.current && (tableRef.current as any).onQueryChange();
    }
  }, [filters, tableRef, isMounted]);
  useEffect(() => {
    setHeader(formatMessage({ id: 'law-portal.our-laws.header', defaultMessage: 'Our laws' }), [
      {
        title: formatMessage({ id: 'law-portal.our-law.tab.law-list' }),
        to: `/law-portal/our-laws`,
      },
      {
        title: formatMessage({ id: 'law-portal.statistics.sub-menu.changes.unacknowledged' }),
        to: `/law-portal/our-laws/unacknowledged`,
      },
      {
        title: formatMessage({ id: 'law-portal.statistics.sub-menu.changes.acknowledged' }),
        to: `/law-portal/our-laws/acknowledged`,
      },

    ]);
  }, [setHeader, formatMessage]);
  const handleDataFetch = useCallback(
    async (query: Query<LawData>) => {
      return await api.searchLawPortalLaws<LawData>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'number',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest);
    },
    [api, filters],
  );

  const handleExport = async () => {
    const response = await api.ExportLawPortalLaws()
    const blob = await response?.blob();
    const contentDisposition = response.headers.get('Content-Disposition'); 
    let fileName = 'LawsExport.xlsx'; 
    if (contentDisposition && contentDisposition.includes('attachment')) { 
      const matches = /filename=([^;]*)/.exec(contentDisposition);
      if (matches && matches[1]) {
         fileName = matches[1]; 
      } 
    }
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); // or whatever you want the file name to be
    document.body.appendChild(link);
    link.click();
  }

  useEffect(() => {
    const queryParamsMap = {
      status: 'status',
      companyUnit: 'companyUnit',
      complianceUsers: 'complianceUsers',
      processes: 'processes',
      aspects: 'aspects',
      category: 'category',
      area: 'area',
      tags:'tags'
    };
    Object.entries(queryParamsMap).forEach(([queryKey, stateKey]) => {
      const queryValues = queryParams.getAll(queryKey);
      if (queryValues.length) {
        setFilter(stateKey, queryValues.join(',').split(',').map(num => num.toString()));
      }
    });
  }, []);
  const renderDate = (date?: Date) => {
    return date ? moment(date).format('ll') : undefined;
  };
  const [showScrollTop, setShowScrollTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    const updatedQueryParams = Object.keys(filters)
      .filter(key => filters[key]?.join() !== undefined && filters[key]?.join() !== "")
      .map(key => `${key}=${encodeURIComponent(filters[key]?.join())}`)
      .join('&');
    history.push({
      pathname: '',
      search: `?${updatedQueryParams}`,
    });
  }, [filters, history]);
const [tableColumn,setTableColumn]=useState([
  { title: formatMessage({ id: 'law-portal.our-laws.table.number' }), field: 'number' ,hidden:false},
  {
    title: formatMessage({ id: 'law-portal.our-laws.table.law' }),
    field: 'name',
    render: (row:any) => <RepealableName name={row.name} effectiveTo={row.effectiveTo} />,
    hidden:false
  },
  {
    title: formatMessage({ id: 'law-portal.our-laws.table.description' }),
    field: 'description',
    render: (row:any) => <HtmlView variant="body2" value={row.description} />,
    sorting: false,
    hidden:false
  },
  { title: formatMessage({ id: 'law-portal.our-laws.table.changeNumber' }), field: 'changeNumber',hidden:false },
  {
    title: formatMessage({ id: 'law-portal.our-laws.table.changeEffectiveFrom' }),
    field: 'changeEffectiveFrom',
    render: (row:any) => renderDate(row.changeEffectiveFrom),
    hidden:false
  },
  {
    title: formatMessage({ id: 'law-portal.our-laws.table.notes' }),
    field: 'notes',
    render: (row:any) => <OwnNotes {...row} />,
    sorting: false,
    hidden:false
  },
  {
    title: formatMessage({ id: 'law-portal.compliance.report.table.col' }),
    field: 'responsible',
    render: (row: any) => {
      if (row.responsible) {
        const responsiblearr = row?.responsible?.split(',')
        return (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {responsiblearr?.map((val: any) => {
                return <span>{val}</span>
              })}
            </div>
          </>
        )
      } else {
        return "-"
      }
    },
    sorting: false,
    hidden:false
  },
  {
    title: formatMessage({ id: 'law-portal.law.table.latest-compliance-control' }), field: 'compilancelog', render: (row: any) => <Button onClick={(event) => {
      event.stopPropagation()
      setLawIdForCompliance(row.id)
      setOpen(true)
    }}
      disabled={row?.logCount == null || row?.logCount == 0 ? true : false}
    >{formatMessage({ id: "law-portal.our-laws.table-row.latest-compliance-control.button.name", defaultMessage: "Compliance Log" })}</Button>, sorting: false,
    hidden:false
  },
  {
    title: formatMessage({ id: 'utils.filters.processes' }),
    field: 'processes',
    render: (row: any) => {
      if (row.processes) {
        const processarr = row?.processes?.split(',')
        return (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {processarr?.map((val: any) => {
                return <span>{val}</span>
              })}
            </div>
          </>
        )
      } else {
        return "-"
      }
    },
    sorting: false,
    hidden:false
  },
  {
    title: formatMessage({ id: 'utils.filters.aspects' }),
    field: 'aspects',
    render: (row: any) => {
      if (row.aspects) {
        const aspectsarr = row?.aspects?.split(',')
        return (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {aspectsarr?.map((val: any) => {
                return <span>{val}</span>
              })}
            </div>
          </>
        )
      } else {
        return "-"
      }
    },
    sorting: false,
    hidden:false
  },
  {
    title: formatMessage({ id: 'utils.filters.tags' }),
    field: 'tags',
    render: (row: any) => {
      if (row.tags) {
        const tagsarr = row?.tags?.split(',')
        return (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {tagsarr?.map((val: any) => {
                return <span>{val}</span>
              })}
            </div>
          </>
        )
      } else {
        return "-"
      }
    },
    sorting: false,
    hidden:false
  },
  { title: formatMessage({ id: 'law-portal.our-laws.table.area' }), field: 'area' ,hidden:false},
  { title: <Button className='custom-save-button' onClick={()=>{handleSubmit()}}>{formatMessage({ id: 'form.save' })}</Button>, field: 'button',hidden:true ,
 
},
])
const getColumn= async (id:any,lawName:any)=>{
  let item= await api.getSaveColumn(id,lawName)
  let columnItem=[...tableColumn]
if(item?.length){
  columnItem.forEach((val:any,index:any)=>{
    if(val?.field!=='button'){
      if(item?.includes(val?.field)){
        columnItem[index].hidden=false
      }else{
        columnItem[index].hidden=true
      }
    }
  })
}
setTableColumn(columnItem)
}
useEffect(()=>{
  let data:any=localStorage.getItem('user')
  let user=JSON.parse(data)
  if(user){
  getColumn(user?.id,1)
}
},[])
const { enqueueSnackbar } = useSnackbar();
const intl = useIntl();
const handleSubmit=()=>{
  let checkItem:any=[]
  tableColumn.forEach((val:any)=>{
    if(val?.hidden==false&&val?.field!='button'){
      checkItem.push(val?.field)
    }
  })
  let payload={
  TableValue: 1,
  Columns: [...checkItem]
}
if(checkItem?.length){
  api.saveColumn(payload)
}else{
  enqueueSnackbar(intl.formatMessage({ id:'law-portal.minimum_one_select_checkbox' }), {
    variant: 'error',
  });
  
}
}

  return (
    <div className={classes.listWrapper}>
      <LawListFilter filters={filters} setFilter={setFilter}>
        {/* <ComplianceActions
          complianceEnabled={complianceEnabled}
          enableCompliance={enableCompliance}
          disableCompliance={disableCompliance}
          hasCompliance={hasCompliance}
          startComplianceSession={startComplianceSession}
        /> */}
      </LawListFilter>
      <hr style={{ marginTop: '25px', marginBottom: '25px' }} />
      <MTable
        tableRef={tableRef}
        columns={tableColumn}
        data={handleDataFetch}
        onRowClick={(_, row) => row && history.push(`/law-portal/our-laws/${row.id}`)}
        actionLabel={enableCompliance ? formatMessage({ id: 'law-portal.our-laws.table.compliance' }) : undefined}
        actions={
          complianceEnabled
            ? [
              (row: LawData) => ({
                icon: () => <Checkbox checked={hasCompliance((law) => law.id === row.id)} />,
                onClick: () => toggleCompliance(row),
                hidden: !isComplianceUserFor(row.id),
              }),
            ]
            : []
        }
        components={{
          Toolbar: (props) => <div className={classes.toolbar}>
            <MTableToolbar {...props} />
            <SaveAlt style={{ cursor: 'pointer' }} onClick={() => handleExport()} />
          </div>
        }}
      />
      {open && lawIdForCompliance !== "" && <LatestComplianceControlDialog open={open} lawId={lawIdForCompliance} setOpen={(val: boolean) => {
        setOpen(val)
        setLawIdForCompliance("")
      }} />}
      {showScrollTop && (
        <Tooltip title={formatMessage({ id: "bottom.to.top.btn.tooltip.text", defaultMessage: "Back to top" })}>
          <Button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className={classes.topButton}
          >
            <ArrowUpward style={{ fontSize: 20 }} />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};
