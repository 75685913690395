import React, { useCallback, useEffect, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { MTableToolbar, Query } from 'material-table';
import MTable from '../../../../../Components/MTable/MTable';
import { HtmlView } from '../../../../../Components/Html/Html.View';
import { OwnNotes } from '../../../../../Components/OwnNotes';
import { useFilter } from '../../../../../Components/ListFilter';
import { RequirementListFilter } from './Filter';
import { ModuleContext } from '../../../../../Context/ModuleContext/ModuleContext';
import { IGridQueryRequest, INamedEntity, OrderDirection } from '../../../../../Api/AptorApi';
import { useAptorApi } from '../../../../../Api';
import { useIsMounted } from '../../../../../Hooks/useIsMounted';
import { useStyles } from '../../../LawPortal.styles';
import moment from 'moment';
import { RepealableName } from '../../../../../Components';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../../Context/UserContext/UserContext';
import { Button,Tooltip } from '@material-ui/core';
import { ArrowUpward, SaveAlt } from '@material-ui/icons';
import LatestComplianceControlDialog from "./LatestComplianceControlDialog";
import { useSnackbar } from 'notistack';

type RequirementData = {
  id: number;
  name: string;
  description: string;
  area: string;
  effectiveTo?: Date;
  changedDate?: Date;
  companyUnits: Array<INamedEntity & { note: string | null }>;
};

export const RequirementList = () => {
  const { formatMessage } = useIntl();
  const { api } = useAptorApi();
  const [filters, setFilter] = useFilter();
  const tableRef = React.createRef();
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const queryParams = new URLSearchParams(location.search);
  const isMounted = useIsMounted();
  const { setHeader } = useContext(ModuleContext);
  const user = useContext(UserContext);
  const isManager = user.hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);
  const classes = useStyles();
  const [reqIdForCompliance,setReqIdForCompliance]=useState("")
  // useEffect(() => {
  //   setHeader(formatMessage({ id: 'law-portal.requirements.header', defaultMessage: 'Other requirements' }));
  // }, [setHeader, formatMessage]);
  useEffect(() => {
    setHeader(formatMessage({ id: 'law-portal.requirements.header', defaultMessage: 'Other requirements' }), [
      {
        title: formatMessage({ id: 'law-portal.requirements.header', defaultMessage: 'Other requirements' }),
        to: `/law-portal/other-requirements`,
      },
      {
        title: formatMessage({ id: 'law-portal.statistics.sub-menu.changes.unacknowledged' }),
        to: `/law-portal/other-requirements/unacknowledged`,
      },
      {
        title: formatMessage({ id: 'law-portal.statistics.sub-menu.changes.acknowledged' }),
        to: `/law-portal/other-requirements/acknowledged`,
      },

    ]);
  }, [setHeader, formatMessage]);
  useEffect(() => {
    if (isMounted) {
      tableRef.current && (tableRef.current as any).onQueryChange();
    }
  }, [filters, tableRef, isMounted]);
  useEffect(() => {
    const queryParamsMap = {
      status: 'status',
      companyUnit: 'companyUnit',
      complianceUsers: 'complianceUsers',
      processes: 'processes',
      aspects: 'aspects',
      area: 'area',
      tags: 'tags',
    };
    Object.entries(queryParamsMap).forEach(([queryKey, stateKey]) => {
      const queryValues = queryParams.getAll(queryKey);
      if (queryValues.length) {
        setFilter(stateKey, queryValues.join(',').split(',').map(num => num.toString()));
      }
    });
  }, []);
  const handleDataFetch = useCallback(
    async (query: Query<RequirementData>) => {
      return await api.searchLawPortalRequirements<RequirementData>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'name',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest);
    },
    [api, filters],
  );

  const renderDate = (date?: Date) => {
    return date ? moment(date).format('ll') : undefined;
  };
  const [showScrollTop, setShowScrollTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    const updatedQueryParams = Object.keys(filters)
      .filter(key => filters[key]?.join() !== undefined && filters[key]?.join() !== "")
      .map(key => `${key}=${encodeURIComponent(filters[key]?.join())}`)
      .join('&');
    history.push({
      pathname: '',
      search: `?${updatedQueryParams}`,
    });
  }, [filters, history]);

  const handleExport = async () => {
    const response = await api.exportLawPortalRequirements()
    const blob = await response?.blob();
    const contentDisposition = response.headers.get('Content-Disposition'); 
    let fileName = 'RequirementExport.xlsx'; 
    if (contentDisposition && contentDisposition.includes('attachment')) { 
      const matches = /filename=([^;]*)/.exec(contentDisposition);
      if (matches && matches[1]) {
         fileName = matches[1]; 
      } 
    }
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); // or whatever you want the file name to be
    document.body.appendChild(link);
    link.click();
  }

  const [tableColumn,setTableColumn]=useState([
    {
      title: formatMessage({ id: 'law-portal.requirements.table.name' }),
      field: 'name',
      render: (row:any) => <RepealableName name={row.name} effectiveTo={row.effectiveTo} />,
      hidden:false,
    },
    {
      title: formatMessage({ id: 'law-portal.requirements.table.description' }),
      field: 'description',
      render: (row:any) => <HtmlView variant="body2" value={row.description} />,
      sorting: false,
      hidden:false
    },
    { title: formatMessage({ id: 'law-portal.requirements.table.area' }), field: 'area' ,   hidden:false},
    {
      title: formatMessage({ id: 'law-portal.requirements.table.changedDate' }),
      field: 'changedDate',
      render: (row:any) => renderDate(row.changedDate),
      hidden:false
    },
    {
      title: formatMessage({ id: 'law-portal.requirements.table.notes' }),
      field: 'notes',
      render: (row:any) => <OwnNotes {...row} />,
      sorting: false,
      hidden:false
    },
    {
      title: formatMessage({ id: 'law-portal.requirements.table.latest-compliance-control' }), field: '', render: (row: any) => <Button onClick={(event) => {
        event.stopPropagation()
        setReqIdForCompliance(row.id)
        setOpen(true)
      }}
      disabled={row?.logCount==null||row?.logCount==0?true:false}
      >{formatMessage({id:"law-portal.our-laws.table-row.latest-compliance-control.req.button.name",defaultMessage:"Compliance Log"})}</Button>, sorting: false,
      hidden:false
    },
    {
      title: formatMessage({ id: 'law-portal.compliance.report.table.col' }),
      field: 'responsible',
      render: (row: any) => {
        if (row.responsible) {
          const responsiblearr = row?.responsible?.split(',')
          return (
            <>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {responsiblearr?.map((val: any) => {
                  return <span>{val}</span>
                })}
              </div>
            </>
          )
        } else {
          return "-"
        }
      },
      sorting: false,
      hidden:false
    },
    {
      title: formatMessage({ id: 'utils.filters.processes' }),
      field: 'processes',
      render: (row: any) => {
        if (row.processes) {
          const processarr = row?.processes?.split(',')
          return (
            <>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {processarr?.map((val: any) => {
                  return <span>{val}</span>
                })}
              </div>
            </>
          )
        } else {
          return "-"
        }
      },
      sorting: false,
      hidden:false
    },
    {
      title: formatMessage({ id: 'utils.filters.aspects' }),
      field: 'aspects',
      render: (row: any) => {
        if (row.aspects) {
          const aspectsarr = row?.aspects?.split(',')
          return (
            <>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {aspectsarr?.map((val: any) => {
                  return <span>{val}</span>
                })}
              </div>
            </>
          )
        } else {
          return "-"
        }
      },
      sorting: false,
      hidden:false
    },
    {
      title: formatMessage({ id: 'utils.filters.tags' }),
      field: 'tags',
      render: (row: any) => {
        if (row.tags) {
          const tagsarr = row?.tags?.split(',')
          return (
            <>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {tagsarr?.map((val: any) => {
                  return <span>{val}</span>
                })}
              </div>
            </>
          )
        } else {
          return "-"
        }
      },
      sorting: false,
      hidden:false,
    },
    { title: <Button className='custom-save-button' onClick={()=>{handleSubmit()}}>{formatMessage({ id: 'form.save' })}</Button>, field: 'button',hidden:true ,
  },
  ])
  const getColumn= async (id:any,lawName:any)=>{
    let item= await api.getSaveColumn(id,lawName)
    let columnItem=[...tableColumn]
  if(item?.length){
    columnItem.forEach((val:any,index:any)=>{
      if(val?.field!=='button'){
        if(item?.includes(val?.field)){
          columnItem[index].hidden=false
        }else{
          columnItem[index].hidden=true
        }
      }
    })
  }
  setTableColumn(columnItem)
  }
  useEffect(()=>{
    let data:any=localStorage.getItem('user')
    let user=JSON.parse(data)
    if(user){
    getColumn(user?.id,2)
  }
  },[])
  const { enqueueSnackbar } = useSnackbar();
const intl = useIntl();
  const handleSubmit=()=>{
    let checkItem:any=[]
    tableColumn.forEach((val:any)=>{
      if(val?.hidden==false&&val?.field!='button'){
        checkItem.push(val?.field)
      }
    })
    let payload={
    TableValue: 2,
    Columns: [...checkItem]
  }
  if(checkItem?.length){
    api.saveColumn(payload)
  }else{
    enqueueSnackbar(intl.formatMessage({ id:'law-portal.minimum_one_select_checkbox' }), {
      variant: 'error',
    });
  } 
  }
  return (
    <div className={classes.listWrapper}>
      <RequirementListFilter filters={filters} setFilter={setFilter} />
      <hr style={{ marginTop: '25px', marginBottom: "25px" }} />
      <MTable
        tableRef={tableRef}
        columns={tableColumn}
        data={handleDataFetch}
        onRowClick={(_, row) =>
          row &&
          (isManager || user.complianceUserFor.requirements.some((x) => x.id === row.id)) &&
          history.push(`/law-portal/other-requirements/${row.id}`)
        }
        options={{
          rowStyle: (row) =>
            (isManager || user.complianceUserFor.requirements.some((x) => x.id === row.id)) === false
              ? { pointerEvents: 'none' }
              : {}, 
        }
      }
        components={{
          Toolbar: (props) => <div className={classes.toolbar}>
            <MTableToolbar {...props} />
            <SaveAlt style={{ cursor: 'pointer' }} onClick={() => handleExport()} />
          </div>
        }}
      />
       {open&&reqIdForCompliance!==""&&<LatestComplianceControlDialog open={open} reqId={reqIdForCompliance} setOpen={(val: boolean) => {
        setOpen(val)
        setReqIdForCompliance("")
       }} />}
      {showScrollTop && (
        <Tooltip title={formatMessage({id:"bottom.to.top.btn.tooltip.text",defaultMessage:"Back to top"})}>
          <Button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className={classes.topButton}
          >
            <ArrowUpward style={{ fontSize: 20 }} />
          </Button>
        </Tooltip>
      )}

    </div>
  );
};
