import React, { FC, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Checkbox, Grid, Icon, Typography } from '@material-ui/core';
import { useConfirmation } from '../../../../../../Context/ConfirmationContext/ConfirmationContext';
import { UserContext } from '../../../../../../Context/UserContext/UserContext';
import { IRequirementChange } from '.';
import moment from 'moment';
import { TextField } from '../../../../../../Components/Field/TextField';
interface IProps {
  reqId: number;
  change: IRequirementChange;
  acknowledge?: (comment?: string,affect?:any) => Promise<void>;
}

export const RequirementChange: FC<IProps> = ({ reqId, change, acknowledge }) => {
  const { complianceUserFor, id } = useContext(UserContext);
  const { deleteConfirmation } = useConfirmation();
  const { formatMessage } = useIntl();

  const [comment, setComment] = useState<string>('');
const [affect,setAffect]=useState(false)
const [notaffect, setNotAffect] = useState(false)
  const shouldAcknowledge =
    acknowledge &&
    complianceUserFor.requirements.some((x) => x.id === reqId) &&
    change.unacknowledgedByUsers.some((x) => x === id);

  const confirmation = {
    title: formatMessage({ id: 'law-portal.requirements.changes.acknowledge.label' }),
    description: formatMessage({ id: 'law-portal.requirements.changes.acknowledge.confirmation' }),
  };

  const ack = () => {
    let affected
    if (affect == false && notaffect == false) {
      affected = null
    } else if (affect == true) {
      affected = true
    } else if (notaffect == true) {
      affected = false
    }
    return acknowledge!(comment,affected);
  };

  const handleAcknowledge = () => {
    if (acknowledge) {
      deleteConfirmation(ack, confirmation);
    }
  };

  return (
    <Grid container direction="column">
      <Typography dangerouslySetInnerHTML={{ __html: change.shortDescription }} />
      <Typography>
        <FormattedMessage
          id="law-portal.requirements.changes.effectiveFrom"
          values={{ date: moment(change.effectiveFrom).format('ll') }}
        />
      </Typography>
      {shouldAcknowledge && (
        <>
        <Grid md={12} xs={12} item style={{ marginTop: '2em', marginBottom: '1em', display: 'flex' }}>
            <div>
              <Checkbox
                checked={affect}
                // color="primary"
                name='affect'
                onChange={(_, value: any) => { 
                  if(notaffect==true){
                    setAffect(value)
                    setNotAffect(false)
                  }else{
                    setAffect(value)
                  }
                }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
                // onFocus={() => setIsFocused(true)}
                // onBlur={() => setIsFocused(false)}
                required={false}

              />
              <label>{formatMessage({ id: "law-portal.our-law.ack.latest-change.checkbox.text.affect" })}</label>
            </div>
            <div>
              <Checkbox 
                checked={notaffect}
                // color="primary"
                name='not_affect'
                onChange={(_, value: any) => { 
                  if(affect==true){
                    setNotAffect(value)
                    setAffect(false)
                  }else{
                    setNotAffect(value)
                  }
                }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
                // onFocus={() => setIsFocused(true)}
                // onBlur={() => setIsFocused(false)}
                required={false}

              />
              <label>{formatMessage({ id: "law-portal.our-law.ack.latest-change.checkbox.text.not_affect" })}</label>
            </div>
          </Grid>
          <Grid md={6} xs={12} item style={{ marginTop: '2em', marginBottom: '1em' }}>

            <TextField
              label={formatMessage({ id: 'component.law-change.acknowledge.comment' })}
              name="comment"
              errorTexts={[]}
              onChange={(val) => setComment(val?.value || '')}
            />
          </Grid>
          <Grid item>
            <Grid container justify="flex-end">
              <Button variant="outlined" onClick={handleAcknowledge} startIcon={<Icon>check</Icon>}>
                <FormattedMessage
                  id="law-portal.requirements.changes.acknowledge.button"
                  defaultMessage="Acknowledge"
                />
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};
