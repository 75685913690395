import React, { FC } from 'react';
import { AptorApi } from '../../../../../../Api';
import { Notes } from '../../../../Components/OverviewNotes';
import { ICompanyUnitRequirement } from '.';

interface IProps {
  reqId: number;
  companyUnits: ICompanyUnitRequirement[];
  onChange: (state: { companyUnits: ICompanyUnitRequirement[] }) => void;
}

export const RequirementNotes: FC<IProps> = ({ reqId, companyUnits, onChange }) => {
  const editNote = (companyUnitId: number, note: string | null, api: AptorApi, onSuccess: () => void) =>
    api.updateLawPortalRequirementNote(reqId, companyUnitId, note).then(() => {
      if (api.abortController?.signal.aborted) {
        return;
      }
      onSuccess();
      var updatedCompanyUnits = companyUnits.replace(
        (x) => x.id === companyUnitId,
        (item) => ({ ...item, ...{ note } }),
      );
      onChange({ companyUnits: updatedCompanyUnits });
    });

  return <Notes companyUnits={companyUnits} editNote={editNote} />;
};
