import { useState, useEffect, useCallback } from 'react';
import { OptionsType } from 'react-select';
import { IListResult, INamedEntity } from './AptorApi';
import { SelectOption } from '../Components/Form/Form.types';
import { entityToSelectOption } from '../Utilities';

export function useFetchSelectOptions(
  fetchFunc: () => Promise<IListResult<INamedEntity>>,
  abortController: React.MutableRefObject<AbortController>,
): [OptionsType<SelectOption>, React.Dispatch<React.SetStateAction<OptionsType<SelectOption>>>, boolean, () => void] {
  const [initFlag, setInitFlag] = useState(false);
  const [options, setOptions] = useState<OptionsType<SelectOption>>([]);

  useEffect(() => {
    const fetchOptions = async () => {
      const result = await fetchFunc();
      if(abortController.current.signal.aborted)
      {
        return;
      }
      setInitFlag(true);
      setOptions(result.items.map(entityToSelectOption));
    };

    if (!initFlag) {
      fetchOptions();
    }
  }, [initFlag, fetchFunc, abortController]);

  const refetchOptions = useCallback(() => {
    setInitFlag(false);
  }, []);

  return [options, setOptions, initFlag, refetchOptions];
}
