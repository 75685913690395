import React from 'react';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { HtmlView } from '..';

interface IProps {
    subject?: string;
    htmlContent: string;
    isOpen: boolean;
    handleClose: () => void;
}

export const MailDialog: React.FC<IProps> = ({ subject, htmlContent, isOpen, handleClose}) => {
  const { formatMessage } = useIntl();
  const descriptionElementRef = React.useRef<HTMLSpanElement>(null);
  React.useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isOpen]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {subject && <DialogTitle id="scroll-dialog-title">{subject}</DialogTitle>}
        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <HtmlView value={htmlContent} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {formatMessage({ id: 'component.mail-dialog.close', defaultMessage: 'Close' })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}